import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TOKEN } from '../../constants/app-constants';
import { DiscountInfo } from '../../models/order-details';
import { FormControlService } from '../../services/form-control.service';

@Component({
  selector: 'app-date-range',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
  ],
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  standalone: true,
})
export class DateRangeComponent {
  @Input() minDate = '';
  @Input() label = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() requireMark: boolean = false;
  startDate: any;
  endDate: any;
  @Input() control = new FormControl();
  @Input() control2 = new FormControl();
  @Input() errorMsgs: any;
  @Input() click: any;
  @Output() dateRangeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() endDateChanged: EventEmitter<any> = new EventEmitter<any>();
  disabledStartDate!: Date; 
  onDateChange(event: any): void {
    // The event contains the selected date range as a Date[] array.
    this.dateRangeChanged.emit(event.value);
    console.log(event);
  }
 
  onDateChanges(event: any): void {
    // The event contains the selected date range as a Date[] array.
    this.endDateChanged.emit(event.value);
    console.log(event);
  }
  constructor(private injector: Injector,){
   let data = this.injector?.get(TOKEN, null) as any as DiscountInfo;
    if (data && data.validFrom) {
      this.disabledStartDate = new Date();
    }
  }
}
