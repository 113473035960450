import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PaymentCopyLink } from '../../models/payment';
import { AuthService } from './../../services/auth.service';
import { ButtonComponent } from './../button/button.component';

@Component({
  selector: 'app-link-status',
  templateUrl: './link-status.component.html',
  styleUrls: ['./link-status.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatIconModule],
})
export class LinkStatusComponent {
  linkStatus = '';
  paymentCopyLink = <PaymentCopyLink>{};

  constructor(
    private authService: AuthService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      const routerParams = this.router.getCurrentNavigation()?.extras.state;

      if (routerParams && routerParams['copyLinkStatus']) {
        this.linkStatus = routerParams['copyLinkStatus'];
        this.paymentCopyLink = routerParams['paymentCopyLink'];
      }
    }
  }

  goToLoginPage() {
    // removing copy link storage
    sessionStorage.removeItem('copyLinkStatus');
    sessionStorage.removeItem('copyPaymentLink');

    // user -> redirected to loggedin user page / !user -> logout the session
    if (!localStorage?.getItem('user')) {
      this.oidcSecurityService
        .logoff()
        .subscribe((result) => console.log(result));
      this.authService.logoff();
    }

    if (localStorage.getItem('adminLink')) {
      location.href = localStorage.getItem('currentURL')!;
    } else {
      location.reload();
    }
  }

  getMessage(): string {
    switch (this.linkStatus) {
      case 'expired':
        return 'The payment link has expired. Please request a new link.';
      case 'completed':
        return 'The payment has already been processed.';
      case 'cancelled':
        return 'This payment link is no longer active. Please use the most recently generated link.';
      case 'inprogress':
        const duration =
          new Date(this.paymentCopyLink.inProgressDuration!).getMinutes() -
          new Date().getMinutes();
        return `The payment link is in use. ${duration > 0 ? 'Please try again after ' + duration.toString() + ' minute(s).' : 'Please ensure link is not open in another browser and try again.'
          }`;
      default:
        return '';
    }
  }
}
