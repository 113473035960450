import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { isSafari } from '../../util-functions/util_functions';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
  ],
  styleUrls: ['./address-autocomplete.component.scss'],
  standalone: true,
})
export class AddressAutoCompleteComponent implements OnInit {
  @Input() formControl!: FormControl;
  @Input() suggestions!: any[];
  @Output() emitAddress = new EventEmitter<any>();
  @Input() errorMsgs: any;
  @Input() requireMark: boolean = false;
  @Input() label = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() fieldType: any;
  @Input() isShowAdd = false;
  @Input() showClear = false;
  @Output() onTextClear = new EventEmitter<string>();
  isSafariBrowser = isSafari;

  onClear(event: any) {
    this.onTextClear.emit(event);
  }
  constructor() {}
  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;
  scrollEvent = (event: any): void => {
    const target = event.target as HTMLElement;
    if (target && target.classList?.contains('mat-mdc-autocomplete-panel')) {
      this.autoComplete.updatePosition();
    } else {
      this.autoComplete.closePanel();
    }
  };

  selectSuggestion(event: any) {
    this.emitAddress.emit(event.option.value);
    console.log('SelectSuggestion Emitted');
  }
  getOptionText(option: any) {
    if (option.firstName) {
      return option.firstName;
    }

    if (option.name || option) return option.name || option;
  }
}
