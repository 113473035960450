import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FieldConfig } from "../models/field-config";
import { Observable } from "rxjs";

@Injectable()
export class FieldConfigMapper {
    constructor(private _http: HttpClient) {
    }

    loadFieldMappingConfig(): Observable<FieldConfig[]> {
        const jsonFile = `assets/config/field_config.json`;
        return this._http.get<FieldConfig[]>(jsonFile);
    }
}