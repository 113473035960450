import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/common-components/modal/modal.component';

@Component({
  selector: 'app-account-member-roster',
  templateUrl: './account-member-roster.component.html',
  styleUrls: ['./account-member-roster.component.scss']
})
export class AccountMemberRosterComponent {

  constructor(private modal: MatDialog) { }

  openModalAddMember() {
    alert('openModalAddMember')

    const modalRef = this.modal.open(ModalComponent, {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        buttonOneLabel: "cancel",
        buttonTwoLabel: "Add",
        title: "Add member",
        bodyComponent: "add-member",
      },
      panelClass: 'app-dialog',
    });
    modalRef.afterClosed().subscribe((item) => {
      console.log(item);
    })
  }

  tableColumns = [
    { label: 'Name', def: 'name', dataKey: 'name' },
    {
      label: 'Category',
      def: 'category',
      dataKey: 'category',
    },
    { label: 'Description', def: 'description', dataKey: 'description' },
  ];

  productsList = [
    {
      name: 'Laptop',
      category: 'Computer',
      description: 'Lorem Ipsum is simply dummy text',
    },
    {
      name: 'Mouse',
      category: 'Accessories',
      description: 'Lorem Ipsum is simply dummy text',
    },
    {
      name: 'Monitor',
      category: 'Gaming System',
      description: 'cLorem Ipsum is simply dummy text',
    },
    {
      name: 'PC',
      category: 'COMPUTER',
      description: 'Lorem Ipsum is simply dummy text',
    },
  ];

  editTable(item: any) {
    console.log(item);
  }

}
