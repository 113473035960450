<div class="link-status-container">
  <div class="payment-link-status">
    <div class="payment-link-status-top">
      <div class="payment-link-status-top-left">
        <h4>Full Board Membership</h4>
      </div>
      <!-- Hiding until PBC portal is released for members
      <div class="payment-link-status-top-right">
        <app-button buttonLabel="Visit Your Account Portal" buttonClass="primary-button"
          (onClick)="goToLoginPage()"></app-button>
      </div>
      -->
    </div>
    <div class="payment-link-status-bottom">
      <h5>{{ getMessage() }}</h5>
    </div>
  </div>
</div>