import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Setting } from '../models/setting';
import { AppConstants } from '../constants/app-constants';
import { BaseService } from './base.service';
import { BaseResponse } from '../models/base-response';

@Injectable({
  providedIn: 'root',
})
// TODO Need to refactor this service
export class SettingService extends BaseService {
  private settingsRecords: Record<string, BaseResponse<Setting[]>> = {};
  private appSettingsRecords: Setting[] = [];

  private fetchFromApi(url: string, settingKey: string): Observable<BaseResponse<any>> {
    return this._http.get<any>(url).pipe(
      tap((result) => {
        this.settingsRecords[settingKey] = result;
      })
    );
  }
  
  public getSysSettingsData(): Setting[] {
    return this.settingsRecords[AppConstants.SYSSETTING].data;
  }

  public getAppSettingsData(): Setting[] {
    if(this.appSettingsRecords.length == 0){
      this. appSettingsRecords = this.settingsRecords[AppConstants.APPSETTING]?.data;
    }
    return this.appSettingsRecords;
  }

  public getAppSettingValue(key: string) {
    return this.getAppSettingsData().filter((setting) => setting.key === key)[0]?.value || '';
  }

  public getSysSettingsByKey(type: string, key: string): Observable<BaseResponse<Setting[]>> {
    const settingKey = `${type}_${key}`;
    if (this.settingsRecords[settingKey]) {
      return of((this.settingsRecords[settingKey]));
    }

    const url = `${this.baseURL}/Settings?Key=${key}&Type=${type}`;
    return this.fetchFromApi(url, settingKey);
  }

  public getSysSettings(): Observable<BaseResponse<Setting[]>> {
    if (this.settingsRecords[AppConstants.SYSSETTING]) {
      return of(this.settingsRecords[AppConstants.SYSSETTING]);
    }

    const url = `${this.baseURL}/Settings/GetByType?Type=${AppConstants.SYSSETTING}`;
    return this.fetchFromApi(url, AppConstants.SYSSETTING);
  }

  public getAppSettings(): Observable<BaseResponse<Setting[]>> {
    if (this.settingsRecords[AppConstants.APPSETTING]) {
      return of(this.settingsRecords[AppConstants.APPSETTING]);
    }

    const url = `${this.baseURL}/Settings/GetByType?Type=${AppConstants.APPSETTING}`;
    return this.fetchFromApi(url, AppConstants.APPSETTING);
  }

  public getMergeAccountFields(): Observable<any> {
    return this._http.get('../assets/config/merge-account-fields.json');
  }

  public getMergeContactFields(): Observable<any> {
    return this._http.get('../assets/config/merge-contact-fields.json');
  }

  getSalutations() {
    const sysSettings = this.getSysSettingsData();
 
    if (sysSettings.length && sysSettings.some((data) => data.key === AppConstants.SYSSETTINGPREFIX)) {
      // order by displayText asc
      let salutations = sysSettings.filter((data) => data.key === AppConstants.SYSSETTINGPREFIX).sort((i: any, j: any) =>
        i.displayText.localeCompare(j.displayText)
      );
 
      // display beginning
      // ['Mr.', 'Ms.', 'Mrs.', 'Dr.'].reverse().forEach((item) => {
      //   const index = salutations.findIndex(obj => obj.displayText === item);
      //   salutations.unshift(salutations.splice(index, 1)[0])
      // });
 
      return salutations;
    } else { return []; }
  }  
}
