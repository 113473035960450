import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AppConstants } from '../../constants/app-constants';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-select',
  imports: [CommonModule, ReactiveFormsModule, MatSelectModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true
})
export class SelectComponent {

  @Input() label = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() items: any[] = [];
  @Input() labelKey: string = '';
  @Input() valueKey: string = '';
  @Input() control = new FormControl();
  @Input() errorMsgs: any;
  @Input() requireMark: boolean = false;
  DEFAULT_SELECT_VALUE = AppConstants.DROPDOWN_DEFAULT_VALUE;

}
