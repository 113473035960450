<div class="alert-modal">
    <div class="form-section">
        <div class="text-center">
            Are you sure you want to change the Primary Board Contact?
        </div>
    </div>
    <div class="modal-footer">
        <app-button buttonClass="secondary-button" buttonLabel="Cancel"
            [mat-dialog-close]></app-button>
        <app-button buttonClass="primary-button" buttonLabel="Confirm" (onClick)="markAsPbc()"></app-button>
    </div>
</div>