<div class="modal-header">
    <div *ngIf="data.title" mat-dialog-title>{{data.title}}</div>
    <mat-icon [mat-dialog-close] *ngIf="showCloseIcon">close</mat-icon>
</div>
<form [formGroup]="formGroup" (ngSubmit)="save()">
    <div class="choose-role">
    <app-checkbox
      checkBoxLabel="Primary Board Contact"
      [control]="formGroup.controls.pbc"
      (emitChange)="pbc($event)"
    ></app-checkbox>
    <div class="role-description">
    The primary board roster contact designated below will serve as the main point of contact for NACD membership.
    </div>
    <app-checkbox
      checkBoxLabel="Administrator"
      [control]="formGroup.controls.admin"
      (emitChange)="admin($event)"
    ></app-checkbox>
    <div class="role-description">
    The administrator will coordinate assignment of board roster, roster changes, payment confirmation, invoices, etc. with NACD.
  </div>
    <app-checkbox
      checkBoxLabel="None of the above"
      [control]="formGroup.controls.noneOfAbove"
      (emitChange)="noneOfAbove($event)"
    ></app-checkbox>
    </div>
    <div class="modal-footer">
      <app-button
        buttonClass="secondary-button"
        buttonLabel="Cancel"
        [mat-dialog-close]
      ></app-button>
      <app-button
        matStepperNext
        buttonLabel="CONTINUE"
        btnType="Next"
        [disable]="!formGroup.value.pbc&&!this.formGroup.value.admin&&!formGroup.value.noneOfAbove"
        buttonClass="primary-button"
        [mat-dialog-close]
      ></app-button>
    </div>
  </form>
  