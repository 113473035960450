import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-tabs',
  imports: [CommonModule, MatTabsModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true
})
export class TabsComponent {

  @Input() componentList:any;
  @Output() tabEmitter = new EventEmitter<any>();
  @ViewChild("matTab", {static: false}) tab!: MatTabGroup;

  emitActiveTab(tabChanged: MatTabChangeEvent) {
    this.tabEmitter.emit(tabChanged.index);
  }

}
