import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from './appconfig.service';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { CompanyRole, CompanyRoleType } from '../models/companyrole';
import { Profile } from '../models/profile';
import { BaseService } from './base.service';
import { BaseResponse } from '../models/base-response';
import { UtilityService } from './app-util.service';
import { ErrorMessage } from '../constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  _companyRoles!: CompanyRole[];
  _companyRoleTypes!: CompanyRoleType[];
  // TODO Need to remove this after fixing the dependency issue
  profile!: Profile;
  roasters!: Profile[];
  rostersForPayment!: Profile[];
  private ProfileDataObservable$ = new BehaviorSubject<any>({});
  profileData!: Profile;

  get companyRoleTypes() {
    return this._companyRoleTypes;
  }

  get companyRoles() {
    return this._companyRoles;
  }

  public getProfileDataObservable(): Observable<Profile> {
    return this.ProfileDataObservable$.asObservable();
  }

  public setProfileData(profile: Profile) {
    this.ProfileDataObservable$.next(profile);
    this.profileData = profile;
  }

  getRoleTypes() {
    this._http
      .get<BaseResponse<CompanyRoleType[]>>(
        `${this.profileAPIUrl}/CompanyRoleType`
      )
      .subscribe({
        next: (data) => {
          this._companyRoleTypes = data.data;
        },
        error: (err) => {
          console.log(`Failed to load Role Types: ${err}`);
        },
      });
  }

  getRole() {
    this._http
      .get<BaseResponse<CompanyRole[]>>(`${this.profileAPIUrl}/CompanyRole`)
      .subscribe({
        next: (data) => {
          this._companyRoles = data.data;
        },
        error: (err) => {
          console.log(`Failed to load Roles: ${err}`);
        },
      });
  }

  saveProfile(profile: Profile, previousEmail?: string, isMemberActiveInRoster: boolean = true): Observable<any> {
    const profiles = [profile];

    return !profile.id
      ? this._http.post<any>(
        `${this.profileAPIUrl}/Profile`,
        profiles
      )
      : this._http.put<any>(
        `${this.profileAPIUrl}/Profile?previousEmailId=${previousEmail}&IsMemberActiveInRoster=${isMemberActiveInRoster}`,
        profiles
      );
  }

  updateProfiles(profiles: Profile[]): Observable<any> {
    return this._http.put<any>(`${this.profileAPIUrl}/Profile`, profiles);
  }

  getProfilesByCompany(companyId: string): Observable<any> {
    return this._http.get<any>(
      `${this.profileAPIUrl}/Profile/Company/${companyId}`
    ).pipe(
      catchError((error)=> {
        this.snackBarService.showErrorMessage(error.error.message || ErrorMessage.UnexpectedError, 'OK');
        return throwError(() => new Error(error.error.message || ErrorMessage.UnexpectedError));
      })
    )
  }

  getProfilesByFirstName(
    firstName: string,
    companyId?: string
  ): Observable<any> {
    return this._http.get<any>(
      `${this.profileAPIUrl
      }/Profile/FirstName/${firstName.toLowerCase()}?companyId=${companyId}`
    );
  }
  getProfileById(profileId: string): Observable<any> {
    return this._http.get<any>(`${this.profileAPIUrl}/Profile/${profileId}`);
  }

  getProfileByEmail(email: string): Observable<any> {
    return this._http.get<any>(`${this.profileAPIUrl}/Profile/EMail/${email}`);
  }
  getProfile(): Observable<any> {
    return this._http.get<any>(`${this.profileAPIUrl}/Profile`);
  }

  updatePbc(
    companyId: string,
    currentPbc: string,
    previousPbc: string
  ): Observable<any> {
    return this._http.put<any>(
      `${this.profileAPIUrl}/Profile/UpdatePrimaryBoardContact?companyId=${companyId}&currentPBCEmail=${currentPbc}&previousPBCEmail=${previousPbc}`,
      {}
    );
  }

  updateAdmin(profile: any) {
    return this._http.put<any>(
      `${this.profileAPIUrl}/Profile/UpdateAdmin`,
      profile
    );
  }

  getBoardAdvisors() {
    return this._http.get<any>(`${this.profileAPIUrl}/Profile/BoardAdvisor`);
  }

  updatePrimaryBoardContactProfile(profile: any) {
    return this._http.put<any>(
      `${this.profileAPIUrl}/Profile/UpdatePrimaryBoardContactProfile`,
      profile
    );
  }
}
