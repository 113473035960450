import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AccountMemberRosterComponent } from './components/account-member-roster/account-member-roster.component';
import { AddMemberRoleFormComponent } from './components/add-member-role-form/add-member-role-form.component';
import { AddMemberComponent } from './components/add-member/add-member.component';
import { BoardAdvisorComponent } from './components/board-advisor/board-advisor.component';
import { ChooseRoleComponent } from './components/choose-role/choose-role.component';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { CompanynameConfirmboxComponent } from './components/companyname-confirmbox/companyname-confirmbox.component';
import { EditAdministratorComponent } from './components/edit-administrator/edit-administrator.component';
import { EditBoardComponent } from './components/edit-board/edit-board.component';
import { EditPbcComponent } from './components/edit-pbc/edit-pbc.component';
import { FirstnameConfirmboxComponent } from './components/firstname-confirmbox/firstname-confirmbox.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { MemberRosterActivateComponent } from './components/member-roster-activate/member-roster-activate.component';
import { MemberRosterAlertComponent } from './components/member-roster-alert/member-roster-alert.component';
import { MemberRosterDeleteComponent } from './components/member-roster-delete/member-roster-delete.component';
import { MemberRosterPbcComponent } from './components/member-roster-pbc/member-roster-pbc.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaymentCompletedComponent } from './components/payment-completed/payment-completed.component';
import { SuperAdminEditCompanyComponent } from './components/super-admin-edit-company/super-admin-edit-company.component';
import { SuperAdminEditGateConComponent } from './components/super-admin-edit-gate-con/super-admin-edit-gate-con.component';
import { SuperAdminEditMemberRosterComponent } from './components/super-admin-edit-member-roster/super-admin-edit-member-roster.component';
import { SuperAdminEditMembershipComponent } from './components/super-admin-edit-membership/super-admin-edit-membership.component';
import { AddHeaderInterceptor } from './core/interceptor/add-header.interceptor';
import { AppMaterialModule } from './material.module';
import { AddressAutoCompleteComponent } from './shared/common-components/address-autocomplete/address-autocomplete.component';
import { ButtonComponent } from './shared/common-components/button/button.component';
import { CheckboxComponent } from './shared/common-components/checkbox/checkbox.component';
import { DatePickerComponent } from './shared/common-components/date-picker/date-picker.component';
import { DateRangeComponent } from './shared/common-components/date-range/date-range.component';
import { DecreaseIncreaseBoxComponent } from './shared/common-components/decrease-increase-box/decrease-increase-box.component';
import { DedupeTableComponent } from './shared/common-components/dedupe-table/dedupe-table.component';
import { InputComponent } from './shared/common-components/input/input.component';
import { ModalWrapperComponent } from './shared/common-components/modal-wrapper/modal-wrapper.component';
import { ModalComponent } from './shared/common-components/modal/modal.component';
import { PreloaderComponent } from './shared/common-components/preloader/preloader.component';
import { RadioComponent } from './shared/common-components/radio/radio.component';
import { SelectComponent } from './shared/common-components/select/select.component';
import { SideMenuComponent } from './shared/common-components/side-menu/side-menu.component';
import { TableComponent } from './shared/common-components/table/table.component';
import { TabsComponent } from './shared/common-components/tabs/tabs.component';
import { ClickOutsideDirective } from './shared/directives/clickOutside.directive';
import { UtcDatePipe } from './shared/pipes/utc-date-pipe';
import { AppConfig } from './shared/services/appconfig.service';
import { FieldConfigMapper } from './shared/services/field-cofig-mapper.service';
import { RestAPIInterceptor } from './shared/services/rest-api.interceptor';
import { SignalrService } from './shared/services/signalr.service';
import { StepperService } from './shared/services/stepper.service';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.loadConfig();
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MatStepperModule,
    AuthConfigModule,
    // Common Components - Standalone
    AddressAutoCompleteComponent,
    ButtonComponent,
    CheckboxComponent,
    DateRangeComponent,
    DatePickerComponent,
    DecreaseIncreaseBoxComponent,
    InputComponent,
    PreloaderComponent,
    RadioComponent,
    SelectComponent,
    SideMenuComponent,
    TableComponent,
    TabsComponent,
    DedupeTableComponent,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.azure_ad_client_id,
          redirectUri: environment.azure_ad_redirect_url,
          authority: environment.azure_ad_authority_url,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.Read']],
        ]),
      }
    ),
  ],
  declarations: [
    AppComponent,
    ModalComponent,
    ModalWrapperComponent,
    HeaderComponent,
    FooterComponent,
    AddMemberComponent,
    AddMemberRoleFormComponent,
    AccountMemberRosterComponent,
    AccountDetailsComponent,
    EditBoardComponent,
    EditPbcComponent,
    EditAdministratorComponent,
    SuperAdminEditCompanyComponent,
    SuperAdminEditMemberRosterComponent,
    SuperAdminEditGateConComponent,
    MemberRosterAlertComponent,
    MemberRosterDeleteComponent,
    MemberRosterPbcComponent,
    MemberRosterActivateComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    HeaderMenuComponent,
    ClickOutsideDirective,
    BoardAdvisorComponent,
    FirstnameConfirmboxComponent,
    CompanynameConfirmboxComponent,
    PaymentCompletedComponent,
    UtcDatePipe,
    ChooseRoleComponent,
    CommonPopupComponent,
    SuperAdminEditMembershipComponent
  ],
  providers: [
    UtcDatePipe,
    CurrencyPipe,
    DatePipe,
    AppConfig,
    FieldConfigMapper,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestAPIInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { scrollStrategy: new NoopScrollStrategy() }
    },
    StepperService,
    SignalrService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe((config) => {
        console.log('ConfigLoaded', config);
      });
  }
}
