import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { EmailService } from 'src/app/shared/services/email.service';
import { EmailHelper } from 'src/app/shared/services/email-helper.service';
import { AppConstants, EmailTriggerTemplateId, ModuleType } from 'src/app/shared/constants/app-constants';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Setting } from 'src/app/shared/models/setting';

@Component({
  selector: 'app-board-advisor',
  templateUrl: './board-advisor.component.html',
  styleUrls: ['./board-advisor.component.scss']
})
export class BoardAdvisorComponent {

  addButtonName = 'Update'
  cancelButtonName = 'cancel'
  boardAdvisorData: any[] = []
  displayData: any[] = []
  settingData: any;
  company: any;
  companyid: any;
  membership: any
  boardData: any;
  appSettings: string = '';
  appSettingsData: any;
  AdvisorEmail!: string;
  validationMessages = {
    name: {
      required: "Name is required"
    }

  }


  constructor(private profileService: ProfileService,
    private registrationDataService: GlobalDataProviderService,
    private companyService: CompanyService,
    private modalService: ModalDataService,
    private membershipService: MembershipService,
    private emailService: EmailService,
    private emailHelperService: EmailHelper,
    private settingService: SettingService,
    private utilityService: UtilityService) { }
  id: any
  ngOnInit(): void {
    this.modalService.showLoader();
    this.settingByKeyAndType();
    this.company = this.registrationDataService.getCompanyData()
    this.companyid = this.companyService.activeCompany
    this.membership = this.membershipService.activeMembership
    this.boardData = this.membership.boardAdvisor
    this.boardAdvisor.patchValue({
      'name': this.boardData?.name,
      'email': this.boardData?.emailId,
      'phone': this.boardData?.phone
    })
    this.profileService.getBoardAdvisors().subscribe(
      res => {

        res.data.forEach((ele: any) => {
          if (ele.isBoardAdvisor) {
            this.boardAdvisorData.push(ele)
            //console.log(ele)
            const display: any = {
              id: ele.id,
              name: ele.firstName + " " + ele.lastName,
              email: ele.eMail,
              phone: ele.phone,
            }
            this.displayData.push(display)
          }
        })


        this.modalService.hideLoader();
      }
    )
    this.boardAdvisor.controls.name.valueChanges.subscribe((val) => {

      this.displayData.forEach((ele: any) => {

        if (val === ele.name) {
          this.id = ele.id;
          this.boardAdvisor.patchValue({
            'email': ele.email,
            'phone': ele.phone
          })
        }

      })
    })
    FormControlService.disableFormControls([this.boardAdvisor.controls.email, this.boardAdvisor.controls.phone])
  }

  boardAdvisor = new FormGroup({
    name: new FormControl('', [this.utilityService.noWhitespaceValidator, this.utilityService.minLengthValidator(3), this.utilityService.maxLengthValidator(255), Validators.pattern(AppConstants.NAME_REGEX)]),
    email: new FormControl('', [this.utilityService.minLengthValidator(10), this.utilityService.maxLengthValidator(255)]),
    phone: new FormControl('', [this.utilityService.minLengthValidator(5), this.utilityService.maxLengthValidator(255), Validators.pattern(AppConstants.PHONE_REGEX)]),
  })

  onSelectionChange(event: any) {
    //console.log(event)
    //console.log(this.boardAdvisor.value.email);
    this.displayData.forEach((ele: any) => {

      if (this.boardAdvisor.value.name === ele.name) {
        // this.boardAdvisor.value.email=ele.email;
        //this.boardAdvisor.value.phone=ele.phone

        this.boardAdvisor.patchValue({
          'email': ele.email,
          'phone': ele.phone
        })
      }
    })
    //console.log(this.boardAdvisor.value)
  }

  save() {
    this.modalService.showLoader();
    //console.log(this.id)
    //console.log(this.boardAdvisor.getRawValue())
    const board: any = {
      id: this.id,
      name: this.boardAdvisor.getRawValue().name,
      emailId: this.boardAdvisor.getRawValue().email,
      phone: this.boardAdvisor.getRawValue().phone
    }
    this.membership.boardAdvisor = board;
    // console.log(this.company)
    //  this.companyService.updateCompany(this.company).subscribe((res)=>{
    //   console.log(res)
    //   this.modalService.hideLoader();
    //   this.modalService.closeModal({ close: true });
    //  })

    this.membershipService.updateMembership(this.membership).subscribe((res) => {
      console.log(res)
      this.membershipService.setActiveMembership(res.data)
      this.modalService.hideLoader();
      this.advisorAssignment(res.data);
      this.modalService.closeModal({ close: true });
    })
  }


  advisorAssignment(response: any) {
    const companyId = response.companyId;
    this.AdvisorEmail = response.boardAdvisor.emailId;
    const module = ModuleType.MEMASSIGN;
    let recipients = this.appSettings + this.AdvisorEmail;
    const templateId = EmailTriggerTemplateId.MemberAdvisor;
    const receiptVariableData: { [key: string]: string } = {
      advisorName: response.boardAdvisor.name,
      boardName: this.companyid.name,
      currentYear: new Date().getFullYear().toString(),
    };
    this.emailService.saveEmailInfo(
      companyId,
      module,
      recipients,
      receiptVariableData,
      templateId)

  }

  settingByKeyAndType() {
    this.settingService
      .getSysSettingsByKey(
        AppConstants.APPSETTING,
        AppConstants.APPSETTING_EMAILLOG_MET_BDT_RECIPIENTS
      )
      .subscribe((response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          this.appSettings = this.appSettings + response.data[i].value + ',';
        }
      });
  }


}
