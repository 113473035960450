import { Component, Injector, OnInit } from "@angular/core";
import { TOKEN } from "src/app/shared/constants/app-constants";
import { MembershipDto } from "src/app/shared/models/order-details";
import { MembershipService } from "src/app/shared/services/membership.service";
import { ModalDataService } from "src/app/shared/services/modal-data.service";
import { GlobalDataProviderService } from "src/app/shared/services/registration-data.service";
import { AppSnackBarService } from "src/app/shared/services/snackbar.service";
import { UnsavedChangesService } from "src/app/shared/services/unsaved-changes.service";

@Component({
    selector: 'app-member-roster-delete',
    templateUrl: './member-roster-delete.component.html',
    styleUrls: ['./member-roster-delete.component.scss']
})
export class MemberRosterDeleteComponent implements OnInit {
    profileToUpdate: any;
    membership!: MembershipDto;
    isLocalDelete = false;

    constructor(private injector: Injector,
        private modalService: ModalDataService,
        private snackbar: AppSnackBarService,
        private membershipService: MembershipService,
        private globalData: GlobalDataProviderService,
        private unsavedChangesService: UnsavedChangesService) {

    }

    ngOnInit(): void {
        const data: any = this.injector?.get(TOKEN);
        this.profileToUpdate = data.profile;
        this.isLocalDelete = data.isLocalDelete;
        this.membership = this.membershipService.activeMembership!;
    }

    confirm() {
        if (!this.profileToUpdate) {
            return
        }

        if (!this.isLocalDelete) {
            this.markAsDeleted();
        } else {
            this.remove();
        }
    }

    markAsDeleted() {
        if (!('member' in this.profileToUpdate)) {
            const mem = this.membership.member.find(m => m.id === this.profileToUpdate.id);
            if (mem) this.profileToUpdate['member'] = mem;
        }

        this.profileToUpdate.member.isDeleted = true;
        this.modalService.showLoader();
        this.membershipService.updateGracePeriod(this.membership.id!, this.profileToUpdate.member).subscribe({
            next: (value) => {
                this.modalService.hideLoader();
                this.snackbar.showSuccessMessage("Member removed from the roster", "OK");
                this.membershipService.setActiveMembership(value.data);
                this.globalData.setMemberRosterProfile(this.profileToUpdate);
                this.modalService.closeModal({ close: true, result: true });
            },
            error: (error) => {
                this.modalService.hideLoader();
                this.snackbar.showErrorMessage("Failed to remove the member to the roster", "OK");
                this.modalService.closeModal({ close: true, result: false });
            }
        })
    }

    remove() {
        const allProfiles = this.globalData.getMemberRosterProfiles();

        if (allProfiles.some(p => p.id === this.profileToUpdate.id)) {
            const profiles = allProfiles.filter(p => p.id !== this.profileToUpdate.id).map(m => m);
            this.globalData.setMemberRosterProfiles(profiles);
            this.globalData.setMemberRosterDataObservable();
            this.unsavedChangesService.hasMemberLocalDeleted = true;
            this.unsavedChangesService.hasValidated = false;
            this.snackbar.showSuccessMessage("Member removed from the roster", "OK");
        } else {
            this.snackbar.showErrorMessage("Failed to remove the member to the roster", "OK");
        }

        this.modalService.closeModal({ close: true, result: true });
    }
}