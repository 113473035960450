import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public activeUser: AppUser | undefined;

  private user = new BehaviorSubject<AppUser | undefined>(undefined);
  user$ = this.user.asObservable();

  setUser(user: AppUser | undefined, isOpenPayment = false) {
    this.user.next(user);
    this.activeUser = user;

    if (!isOpenPayment)
      localStorage.setItem('user', JSON.stringify(user));

    // // TODO move this to a guard or component after implementing Authentication and Authorization
    // if (user.isSuperAdmin) {
    //   this.router.navigate(['/admin']);
    // } else {
    //   this.router.navigate(['/account']);
    // }
  }

  isSuperAdminUser() {
    return this.activeUser?.roles.includes('Super Admin');
  }

  isPbc() {
    return this.activeUser?.roles.includes('Primary Board Contact')
  }

  isAdmin() {
    return this.activeUser?.roles.includes('Administrator')
  }

  logoff() {
    this.user.next(undefined);
    localStorage.removeItem('user');
    localStorage.removeItem('companyId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('API_TOKEN')
    localStorage.removeItem('adminLink')
    this.activeUser = undefined;
    // this.router.navigate(['/']);
    // window.location.reload();
  }

  constructor() {

    //load user from local storage
    const user = localStorage.getItem('user');
    if (user) {
      const savedUser = JSON.parse(user);
      this.setUser(savedUser);
    }
  }


}

export class AppUser {
  given_name!: string;
  family_name!: string;
  email!: string;
  roles!: string[];
  token!: string;
}
