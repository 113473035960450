import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs';
import { VALIDATION_MESSAGES_CONST } from 'src/app/shared/constants/common-constants';
import { CompanyRoleType } from 'src/app/shared/models/companyrole';
import { ProfileRole } from 'src/app/shared/models/membership';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { AppSnackBarService } from 'src/app/shared/services/snackbar.service';
import { getRoleYears, pushOtherRoleToBottom } from 'src/app/shared/util-functions/util_functions';
import { CommonPopupComponent } from '../common-popup/common-popup.component';
import { AppConstants, MemberRoleType} from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-add-member-role-form',
  templateUrl: './add-member-role-form.component.html',
  styleUrls: ['./add-member-role-form.component.scss'],
})
export class AddMemberRoleFormComponent {
  @Input() roleInfoForm: FormGroup;
  @Input() existingRoles: ProfileRole[] = [];
  validationMessages = VALIDATION_MESSAGES_CONST;

  companyRoleTypes: CompanyRoleType[] = [];
  companyRolesMaster: any = [];
  companyRoles: any = [];

  roleStartYears: any[] = [];
  roleEndYears: any[] = [];
  isSuperAdmin = false;
  appSettingsData: any;
  priorityOneImplementationDate = new Date();
  deprecatedExecutiveRoleIDs: string[] = [];
  executiveCompanyRoleTypeID: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private registrationService: GlobalDataProviderService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private modal: MatDialog,
    private snackbarService: AppSnackBarService,
    private settingService: SettingService
  ) {
    this.loadData();

    this.roleInfoForm = this.fb.group({
      memberRoles: this.fb.array([]),
    });
  }

  ngOnChanges() {
    this.checkExistingRoles();
  }

  checkExistingRoles() {
    this.memberRoles.removeAt(0);

    if (!!this.existingRoles?.length) {
      this.existingRoles.forEach((er) => {
        // for admin/pbc - no need to display
        if (
          !!this.isSuperAdmin ||
          (!this.isSuperAdmin && !this.isDeletedRole(er))
        ) {
          this.addRole(er);
        }
      });

      this.roleInfoForm.markAllAsTouched();
      this.roleInfoForm.updateValueAndValidity();
    } else {
      this.addRole();
    }
  }

  loadData() {
    this.isSuperAdmin = this.authService.isSuperAdminUser() || false;

    const roleTypes = this.profileService.companyRoleTypes;
    this.companyRoleTypes = roleTypes.sort((i: any, j: any) =>
      i.description.localeCompare(j.description)
    );

    this.companyRoleTypes = pushOtherRoleToBottom(this.companyRoleTypes)
    this.companyRolesMaster = this.profileService.companyRoles;

    this.roleStartYears = getRoleYears();
    this.roleEndYears.push(
      { id: 'Current', description: 'Current' },
      ...this.roleStartYears
    );

    let executiveCompanyRoleType = roleTypes.filter((r: any) => (r.description === MemberRoleType.EXECUTIVE));
    if(executiveCompanyRoleType){
      this.executiveCompanyRoleTypeID = executiveCompanyRoleType[0].id;
    }

    // Loads the Priority 1 Implementation Date from settings container
    this.appSettingsData = this.settingService.getAppSettingsData();
    if (this.appSettingsData) {
      let appSetting_PriorityImplementationDate = this.appSettingsData.filter(
        (setting: any) => setting.key === AppConstants.APPSETTING_MEMBERROSTER_PRIORITY1_IMPLEMENTATIONDATE
      );

      if(appSetting_PriorityImplementationDate){
        this.priorityOneImplementationDate = new Date(appSetting_PriorityImplementationDate[0].value);
      }

      let appSetting_DeprecatedRoleIDs = this.appSettingsData.filter(
        (setting: any) => setting.key === AppConstants.APPSETTING_MEMBERROLES_DEPRECATED_EXECUTIVE_ROLEIDS
      );

      if (appSetting_DeprecatedRoleIDs[0].value && typeof appSetting_DeprecatedRoleIDs[0].value === 'string'){
        this.deprecatedExecutiveRoleIDs = appSetting_DeprecatedRoleIDs[0].value.split(',');
      }
    }
  }

  getRoles(group: any) {
    const selectedRoleTypeId = this.companyRoleTypes.some(
      (rt) => rt.code === group.value.roleType)
      ? this.companyRoleTypes.find(
        (rt) => rt.code === group.value.roleType
      )?.id
      : null;

    if (!this.isBeforePriorityOneDate(group.value) && selectedRoleTypeId === this.executiveCompanyRoleTypeID) { // Hide Deprecated Executive Roles
      const sortedRoles = this.companyRolesMaster
        .filter((r: any) => (r.companyRoleTypeId === selectedRoleTypeId) && !this.deprecatedExecutiveRoleIDs.includes(r.id))
        .sort((i: any, j: any) =>
          i.description.localeCompare(j.description)
        );
        return pushOtherRoleToBottom(sortedRoles);
    }
    else if(this.companyRolesMaster.length && selectedRoleTypeId) {
      const sortedRoles = this.companyRolesMaster
        .filter((r: any) => r.companyRoleTypeId === selectedRoleTypeId)
        .sort((i: any, j: any) =>
          i.description.localeCompare(j.description)
        );
        return pushOtherRoleToBottom(sortedRoles);
    }
    else {
      return [];
    }
  }

  getControls(group: any, control: string) {
    return group.controls[control];
  }

  isDeletedRole(roleInfo: ProfileRole) {
    return !!roleInfo.hideFromPortal;
  }

  get memberRoles() {
    return this.roleInfoForm?.get('memberRoles') as FormArray;
  }

  isBeforePriorityOneDate(memberRole?: ProfileRole): boolean{
    let rolecreationDate = new Date(memberRole?.createdDateTime || '');
    if(rolecreationDate <= this.priorityOneImplementationDate) {
      return true;
    }
    return false;
  }

  subscribeToFieldChanges(newRole: FormGroup, isOldMemberRole: boolean){

    newRole
      .get('startYear')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {

        let hideFromPortal = newRole.value.hideFromPortal;

        if (!isOldMemberRole && !hideFromPortal) {
          let endYear = newRole.get('endYear')?.value ?? 0;

          if (!!endYear) {
            isNaN(+endYear) ? (endYear = new Date().getFullYear()) : '';
            // Set validators based on startYear and endYear
            newRole.get('startYear')?.setValidators([Validators.max(+endYear)]);
          }

          if (!value) {
            newRole.get('startYear')?.setValidators([Validators.required]);
          }

          if (newRole.get('endYear')?.value) {
            newRole.get('endYear')?.clearValidators();
            newRole.get('endYear')?.setValidators([Validators.required]);
          }

          // Update validity
          setTimeout(() => {
            if (newRole.get('endYear')?.value) {
              newRole.get('endYear')?.updateValueAndValidity({ emitEvent: false });
              newRole.get('startYear')?.updateValueAndValidity();
            } else {
              newRole
                .get('startYear')
                ?.updateValueAndValidity({
                  emitEvent: false,
                  onlySelf: !newRole.get('endYear')?.invalid,
                });
            }
          }, 500);
        }
      });

    newRole.get('endYear')?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {

        let hideFromPortal = newRole.value.hideFromPortal;

        if (!isOldMemberRole && !hideFromPortal){
          const startYear = newRole.get('startYear')?.value ?? 0;

          if (!!startYear) {
            // Set validators based on startYear and endYear
            newRole.get('endYear')?.setValidators([Validators.min(+startYear)]);
          }

          if (!value) {
            newRole.get('endYear')?.setValidators([Validators.required]);
          }

          if (newRole.get('startYear')?.value) {
            newRole.get('startYear')?.clearValidators();
            newRole.get('startYear')?.setValidators([Validators.required]);
          }

          // Update validity
          setTimeout(() => {
            if (newRole.get('startYear')?.value) {
              newRole.get('startYear')?.updateValueAndValidity({ emitEvent: false });
              newRole.get('endYear')?.updateValueAndValidity();
            } else {
              newRole
                .get('endYear')
                ?.updateValueAndValidity({
                  emitEvent: false,
                  onlySelf: !newRole.get('startYear')?.invalid,
                });
            }
          }, 500);
        }
      });

    newRole.get('roleType')?.valueChanges.pipe(distinctUntilChanged()).subscribe((currentRoleType) => {
      let previousRoleType = newRole.value.roleType;
      if(previousRoleType != currentRoleType){
        newRole.get('role')?.patchValue('');
      }
    });
  }

  addRole(existingRole?: ProfileRole): void {
    const companyData =
      this.companyService.activeCompany ??
      this.registrationService.getCompanyData();

    const isOldMemberRole = this.isBeforePriorityOneDate(existingRole); // is MemberRole Created Before PriorityOne Implementation Date
    const hideFromPortal = existingRole?.hideFromPortal;

    // Create a new FormGroup for each role
    const newRole = this.fb.group({
      id: [existingRole?.id || ''],
      roleType: [existingRole?.roleType || '', isOldMemberRole || hideFromPortal ? [] : Validators.required],
      role: [existingRole?.role || '', isOldMemberRole || hideFromPortal  ? [] : Validators.required],
      startYear: [existingRole?.startYear || '', isOldMemberRole || hideFromPortal  ? [] : Validators.required],
      endYear: [existingRole?.endYear || '', isOldMemberRole || hideFromPortal  ? [] : Validators.required],
      companyId: [existingRole?.companyId || companyData?.id],
      sfContactId: [existingRole?.sfContactId || ''],
      profileId: [existingRole?.profileId || ''],
      sFRoleId: [existingRole?.sFRoleId || ''],
      sfUserId: [existingRole?.sfUserId || ''],
      sfAccountId: [existingRole?.sfAccountId || ''],
      isActive: [
        _.isBoolean(existingRole?.isActive) ? existingRole?.isActive : true,
      ],
      hideFromPortal: [
        _.isBoolean(existingRole?.hideFromPortal)
          ? existingRole?.hideFromPortal
          : false,
      ],
      createdDateTime: [existingRole?.createdDateTime || new Date()]
    });

    // Push the FormGroup into the FormArray
    this.memberRoles.push(newRole);

    this.subscribeToFieldChanges(newRole, isOldMemberRole);
  }

  removeRole(group: FormGroup, index: number): void {
    if (!group.value.id) {
      this.memberRoles.removeAt(index);
    } else {
      const { role, roleType, startYear, endYear } = group.value;
      const isMemberRoleActive = role && roleType && startYear && endYear.toLowerCase() === 'Current';
      group.patchValue({ isActive: isMemberRoleActive, hideFromPortal: true });

      this.snackbarService.showSuccessMessage(
        'Role removed from the member profile',
        'OK'
      );
    }
  }

  minRoleLengthInvalid() {
    return (
      this.memberRoles?.getRawValue()?.filter((mr) => !this.isDeletedRole(mr))
        .length === 1
    );
  }

  deleteConfirmationPopup(group: any, index: number) {
    if (this.minRoleLengthInvalid()) {
      this.snackbarService.showErrorMessage(
        `At least 1 Active Role is required.`,
        'OK'
      );
      return;
    }

    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: CommonPopupComponent,
        buttonOneLabel: 'Cancel',
        buttonTwoLabel: 'Confirm',
        text: `Are you sure you want to remove this role?`,
        showCloseIcon: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(CommonPopupComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (!!item) {
        this.removeRole(group, index);
        this.clearValidatorsForMemberRole(group); // clear validators for member role fields if removed (hidefromPortal is set to true)
      }
    });
  }

  clearValidatorsForMemberRole(group: FormGroup) {
    const fields = ['roleType', 'role', 'startYear', 'endYear'];
    fields.forEach(field => {
      const control = group.get(field);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }
}
