import { Injectable } from '@angular/core';
import { AppConfig } from './appconfig.service';
import { ProfileRole } from '../models/membership';
import { BaseResponse } from '../models/base-response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileRoleService {
  profileRoleApiUrl!: string;

  constructor(private http: HttpClient) {
    this.profileRoleApiUrl = `${AppConfig.configSettings.env.profileAPIUrl}/ProfileRoles`;
  }

  getAllProfileRoles(): Observable<BaseResponse<ProfileRole[]>> {
    return this.http.get<BaseResponse<ProfileRole[]>>(
      `${this.profileRoleApiUrl}`
    );
  }

  getById(id: string): Observable<BaseResponse<ProfileRole[]>> {
    return this.http.get<BaseResponse<ProfileRole[]>>(
      `${this.profileRoleApiUrl}/${id}`
    );
  }

  getByProfileId(profileId: string): Observable<BaseResponse<ProfileRole[]>> {
    return this.http.get<BaseResponse<ProfileRole[]>>(
      `${this.profileRoleApiUrl}/Profile/${profileId}`
    );
  }

  getByCompanyId(companyId: string): Observable<BaseResponse<ProfileRole[]>> {
    return this.http.get<BaseResponse<ProfileRole[]>>(
      `${this.profileRoleApiUrl}/${companyId}`
    );
  }

  create(profileRole: ProfileRole[]) {
    return this.http.post<any>(`${this.profileRoleApiUrl}/UpdateProfileRoles`, profileRole);
  }

  update(profileRole: ProfileRole[]) {
    return this.http.put<any>(`${this.profileRoleApiUrl}/UpdateProfileRoles`, profileRole);
  }

  updateFromSF(profileRole: ProfileRole) {
    return this.http.put<any>(`${this.profileRoleApiUrl}/UpdateProfileRoleFromSF`, profileRole);
  }
}
