import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
  selector: 'app-checkbox',
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, FormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true
})
export class CheckboxComponent {

  @Input() inputData: any;
  @Input() label = "";
  @Input() checkBoxLabel = "";
  @Input() control = new FormControl();
  @Output() emitCheck = new EventEmitter<any>();
  @Output() emitChange = new EventEmitter<any>();
  @Input() hint = '';
  @Input() requireMark: boolean = false;
  @Input() labelLink = "";
  @Input() labelLinkUrl = "";
  @Input() errorMsgs : any;
  @Input() isSubmit :boolean | undefined;
  @Input() disable: boolean = false;
  @Input() showHtml: any;

  checked() {
    this.emitCheck.emit();
  }

  onCheckBoxChange(event: MatCheckboxChange) {
    this.emitChange.emit(event);
  }

}
