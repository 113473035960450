import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { Setting } from 'src/app/shared/models/setting';
import { CompanyService } from 'src/app/shared/services/company.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { PreloaderService } from 'src/app/shared/services/preloader.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'app-super-admin-edit-gate-con',
  templateUrl: './super-admin-edit-gate-con.component.html',
  styleUrls: ['./super-admin-edit-gate-con.component.scss']
})
export class SuperAdminEditGateConComponent {
  company: any;
  preferNoContact:any
  membership:any;
  data:any;
  gateConText:any;
  commonSettings: any;
  constructor( private registrationDataService: GlobalDataProviderService,
    private settingService: SettingService,
    private companyService: CompanyService,    
    private preloaderService: PreloaderService,
    private membershipService: MembershipService,
    
  ){}


  ngOnInit(): void{
    this.data=this.settingService.getAppSettingsData();
    this.company=this.companyService.activeCompany;
    console.log(this.company)
    
    this.preferNoContact=this.company.preferNoContact
    this.membership = this.membershipService.activeMembership!;
    console.log(this.membership);

    const interval = setInterval(() => {
      if (this.data) {
        this.gateConText=this.getSysSetting(AppConstants.APPSETTING_GATE_CON)
        
        this.gateConText.forEach((ele:any) => {
          console.log(ele) 
          this.gateConText=ele         
        });
        
      }
      
      clearInterval(interval);
    },30)
  }

  public getSysSetting(key: string): Setting[] {
    this.commonSettings = this.data.filter((da:any) => da.key === key);
    return this.commonSettings;
  }


  formGroup = new FormGroup({
    isContact: new FormControl(false),
   
  })

  getCheck() {
    if(this.membership.preferNoContact==null){
      this.membership.preferNoContact=true;
    }
    else{
      this.membership.preferNoContact=!this.membership.preferNoContact
    }
  }
  

  saveGateCon() {
    this.preloaderService.showLoader();
    // this.companyService.updateCompany(this.company).subscribe(
    //   result=>{
    //     this.preloaderService.hideLoader();
    //     console.log(result)
    //   }
    // )
    this.membershipService.updateMembership(this.membership).subscribe(
      result=>{
        this.preloaderService.hideLoader();
        console.log(result)
      }
    )
  }

}
