import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MembershipRequestType } from '../constants/app-constants';
import {
  EmailLogDetail,
  EmailAddress,
  EmailLog,
  EmailStatus,
  attachment,
} from '../models/emaillog';
import { BaseService } from './base.service';

export enum EmailType {
  PaymentReceipt = 'PaymentReceipt',
  RenewalInvoice = 'RenewalInvoice',
  PaymentLink = 'PaymentLink',
  PaymentReminder = 'PaymentReminder',
  MemberRoster = 'MemberRoster',
  CorporateJoins = 'CorporateJoins',
  Invoice = 'Invoice',
}

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseService {
  sender: EmailAddress = {
    Name: environment.mail_service_sender_name,
    Email: environment.mail_service_sender_email,
  };
  id: string = 'E06E75B1-D4DD-4A8A-B814-CCAA1C9C978F'; // It will be replaced with new GUID in Email Service API.;
  contactUSURL: string = 'https://nacdonline.org'; // TODO Add Contact US URL
  loginURL: string = window.origin; // TODO Add login URL
  emailLogs: EmailLog[] = [];
  memAdvisorEmailLog: EmailLog[] = [];
  boardAdvisorSender = {} as EmailAddress;

  createEmailLog(emailData: EmailLogDetail, toEmail: string) {
    const senderTemp =
      emailData.module &&
      [
        'RenewalInvoice',
        'PaymentLink',
        'MemberRoster',
        'CorporateJoins',
      ].includes(emailData.module) &&
      this.boardAdvisorSender?.Email
        ? this.boardAdvisorSender
        : this.sender;

    const emailLogData: EmailLog = {
      id: this.id,
      module: emailData.module,
      sender: senderTemp,
      recipients: toEmail,
      scheduleDateTime: emailData.emailScheduleDate,
      variableData: emailData.variableData,
      statusCode: EmailStatus.InProgress,
      statusMessage: 'In Progress',
      companyId: emailData.companyId,
      templateId: emailData.templateId,
      attachments: [],
    };

    if (typeof emailData.attachmentInBase64 !== 'string') {
      emailLogData.attachments = emailData.attachmentInBase64;
    } else {
      const attachment = {} as attachment;
      attachment.Base64 =
        emailData.module === 'RenewalInvoice' && !emailData.needsTrigger
          ? ''
          : emailData.attachmentInBase64;
      attachment.FileName = emailData.fileName || '';
      emailLogData.attachments.push(attachment);
    }
    this.emailLogs.push(JSON.parse(JSON.stringify(emailLogData)));
  }

  createEmailLogForPBCAndAdmin(emailLog: EmailLogDetail) {
    if (
      emailLog.module &&
      [
        EmailType.PaymentReceipt.toString(),
        EmailType.RenewalInvoice.toString(),
      ].includes(emailLog.module)
    ) {
      emailLog.variableData['loginURL'] = this.loginURL;
    } else if (emailLog.module == EmailType.PaymentLink) {
      emailLog.variableData['contactUS'] = this.contactUSURL;
    }

    if (emailLog.PBCEmail) {
      emailLog.variableData['memberName'] = emailLog.PBCMemberName || '';

      if (emailLog.module == EmailType.PaymentLink) {
        emailLog.variableData['username'] = emailLog.PBCEmail;
        emailLog.variableData[
          'paymentURL'
        ] = `${emailLog.variableData['paymentURL']}/${emailLog.PBCEmail}`;
      }

      this.createEmailLog(emailLog, emailLog.PBCEmail);
    }

    if (emailLog.AdminEmail && emailLog.AdminEmail != emailLog.PBCEmail) {
      emailLog.variableData['memberName'] = emailLog.AdminMemberName || '';
      if (emailLog.module == EmailType.PaymentLink) {
        emailLog.variableData['username'] = emailLog.AdminEmail;
        let lastIndex = emailLog.variableData['paymentURL'].lastIndexOf('/');
        emailLog.variableData['paymentURL'] = emailLog.variableData[
          'paymentURL'
        ].slice(0, lastIndex);
        emailLog.variableData[
          'paymentURL'
        ] = `${emailLog.variableData['paymentURL']}/${emailLog.AdminEmail}`;
      }

      this.createEmailLog(emailLog, emailLog.AdminEmail);
    }
  }

  getAllEmailLogs(): Observable<any> {
    return this._http.get<any>(`${this.baseURL}/EmailLog`);
  }

  postEmailLog(emailLog: EmailLog[], isDelete = false): Observable<any> {
    console.log('CREATING EMAIL LOG: ', JSON.stringify(emailLog));
    return this._http.post<[any]>(
      `${this.baseURL}/EmailLog?isDelete=${isDelete}`,
      emailLog
    );
  }

  triggerEmailLog(emailLog: EmailLog[], toasterMessage?: string) {
    this._http.post<any>(this.mailTriggerUrl, emailLog).subscribe((data) => {
      console.log(data);
      if (toasterMessage) {
        this.snackBarService.showSuccessMessage(toasterMessage!, 'OK');
      }
    });
  }

  saveEmailLog(
    isPayLater = false,
    isTriggerEmail = false,
    toasterMessage?: string,
    isDeleteEmail = false
  ) {
    if (this.emailLogs?.length) {
      const isDelete =
        this.registrationDataService.membershipUpdateState ===
          MembershipRequestType.RENEW ||
        this.registrationDataService.membershipUpdateState ===
          MembershipRequestType.RETRY ||
        isDeleteEmail;
      this.postEmailLog(
        this.emailLogs,
        isPayLater ? false : isDelete
      ).subscribe({
        next: (data) => {
          this.emailLogs = [];
          let triggerData = [];
          if (data.data?.some((se: any) => se.module === 'PaymentReceipt')) {
            triggerData = data.data?.filter(
              (se: any) => se.module === 'PaymentReceipt'
            );
          } else if (
            data.data?.some((se: any) => se.module === 'PaymentLink')
          ) {
            triggerData = data.data.filter(
              (se: any) => se.module === 'PaymentLink'
            );
          }
          if (triggerData?.length || isTriggerEmail) {
            this.triggerEmailLog(
              isTriggerEmail ? data.data : triggerData,
              toasterMessage
            );
          }
        },
        error: (error) => console.log('EmailLog Error: ', error),
      });
    }
  }

  saveEmailInfo(
    companyId: string,
    module: string,
    toEmails: string,
    variableData: { [key: string]: string },
    templateId: string,
    fileName?: string,
    attachmentInBase64?: string
  ) {
    if (module === 'MemberAdvisorAssignment') {
      this.sender.Email = environment.mail_service_sender_advisorEmail;
      const emailLogInfo: EmailLog = {
        id: this.id,
        module: module,
        sender: this.sender,
        recipients: toEmails,
        scheduleDateTime: new Date(),
        variableData: variableData,
        statusCode: EmailStatus.InProgress,
        statusMessage: 'In Progress',
        companyId: companyId,
        templateId: templateId,
        attachments: [],
      };
      this.memAdvisorEmailLog.push(JSON.parse(JSON.stringify(emailLogInfo)));
      this.postEmailLog(this.memAdvisorEmailLog, false).subscribe({
        next: (data) => {
          this.sender = {
            Name: environment.mail_service_sender_name,
            Email: environment.mail_service_sender_email,
          };
          this.memAdvisorEmailLog = [];
        },
        error: (error) => console.log('EmailLog Error: ', error),
      });
    }
  }
}
