<div class="table-container">
  <table mat-table [dataSource]="matTableData" class="app-table" matSort>
    <ng-container
      *ngFor="let column of tableColumns"
      [matColumnDef]="column.def"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="column.hideSort"
      >
        {{ column.label }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container
          *ngIf="
            !column.clickable && column.label !== 'VALID EMAILS';
            else clickableColumn
          "
        >
          <ng-container *ngIf="column.dataType === 'date'">
            {{
              datePipe.transform(row[column.dataKey], column.formatt) ||
                row[column.dataKey]
            }}
          </ng-container>
          <ng-container *ngIf="column.dataType === 'checkbox'">
            <app-checkbox
              [inputData]="row.isSelected"
              [disable]="row.isDisable"
              (emitChange)="updateSelection($event, row)"
            ></app-checkbox>
          </ng-container>
          <ng-container *ngIf="column.dataType === 'boolean'">
            {{ row[column.dataKey] ? column.trueLabel : column.falseLabel }}
          </ng-container>
          <ng-container
            *ngIf="column.dataType !== 'date' && column.dataType !== 'boolean'"
          >
            {{ row[column.dataKey] }}{{ column.suffix ? column.suffix : "" }}
          </ng-container>
        </ng-container>
        <ng-template #clickableColumn>
          <ng-container *ngIf="column.label !== 'VALID EMAILS'">
            <a
              (click)="onColumnClick(row)"
              [ngClass]="{ 'pointer-none': row.isDisableTranId }"
              >{{ row[column.dataKey] }}</a
            >
          </ng-container>
          <ng-container *ngIf="column.label === 'VALID EMAILS'">
            {{ splitEmail(row) }}
          </ng-container>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row">
        <!-- <mat-icon
          *ngIf="isEdit"
          fontSet="material-icons-outlined"
          class="table-icon"
          (click)="onEdit(row)"
          >edit_square</mat-icon
        > -->
        <img
          *ngIf="isEdit"
          src="../../../../assets/images/edit.svg"
          alt="edit"
          class="table-icon action-edit-icon"
          (click)="onEdit(row)"
          title="Edit"
        />

        <mat-icon
          *ngIf="isDelete"
          fontSet="material-icons-outlined"
          class="table-icon"
          (click)="onDelete($event)"
          >delete</mat-icon
        >
        <mat-icon
          *ngIf="isPBC"
          fontSet="material-icons-outlined"
          class="table-icon pbc-icon"
          (click)="onPBC($event)"
          >local_police</mat-icon
        >
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableDisplayColumns; sticky: true"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayColumns"></tr>
  </table>
</div>
