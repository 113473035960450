<div class="form-radio" [ngClass]="rowRadio ? 'row-radio' : ''">
    <div class="form-label-hint" *ngIf="label">
        <mat-label class="form-label">{{label}}<span *ngIf="requireMark" class="require-mark">*</span></mat-label>
        <mat-hint class="form-hint">{{hint}}</mat-hint>
    </div>
    <mat-radio-group aria-label="Select an option" [formControl]="control">
        <mat-radio-button *ngFor="let item of radioList"
        [value]="item.value"
        [(ngModel)]="data" 
        ngDefaultControl
        (change)="dataChange($event)" 
        >{{item.label}}</mat-radio-button>
    </mat-radio-group>
    <div class="form-error" *ngIf="control.invalid && isSubmit ">
        <div *ngFor="let err of control.errors | keyvalue">{{errorMsgs[err.key]}}</div>
    </div>
</div>

