import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type { CanDeactivateFn } from '@angular/router';
import { map } from 'rxjs';
import { CommonPopupComponent } from 'src/app/components/common-popup/common-popup.component';
import { ICanComponentDeactivate } from '../models/common';
import { UnsavedChangesService } from '../services/unsaved-changes.service';

export const UnsavedChangesGuard: CanDeactivateFn<ICanComponentDeactivate> =
  (component: ICanComponentDeactivate) => {
    const modal = inject(MatDialog);
    const unsavedChangesService = inject(UnsavedChangesService);

    if (component.canDeactivate()) {
      return true;
    }

    let msg = 'Navigating to the selected menu will clear the data entered. Would you like to proceed?';

    if (unsavedChangesService.hasMemberLocalDeleted) {
      msg = 'Navigating to a different page will undo the updates made to the roster. Would you like to proceed?';
    }

    // Confirmation dialog
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: CommonPopupComponent,
        ConfirmBtn: 'Proceed',
        text: msg
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };

    return modal
      .open(CommonPopupComponent, modalConfig)
      .afterClosed()
      .pipe(map(result => {

        if (!!result) {
          if (!!unsavedChangesService.hasUnsavedChanges) {
            unsavedChangesService.hasUnsavedChanges = false;
          }

          if (!!unsavedChangesService.hasMemberLocalDeleted) {
            unsavedChangesService.hasMemberLocalDeleted = false;
          }
        }

        return !!result;
      }));
  };

