<div
  class="download-roster"
  [ngClass]="{ 'mt-12': isRosterPage }"
  *ngIf="!hideDownload"
></div>
<div
  class="download-roster"
  [ngClass]="{ 'mt-12': isRosterPage }"
  *ngIf="!hideDownload"
></div>
<div class="table-container">
  <table mat-table [dataSource]="datasource" class="app-table" matSort>
    <ng-container matColumnDef="sequence">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row; let i = index" class="table-data-number">
        {{ i + 1 }}
      </td>
    </ng-container>


    <ng-container matColumnDef="member">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Member Details</th>
      <td mat-cell *matCellDef="let row">
        <div class="table-name-email-pbc">
          <div class="table-name-email">
            <div class="table-name">{{ row.member.name }}</div>
            <div class="table-email">{{ row.member.email }}</div>
          </div>
          <div class="table-pbc" *ngIf="row.member.pbc">
            Primary Board Contact
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="statusDisplay">
        Status
      </th>
      <td mat-cell *matCellDef="let row">
        <app-button
          *ngIf="row.status || (!row.status && row.member.isAddForRenewal && forRenewal)"
          buttonLabel="Active"
          buttonClass="active-button"
          (onClick)="emitAction(row, 'deactivate')"
          [title]="!fromModal ? 'Deactivate member to revoke access' : ''"
          [disable]="row.member.pbc"
        ></app-button>
        <app-button
          *ngIf="!row.status && !row.member.isAddForRenewal || (!row.status && row.member.isAddForRenewal && !forRenewal)"
          matStepperPrevious
          buttonLabel="Deactivated"
          buttonClass="de-activated-button"
          (onClick)="emitAction(row, 'activate')"
          [title]="!fromModal ? 'Activate member to provide access' : ''"
          [disable]="(!activateStatus && !row.member.email)"
        ></app-button>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row">
        <img
          src="../../../assets/images/edit.svg"
          alt="edit"
          class="table-icon action-edit-icon"
          (click)="emitAction(row, 'add')"
          title="Edit member details"
        />
        <img
          src="../../../assets/images/delete.svg"
          alt="delete"
          class="table-icon"
          [ngClass]="
            row.member.pbc ? 'action-delete-icon-disable' : 'action-delete-icon'
          "
          title="Remove member"
          (click)="emitAction(row, 'remove')"
          *ngIf="hasDelete && !forRenewal && !row.member.isAddForRenewal"
        />
        <img
          src="../../../assets/images/pbc.svg"
          alt="pbc action"
          class="table-icon action-pbc-icon"
          [ngClass]="
          row.statusDisplay !=='Deactivated' ? '' : 'action-pbc-icon-disable icon-click-disable'
          "
          (click)="emitAction(row, 'pbc')"
          title="Make Primary Board Contact"
          *ngIf="hasPbc && !row.member.pbc && !forRenewal"
        />
        <img
          src="../../../assets/images/pbc.svg"
          alt="pbc"
          class="table-icon action-pbc-icon-active"
          *ngIf="hasPbc && row.member.pbc && !forRenewal"
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
</div>
