<div class="modal-container">
  <div class="modal-header">
    <div *ngIf="modalData.title" mat-dialog-title>{{ modalData.title }}</div>
    <div *ngIf="modalData?.showDenotes" class="denotes-info"><span class="astrik">*</span> denotes a required field</div>
    <mat-icon [mat-dialog-close] (click)="close()">close</mat-icon>
  </div>
  <div mat-dialog-content class="modal-body">
    <div
      *ngIf="modalData.text && modalData.text !== 'Transaction ID'"
      class="dialog-text"
    >
      {{ modalData.text }}
    </div>
    <div *ngIf="modalData.bodyComponent" [ngSwitch]="modalData.bodyComponent">
      <app-add-member *ngSwitchCase="'add-member'"></app-add-member>
      <ng-container *ngSwitchCase="'account-order-history-receipt'">
        <div #receiptContainer></div>
      </ng-container>
      <app-edit-board *ngSwitchCase="'edit-board'"></app-edit-board>
      <app-edit-pbc *ngSwitchCase="'edit-pbc'"></app-edit-pbc>
      <app-edit-administrator
        *ngSwitchCase="'edit-administrator'"
      ></app-edit-administrator>
      <div class="flex justify-content-center" *ngSwitchCase="'count-box'">
        <app-decrease-increase-box
          [min]="modalData.min"
          [max]="modalData.max"
          [(ngModel)]="modalData.value"
        />
      </div>
      <div class="flex justify-content-center" *ngSwitchCase="'input-box'">
        <!-- TODO Replace with reusable component if needed -->
        <!-- TODO Need to style if not using reusable component -->
        <input type="text" class="normal-input" [(ngModel)]="modalData.value" />
      </div>
      <div class="form-row" *ngSwitchCase="'input-box-validation'">
        <div class="form-row-cell">
          <app-input
            label="Transaction ID"
            [control]="inputFormControl"
            [errorMsgs]="{ required: modalData.text + ' is required' }"
            [requireMark]="true"
          ></app-input>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!data.isShowIcon" mat-dialog-actions class="modal-footer">
    <app-button
      buttonClass="secondary-button"
      [buttonLabel]="modalData.buttonOneLabel"
      (onClick)="modalButtonEventOne()"
      [mat-dialog-close]
    ></app-button>
    <app-button
      buttonClass="primary-button"
      [disable]="
        inputFormControl.invalid &&
        modalData.bodyComponent === 'input-box-validation'
      "
      [buttonLabel]="modalData.buttonTwoLabel"
      (onClick)="saveData()"
    ></app-button>
  </div>
  <div *ngIf="data.isShowIcon" class="icon-buttons-group modal-footer">
    <app-button buttonClass="icon-button" iconName="forward_to_inbox" (onClick)="emailButton()" title="Send Email"></app-button>
    <app-button buttonClass="icon-button" iconName="print" (onClick)="saveData()" title="Print"></app-button>  
    <app-button buttonClass="icon-button" iconName="download" (onClick)="downloadButton()" title="Download"></app-button>
  </div>
</div>
