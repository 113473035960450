import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PreloaderService {

    private preLoader$ = new BehaviorSubject<any>({});

    getPreloaderObs() {
        return this.preLoader$.asObservable();
    }

    showLoader() {
        this.preLoader$.next({value: true, cssClass: 'preloader-page'});
    }

    hideLoader() {
        this.preLoader$.next({value: false, cssClass: 'preloader-page'});
    }

    showLoaderinContainer() {
        this.preLoader$.next({value: true, cssClass: 'preloader-section'});
    }

    hideLoaderinContainer() {
        this.preLoader$.next({value: false, cssClass: 'preloader-section'});
    }
}