import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-date-picker',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  standalone: true,
  
})
export class DatePickerComponent {

  @Input() inputDate = '';
  @Input() label = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() requireMark: boolean = false;  
  @Input() control = new FormControl();
  @Input() errorMsgs: any;
  @Input() click: any;
  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
  onDateChange(event: any): void {
    this.dateChanged.emit(event.value);    
  }
}

