export const environment = {
  production: false,
  name: 'prod',
  ids_api_client_id: 'cmmp_api_client',
  ids_api_client_secret: 'P@ssword1',
  ids_api_scope: 'PRFSVC MEBSVC',
  ids_api_grant_type: 'client_credentials',
  ids_frontend_client_id: 'cmmp_web_client',
  ids_frontend_scope: 'openid profile email',
  ids_frontend_responseType: 'code',
  ids_frontend_authority: 'https://login.membership.dev.nacdonline.org',
  ids_frontend_redirect_url: `${window.origin}/signin-oidc`,
  ids_frontend_logout_url: `${window.origin}`,
  mail_service_sender_name: 'NACD',
  mail_service_sender_email: 'noreply@nacdonline.org',
  mail_service_sender_advisorEmail: 'boardadvisor@nacdonline.org', 
  azure_ad_client_id: '2b38e449-ec90-4151-91c4-d8d3a98161e7',
  azure_ad_redirect_url: `${window.origin}/admin`,
  azure_ad_authority_url:
    'https://login.microsoftonline.com/f6f46c35-8ab6-40b3-97f6-4b95ff6b0ca6',
  ids_update_password:
    'https://login.membership.dev.nacdonline.org/account/updatepassword/?returnUrl=https://cmmp-membership-web-dev.azurewebsites.net',
  azure_function_client_id: 'profile_api_client',
  azure_function_scope: 'SFSSVC',
  superadmin_url: 'https://cmmp-membership-web-dev.azurewebsites.net/admin',
  azure_secret_id:'P@ssword1',
  azure_grant_type: 'client_credentials',
};
