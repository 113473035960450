<div class="dedupe-table">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
    (matSortChange)="handleParentTableSort($event)" class="dedupe-table-parent">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
        [disabled]="sortColumnList?.includes(column) ? false : true">
        {{ column | titlecase }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="table-data-flex">
          <div class="flex-align-center">
            <div *ngIf="column == arrowColumn" class="down-arrow" ></div>
            <div *ngIf="element[column]" class="column-main-data">
              {{ element[column] }} 
            </div>
          </div>
          <ng-container *ngFor="let item of parentTableColumnMapping">
            <div *ngIf="column == item?.columnName" class="column-multi-data">
              <div>{{ element[item?.combineData] }}</div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="isAction && column == 'action'">
          <div class="action-buttons">
            <button *ngIf="isEdit" (click)="onEdit(element, $event)">
              Ignore Duplicates
            </button>
            <button *ngIf="isDelete && !element.mergeText" (click)="onDelete(element, $event)">
              {{mergeText}}
            </button>
            <button *ngIf="element.mergeText" [disabled]="element.disableMerge" (click)="onDelete(element, $event)">
              {{element.mergeText}}
            </button>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div>
          <table #innerTables mat-table [dataSource]="element.duplicateList" class="dedupe-table-child">
            <ng-container matColumnDef="{{ innerColumn }}" *ngFor="let innerColumn of innerTableColumns">
              <th mat-header-cell *matHeaderCellDef>
                {{ innerColumn | titlecase }}
              </th>
              <td mat-cell *matCellDef="let el; let childIndex = index">
                <div class="child-cell-data-wrapper">
                  <div class="child-cell-data-wrapper-content">
                    <app-checkbox *ngIf="[innerColumn] == checkBoxColumn && el.count >= 10 && !el.masterRecord"
                      (emitChange)="toggleChildItemCheckbox($event, el)"
                      [inputData]="findItemFromDuplicateItemsToMerge(el.duplicateGroupId, el.id)"></app-checkbox>
                    <!-- <input
                      type="checkbox"
                      *ngIf="[innerColumn] == checkBoxColumn && element.count > 2"
                    /> -->
                    <div>
                      <div class="child-table-data">
                        {{ el[innerColumn] }}
                      </div>
                      <ng-container *ngFor="let item of childTableColumnMapping">
                        <div *ngIf="innerColumn == item?.columnName" class="combine-child-table-data">
                          <!-- {{element[item?.combineData]}} -->
                          <ng-container *ngFor="let combineItems of item.combineData">
                            <div [hidden]="!el[combineItems]">{{ el[combineItems] }}</div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="el.newRecord && innerColumn === checkBoxColumn" class="newBadge">
                    new
                  </div>
                </div>

                <ng-container *ngIf="isActionInnerTable && innerColumn == 'action'">
                  <div class="action-buttons inner-table-actions">
                    <mat-icon *ngIf="isEdit" (click)="
                        !el.masterRecord
                          ? changeMasterRecord(el, $event, childIndex)
                          : null
                      " class="action-icon-change-record action-icon"
                      [ngClass]="el.masterRecord ? 'master-record' : ''" [title]="
                        el.masterRecord
                          ? 'Master record'
                          : 'Change master record'
                      ">
                      emoji_events
                    </mat-icon>
                    <mat-icon class="action-icon-delete action-icon material-icons-outlined" *ngIf="
                        isDelete && !el.masterRecord
                      " (click)="onDeleteDedupeChild(el, $event, childIndex)" fontSet="material-icons-outlined"
                      title="Ignore record">
                      delete
                    </mat-icon>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="innerTableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: innerTableColumns"></tr>
          </table>
          <mat-paginator #innerPaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onInnerTablePageChange($event)"></mat-paginator>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex" (click)="toggleInnerTable(row)"
      [class.expanded-row]="expandedElement === row || i === index" class="parent-row" (click)="logIndex(i)"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row">

    </tr>
  </table>
  <div *ngIf="searchstatus" class="data-not-found">&nbsp;&nbsp;No Records Found!</div>
  <mat-paginator #paginator hideFirstLastButtons
    [pageSizeOptions]="[10, 25, 50]" [length]="totalCount" (page)="emitParentPageChange($event)" class="sticky-bottom"></mat-paginator>
</div>