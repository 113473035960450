import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { UnsavedChangesService } from 'src/app/shared/services/unsaved-changes.service';
import { AddMemberComponent } from '../add-member/add-member.component';
@Component({
  selector: 'app-companyname-confirmbox',
  templateUrl: './companyname-confirmbox.component.html',
  styleUrls: ['./companyname-confirmbox.component.scss'],
})
export class CompanynameConfirmboxComponent {
  companyId?: string;
  message: any;
  cancelButtonVisible: boolean = true;
  okButtonText: string = 'OK';
  @Output() public modal_principal_parent = new EventEmitter();
  showCloseIcon = true;
  constructor(
    private dialogRef: MatDialogRef<CompanynameConfirmboxComponent>,
    private parentRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private authService: AuthService,
    private globalData: GlobalDataProviderService,
    private unsavedChangesService: UnsavedChangesService
  ) {
    if (data) {
      this.message = data.data;

      if (this.message == AppConstants.ADMIN_PBC_SUBSCRIPTION_ACTIVE ||
        this.message == AppConstants.ADMIN_PBC_SUBSCRIPTION_EXPIRED) {
        this.cancelButtonVisible = false;
      }
      if (
        this.message == AppConstants.SUPER_ADMIN_COMPANY_SUBSCRIPTION_ACTIVE ||
        this.message == AppConstants.SUPER_ADMIN_COMPANY_SUBSCRIPTION_EXPIRED
      ) {
        this.okButtonText = 'YES';
        this.cancelButtonVisible = true;
      }
      this.companyId = data.companyId;
      this.parentRef = data.companySuggestions;
    }
  }
  closeDialog() {
    this.dialogRef.close();
    if (this.authService.isSuperAdminUser()) {
      this.unsavedChangesService.hasUnsavedChanges = false;
      this.globalData.setCompanyId(this.companyId!);
      this.router.navigate(['admin/edit-corporate-boards']);
    }
  }
}
