import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponse } from '../models/base-response';
import { Company, CorporateBoard } from '../models/company';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  isCompanyNotRegisteredYet = true;
  activeCompany!: Company;
  dayJobCompany!: Company;
  role: any;
  dayJobCompany$ = new BehaviorSubject<any>({});

  setDayJobCompany(newDayJobCompany: Company) {
    this.dayJobCompany = newDayJobCompany;
    this.dayJobCompany$.next(newDayJobCompany);
  }

  private _companyId: string | undefined =
    localStorage.getItem('companyId') ?? undefined;
  public get companyId(): string {
    return this.activeCompany?.id ?? this._companyId ?? '';
  }
  public set companyId(v: string) {
    this._companyId = v;
  }

  getCompany(): Observable<BaseResponse<Company[]>> {
    console.log('In company service' + this.baseURL);
    return this._http.get<BaseResponse<Company[]>>(`${this.baseURL}/Company`);
  }

  getCorporateBoards(
    pageSize: number,
    direction: string,
    filter?: string
  ): Observable<BaseResponse<any[]>> {
    let url = `${this.baseURL}/Company/CorporateBoard?pageSize=${pageSize}&direction=${direction}`;
    if (filter) {
      filter = encodeURIComponent(filter);
      url = url + `&companyName=${filter}`;
    }
    return this._http.get<BaseResponse<CorporateBoard[]>>(url);
  }

  getCompanyById(companyId: string): Observable<any> {
    return this._http.get<Company>(`${this.baseURL}/Company/${companyId}`);
  }

  saveCompany(company: Company): Observable<any> {
    return this._http.post<any>(`${this.baseURL}/Company`, company);
  }

  saveDayJobCompany(company: Company): Observable<any> {
    return this._http.post<any>(
      `${this.baseURL}/Company/DayJobCompany`,
      company
    );
  }

  updateCompany(company: Company): Observable<any> {
    return this._http.put<any>(`${this.baseURL}/Company`, company);
  }

  searchCompanyByName(companyName: string): Observable<any> {
    console.log('companyName in CompanyService' + companyName);
    //return this._http.get<any>(`${this.baseURL}/Company/${companyName}`);
    return this._http.get<any>(
      `${this.baseURL}/Company/dayJobCompanyName?dayjobcompanyName=${companyName}`
    );
  }

  searchCompanyForDayJobCompany(companyName: string): Observable<any> {
    return this._http.get<any>(
      `${this.baseURL}/Company/dayJobCompanyName?dayjobcompanyName=${companyName}`
    );
  }

  getProfileWithDayJobCompany(companyId: string): Observable<any> {
    return this._http.get<Company>(
      `${this.baseURL}/Company/GetProfilesWithDayJobCompanyName/${companyId}`
    );
  }

  public get setRole(): string {
    return this.role;
  }
  public set setRole(v: string) {
    this.role = v;
  }
}
