<div class="account-header-section">
  <div class="account-header-title" *ngIf="!isSuperAdmin">
    Company Account Details
  </div>
</div>
<div class="contact-info-container">
  <div class="contact-info-box">
    <div class="contact-info-title">
      <span>Board Information</span>
      <mat-icon (click)="editBoard()" title="Edit">edit</mat-icon>
    </div>
    <div class="contact-info-name">{{ companyData.name }}</div>
    <div class="contact-info-type">{{ companyData.type }}</div>
    <div class="contact-info-category">{{ companyData.industry }}</div>
    <div class="contact-info-url" *ngIf="companyData.website">
      {{ companyData.website }}
    </div>
    <div class="contact-info-address mt-10 mb-10">
      <div *ngIf="isAdminPhoneHide" class="contact-info-label">Address:</div>
      {{ companyAddress }}<br />
      {{ companyCity }}<br />
      {{ companyData.address.country }}
    </div>
  </div>

  <div class="contact-info-box" *ngIf="primaryContact">
    <div class="contact-info-title">
      <span>Primary Board Contact</span>
      <!--mat-icon (click)="editProfile(true)" title="Edit">edit</mat-icon-->
    </div>
    <div class="contact-info-name">
      {{ primaryContact.firstName }} {{ primaryContact.lastName }}
    </div>
    <div class="contact-info-type">{{ primaryContact.eMail }}</div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div *ngIf="isPrimaryPhoneHide" class="contact-info-label">Phone:</div>
      <div class="contact-info-data">{{ primaryContact.phone }}</div>
    </div>
    <div class="contact-info-address mt-10 mb-10">
      <div *ngIf="isAdminPhoneHide" class="contact-info-label">Address:</div>
      {{ pbcAddress }}<br />
      {{ pbcCity }}<br />
      {{ primaryContact.address?.country }}
    </div>
  </div>

  <div class="contact-info-box" *ngIf="adminContact">
    <div class="contact-info-title">
      <span>Administrator</span>
      <mat-icon (click)="editProfile(false)" title="Edit">edit</mat-icon>
    </div>
    <div class="contact-info-name">
      {{ adminContact.firstName }} {{ adminContact.lastName }}
    </div>
    <div class="contact-info-type">{{ adminContact.eMail }}</div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div *ngIf="isAdminPhoneHide" class="contact-info-label">Phone:</div>
      <div class="contact-info-data">{{ adminContact.phone }}</div>
    </div>
    <div class="contact-info-address mb-10">
      <div *ngIf="isAdminPhoneHide" class="contact-info-label">Address:</div>
      {{ adminAddress }}<br />
      {{ adminCity }}<br />
      {{ adminContact.address?.country }}
    </div>
  </div>

  <div class="contact-info-box">
    <div class="contact-info-title">
      <span>Membership Details</span>
      <mat-icon *ngIf="isSuperAdmin"  [ngClass]="membership?.endDate? '' : 'icon-disable'" (click)="editMembership()" title="Edit">edit</mat-icon>
    </div>

    <div class="contact-info-name">Corporate Membership</div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div class="contact-info-label">Registration Date:</div>
      <div class="contact-info-data">
        {{ membership.registeredDate | date }}
      </div>
    </div>
    <div class="contact-info-data-label mt-10">
      <div class="contact-info-label">Membership End Date:</div>
      <div class="contact-info-data">{{ membership.endDate | date }}</div>
    </div>
    <div
      class="contact-info-data-label mt-10 mb-10"
      *ngIf="membership.status?.toLowerCase() !== 'completed'"
    >
      <div class="contact-info-label">Payment Due Date:</div>
      <div class="contact-info-data">
        {{ membership.paymentDueDate | date }}
      </div>
    </div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div class="contact-info-label">Payment Status:</div>
      <div class="contact-info-data">{{ membership.status }}</div>
    </div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div class="contact-info-label">Unallocated Board Seats:</div>
      <div class="contact-info-data">{{ unallocatedSeats }}</div>
    </div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div class="contact-info-label">Board Seats Allowed:</div>
      <div class="contact-info-data">{{ membership.count }}</div>
    </div>

    <app-button
      buttonLabel="Renew Now"
      buttonClass="primary-button"
      *ngIf="
        (isRenewPeriodActive && isSuperAdmin) ||
        (isSuperAdmin && isShowRenew && membership?.paymentDueDate)
      "
      (onClick)="doRenew()"
    ></app-button>
    <app-button
      buttonLabel="Pay Now"
      buttonClass="primary-button"
      *ngIf="isPayNow && isSuperAdmin"
      (onClick)="payNow()"
    ></app-button>
  </div>

  <div class="contact-info-box">
    <div class="contact-info-title">
      <span>NACD Member Engagement Advisor</span>
      <mat-icon *ngIf="isSuperAdmin" (click)="editAdvisor()" title="Edit">edit</mat-icon>
    </div>
    <div class="contact-info-name">{{ boardAdvisor?.name }}</div>
    <div class="contact-info-type">{{ boardAdvisor?.emailId }}</div>
    <div class="contact-info-data-label mt-10 mb-10">
      <div class="contact-info-label">Phone:</div>
      <div class="contact-info-data">{{ boardAdvisor?.phone }}</div>
    </div>
  </div>
</div>
