<div class="form-checkbox">
    <div class="form-label-hint" *ngIf="label">
        <mat-label class="form-label">{{label}}<span *ngIf="requireMark" class="require-mark">*</span></mat-label>
        <mat-hint class="form-hint">{{hint}}</mat-hint>
    </div>
    <mat-checkbox [ngClass]="{ 'pointer-none': disable }" (click)="checked()" (change)="onCheckBoxChange($event)" [(ngModel)]="inputData"  [formControl]="control" [disabled]="disable"><div [innerHTML]="checkBoxLabel"></div> <a [href]="labelLinkUrl"
            *ngIf="labelLink" >{{labelLink}}</a></mat-checkbox>
    <div class="form-error" *ngIf="control.hasError('required') && isSubmit ">

        <div *ngFor="let err of control.errors | keyvalue">{{errorMsgs[err.key]}}</div>
    </div>
</div>