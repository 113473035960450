<h4 class="white-text" *ngIf="matStep">Enter Your Board Information</h4>
<form [formGroup]="boardInfoForm" (ngSubmit)="boardInfoForm.valid && save()">
  <div [ngClass]="matStep ? 'stepper-content' : ''">
    <div class="form-title" *ngIf="matStep">
      <h5>Board Information</h5>
      <div class="mandatory-field-info">
        <span>*</span> denotes a required field
      </div>
    </div>
    <!-- form section starts -->
    <div class="form-section">
      <!-- <div class="denotes-info" *ngIf="!matStep"><span class="astrik">*</span> denotes a required field</div> -->
      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <!-- <app-input label="Company Name" [control]="boardInfoForm.controls.companyName"
                        [errorMsgs]="validationMessages.companyName" [requireMark]="true"></app-input> -->
          <!-- <button mat-raised-button         matTooltip="Info about the company"
        aria-label="Button that displays a tooltip when focused or hovered over"> -->

        <app-address-autocomplete
        label="Company Name"
        [formControl]="boardInfoForm.controls.companyName"
        [errorMsgs]="validationMessages.companyName"
        [requireMark]="true"
        mat-raised-button
        (emitAddress)="selectCompany($event)"
        [hint]="'Please enter your Company\'s Legal Name'"
        [suggestions]="companySuggestions"
      ></app-address-autocomplete>
        </div>
        <div class="form-row-cell">
          <app-input
            label="Website"
            [control]="boardInfoForm.controls.website"
            [errorMsgs]="validationMessages.website"
          ></app-input>
        </div>
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <app-checkbox
            [requireMark]="isRequiredFalse ? false : true"
            [control]="boardInfoForm.controls.isUSAddress"
            checkBoxLabel="Address Autocomplete (US only)"
            (emitChange)="changeUSAddress($event)"
            [inputData]="defaultUSAddress"
          ></app-checkbox>
        </div>
        <!-- [control]="formGroup.controls.isPrimary"-->
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <app-address-autocomplete
            label="Address Line 1"
            [formControl]="boardInfoForm.controls.addressLine1"
            [errorMsgs]="validationMessages.addressLine1"
            [requireMark]="isRequiredFalse ? false : true"
            (emitAddress)="selectSuggestion($event)"
            [suggestions]="suggestions"
          ></app-address-autocomplete>
          <!-- <app-input *ngIf="!defaultUSAddress" label="Address Line 1" [formControl]="boardInfoForm.controls.addressLine1"
                        [errorMsgs]="validationMessages.addressLine1" [requireMark]="true"></app-input> -->
        </div>
        <div class="form-row-cell">
          <app-input
            label="Address Line 2"
            [control]="boardInfoForm.controls.addressLine2"
            [errorMsgs]="validationMessages.addressLine2"
          ></app-input>
        </div>
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <app-input
            label="City"
            [control]="boardInfoForm.controls.city"
            [errorMsgs]="validationMessages.city"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-input>
        </div>
        <div class="form-row-cell">
          <app-input
            label="State"
            [control]="boardInfoForm.controls.state"
            [errorMsgs]="validationMessages.state"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-input>
        </div>
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <app-input
            label="Country"
            [control]="boardInfoForm.controls.country"
            [errorMsgs]="validationMessages.country"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-input>
        </div>
        <div class="form-row-cell">
          <app-input
            label="Zip Code"
            [control]="boardInfoForm.controls.zipcode"
            [errorMsgs]="validationMessages.zipcode"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-input>
        </div>
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row">
        <div class="form-row-cell">
          <app-select
            label="Industry"
            [items]="industryData"
            [control]="boardInfoForm.controls.industry"
            labelKey="displayText"
            valueKey="value"
            [errorMsgs]="validationMessages.industry"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-select>
        </div>
        <div class="form-row-cell">
          <app-select
            label="Company Type"
            [items]="companyTypeData"
            [control]="boardInfoForm.controls.companyType"
            labelKey="displayText"
            valueKey="value"
            [errorMsgs]="validationMessages.companyType"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-select>
        </div>
      </div>
      <!-- form row ends -->

      <!-- form row starts -->
      <div class="form-row" *ngIf="!isRequiredFalse">
        <div class="form-row-cell">
          <app-select
            label="How did you hear about us?"
            [items]="referenceTypeData"
            labelKey="displayText"
            valueKey="value"
            [control]="boardInfoForm.controls.referenceType"
            [errorMsgs]="validationMessages.referenceType"
            [requireMark]="isRequiredFalse ? false : true"
          ></app-select>
        </div>
      </div>
      <!-- form row ends -->
      <!-- form row starts -->
      <div class="form-row" *ngIf="!isRequiredFalse">
        <div class="form-row-cell">
          <app-checkbox
            [requireMark]="true"
            [control]="boardInfoForm.controls.isLegelCompany"
            checkBoxLabel="I confirm that I have entered my Company's Legal Name"            
            [errorMsgs]="validationMessages.isLegelCompany"
            [isSubmit]="isSubmit"            
          ></app-checkbox>         
        </div>
      </div>
      <!-- form row ends -->
    </div>
  </div>

  <div class="form-buttons" *ngIf="matStep">
    <app-button
      matStepperNext
      buttonLabel="Next"
      btnType="submit"
      buttonClass="primary-button"
      [disable]="!boardInfoForm.valid"
    ></app-button>
  </div>
  <div class="modal-footer" *ngIf="!matStep">
    <app-button
      buttonClass="secondary-button"
      buttonLabel="Cancel"
      [mat-dialog-close]
      id="close"
    ></app-button>
    <app-button
      buttonClass="primary-button"
      [buttonLabel]="registrationDataService.isUpdateBoardInfo ? 'Update' : 'Add'"
      [disable]="!boardInfoForm.valid"
      btnType="submit"
    ></app-button>
  </div>
  <!-- form section ends -->
</form>
