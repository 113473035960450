import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'app-edit-administrator',
  templateUrl: './edit-administrator.component.html',
  styleUrls: ['./edit-administrator.component.scss']
})
export class EditAdministratorComponent {

  companyFieldError = {
    required: "Company Name is required",
    minlength: "Company Name should be more than 3 characters",
    maxlength: "Company Name cannot be more than 10 characters",
    pattern: AppConstants.COMPANY_NAME_ERROR_MESSAGE
  }

  requireError = {
    required: "Prefix is required",
  }

  prfixSelect:any;

  constructor(private utilityService:UtilityService, private settingService: SettingService){
    this.prfixSelect = this.settingService.getSalutations();
  }

  formGroup = new FormGroup({
    companyName: new FormControl('', [this.utilityService.noWhitespaceValidator, this.utilityService.minLengthValidator(3), this.utilityService.maxLengthValidator(10), Validators.pattern(AppConstants.COMPANY_NAME_REGEX)]),
    prefix: new FormControl('', [this.utilityService.noWhitespaceValidator]),
    isPrimary: new FormControl('', [Validators.required]),
  })

  getCheck() {
    console.log("checked!")
  }
  
}
