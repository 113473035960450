import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-completed',
  templateUrl: './payment-completed.component.html',
  styleUrls: ['./payment-completed.component.scss'],
})
export class PaymentCompletedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
