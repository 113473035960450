import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailTriggerTemplateId, ModuleType } from '../constants/app-constants';
import {
  EmailAddress,
  EmailLog,
  EmailLogDetail,
  EmailStatus,
  attachment,
} from '../models/emaillog';
import { MembershipDto } from '../models/order-details';
import { Profile } from '../models/profile';
import { CompanyService } from './company.service';
import { EmailService, EmailType } from './email.service';
import { HtmlRendererService } from './html-renderer.service';
import { MembershipService } from './membership.service';
import { PaymentService } from './payment.service';
import { SalesService } from './sales.service';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { Setting } from 'src/app/shared/models/setting';
import { GlobalDataProviderService } from './registration-data.service';
import { UtilityService } from './app-util.service';

@Injectable({
  providedIn: 'root',
})
export class EmailHelper {
  templateId = '';
  receiptData: any = [];
  newPBCEmailLogs: EmailLog[] = [];
  deleteReminderAfterPayNow: boolean = false;
  enablePaymentReminder: any;
  enableRenewalInvoice: any;
  constructor(
    private emailService: EmailService,
    private datePipe: DatePipe,
    private htmlRenderer: HtmlRendererService,
    private companyService: CompanyService,
    private paymentSvc: PaymentService,
    private membershipService: MembershipService,
    private currencyPipe: CurrencyPipe,
    private salesService: SalesService,
    private registrationDataService: GlobalDataProviderService
  ) {}

  paymentReminderEmailLogCreation(
    primaryContactData: Profile,
    administratorData: Profile,
    membership: MembershipDto,
    netAmount: any
  ) {
    this.emailService.boardAdvisorSender = {} as EmailAddress;
    let PBCMemberName = '';
    let PBCEmail = '';
    if (primaryContactData) {
      PBCEmail = primaryContactData.eMail!;
      PBCMemberName =
        primaryContactData.firstName + ' ' + primaryContactData.lastName;
    }

    let AdminMemberName = '';
    let AdminEmail = '';
    if (administratorData) {
      AdminEmail = administratorData.eMail!;
      AdminMemberName =
        administratorData.firstName + ' ' + administratorData.lastName;
    }

    const daysBeforePaymentDueDate = [30, 15, 7, 2];

    const membershipTermStartDate = membership.startDate;
    if (membershipTermStartDate) {
      let termStartDate = new Date(membershipTermStartDate) as any;
      termStartDate.setDate(new Date(termStartDate.getDate() + 60));
      let PaymentDueDate = termStartDate;

      let paymentReminderVariableData: { [key: string]: string } = {};
      paymentReminderVariableData['invoiceNo'] = this.paymentSvc.invoiceNo;
      paymentReminderVariableData['fullAmount'] = netAmount?.toString();
      paymentReminderVariableData['currentYear'] = new Date()
        .getFullYear()
        ?.toString();
      paymentReminderVariableData['contactURL'] =
        'https://www.nacdonline.org/about/contact-us/';
      paymentReminderVariableData['boardName'] =
        this.companyService.activeCompany?.name!;
      paymentReminderVariableData['dueDate'] = this.datePipe.transform(
        termStartDate!,
        'MM/dd/yyyy'
      )!;
      paymentReminderVariableData['monthDay'] = this.datePipe.transform(
        termStartDate!,
        'MM/dd'
      )!;

      for (const days of daysBeforePaymentDueDate) {
        const reminderDate = new Date(PaymentDueDate);
        reminderDate.setDate(PaymentDueDate.getDate() - days);
        paymentReminderVariableData['finalReminder'] = '';
        paymentReminderVariableData['reminderCount'] = '';
        if (days === 2) {
          paymentReminderVariableData['finalReminder'] = 'Final';
        } else if (days === 7) {
          paymentReminderVariableData['reminderCount'] = '3';
        } else if (days === 15) {
          paymentReminderVariableData['reminderCount'] = '2';
        }
        const paymentReminderData = {
          PBCEmail: PBCEmail,
          PBCMemberName: PBCMemberName,
          AdminEmail: AdminEmail,
          AdminMemberName: AdminMemberName,
          emailScheduleDate: reminderDate,
          module: EmailType.PaymentReminder,
          variableData: paymentReminderVariableData,
          needsTrigger: false,
          companyId: this.companyService.activeCompany?.id!,
          templateId: EmailTriggerTemplateId.PaymentReminder,
          attachmentInBase64: '',
        };

        this.emailService.createEmailLogForPBCAndAdmin(paymentReminderData);

        if (days === 2) {
          this.emailService.saveEmailLog(true);
        }
      }
    }
  }

  paymentReceiptEmailLogCreation(
    PBCEmail: string,
    PBCMemberName: string,
    AdminEmail: string,
    AdminMemberName: string,
    boardName: string,
    memberShip: MembershipDto,
    transactionData: any,
    companyId?: string,
    membershipEndDate?: any,
    isAdditional?: boolean,
    isMultipleInvoice?: []
  ) {
    let receiptVariableData: { [key: string]: string } = {
      NACD: 'National Association of Corporate Directors',
      boardName: boardName,
      fullAmount: transactionData.totalAmount?.toString(),
      date: this.datePipe.transform(new Date(), 'MM/dd/YYYY')!,
      currentYear: new Date().getFullYear()?.toString(),
      contactURL: 'https://www.nacdonline.org/about/contact-us/',
    };

    const memberShipTemp = {
      PBCEmail: PBCEmail,
      PBCMemberName: PBCMemberName,
      AdminEmail: AdminEmail,
      AdminMemberName: AdminMemberName,
      memberShip: memberShip,
      companyId: companyId,
      membershipEndDate: membershipEndDate,
      isAdditional: isAdditional,
    };
    if (isMultipleInvoice?.length) {
      this.receiptData = [];
      isMultipleInvoice?.forEach((mi: any, index: number) => {
        if (mi.invoice) {
          const paymentTemp = mi.invoice.payment;

          this.fetchReceiptCreateEmailLog(
            {
              transId: paymentTemp.transactionId,
              accountType: paymentTemp.method,
              totalAmount: mi.invoice?.netAmount
                ? this.currencyPipe.transform(mi.invoice.netAmount)
                : mi.invoice.netAmount,
              dateTime: paymentTemp.date,
            },
            receiptVariableData,
            memberShipTemp,
            true,
            isMultipleInvoice,
            index
          );
        }
      });
    } else {
      this.fetchReceiptCreateEmailLog(
        transactionData,
        receiptVariableData,
        memberShipTemp
      );
    }
  }

  fetchReceiptCreateEmailLog(
    transactionData: any,
    receiptVariableData: any,
    memberShipTemp: any,
    isMultipleBase64?: any,
    isMultipleInvoice?: any,
    index?: number
  ) {
    this.htmlRenderer
      .fetchReceiptHTMLElement(
        transactionData.transId,
        memberShipTemp.memberShip?.term,
        transactionData.accountType,
        transactionData.totalAmount,
        transactionData.dateTime,
        true,
        memberShipTemp.membershipEndDate
      )
      .subscribe({
        next: (receiptBase64: any) => {
          const attachment = {} as attachment;
          const invoiceNo = this.paymentSvc.invoiceNo.split(', ');
          (attachment.Base64 = receiptBase64),
            (attachment.FileName = `PaymentReceipt_${
              index! >= 0 ? invoiceNo[index!] : invoiceNo
            }.pdf`);
          this.receiptData.push(attachment);
          if (
            !isMultipleBase64 ||
            this.receiptData.length === isMultipleInvoice.length
          ) {
            const receiptData = {
              PBCEmail: memberShipTemp.PBCEmail,
              PBCMemberName: memberShipTemp.PBCMemberName,
              AdminEmail: memberShipTemp.AdminEmail,
              AdminMemberName: memberShipTemp.AdminMemberName,
              emailScheduleDate: new Date(),
              module: EmailType.PaymentReceipt,
              variableData: receiptVariableData,
              needsTrigger: true,
              companyId:
                this.companyService.activeCompany?.id! ||
                memberShipTemp.companyId!,
              templateId: EmailTriggerTemplateId.PaymentReceipt,
              attachmentInBase64: this.receiptData?.length
                ? this.receiptData
                : receiptBase64,
              fileName: EmailTriggerTemplateId.PaymentReceipt,
            };

            this.emailService.createEmailLogForPBCAndAdmin(receiptData);
            if (
              memberShipTemp.isAdditional ||
              this.salesService.isPayLaterAdditionalInvoice ||
              this.enableRenewalInvoice[0].value !== AppConstants.TRUE
            ) {
              console.log(this.emailService.emailLogs, 'additional');
              this.emailService.saveEmailLog();
            }
          }
        },
        error: (error) => {
          console.log('fetchReceiptHTMLElement Error: ', error);
        },
      });
  }

  receptEmailLog(
    PBCEmail: string,
    PBCMemberName: string,
    AdminEmail: string,
    AdminMemberName: string,
    boardName: string,
    memberShip: MembershipDto,
    transactionData: any,
    companyId?: string,
    membershipEndDate?: any,
    isAdditional?: boolean,
    contant?: any,
    base64?: any,
    type?: any,
    toasterMessage?: string
  ) {
    let receiptVariableData: { [key: string]: string } = {
      NACD: 'National Association of Corporate Directors',
      boardName: boardName,
      fullAmount: transactionData.totalAmount?.toString(),
      date: this.datePipe.transform(new Date(), 'MM/dd/YYYY')!,
      currentYear: new Date().getFullYear()?.toString(),
      contactURL: 'https://www.nacdonline.org/about/contact-us/',
    };

    const pdfMargin = 0;
    const bottomMargin = 0;

    this.htmlRenderer
      .downloadReciptAsPDF(contant, type, base64, pdfMargin, bottomMargin)
      .subscribe({
        next: (receiptBase64: any) => {
          const paymentReceiptData = {
            PBCEmail: PBCEmail,
            PBCMemberName: PBCMemberName,
            AdminEmail: AdminEmail,
            AdminMemberName: AdminMemberName,
            emailScheduleDate: new Date(),
            module: EmailType.PaymentReceipt,
            variableData: receiptVariableData,
            needsTrigger: true,
            companyId: this.companyService.activeCompany?.id! || companyId!,
            templateId: EmailTriggerTemplateId.PaymentReceipt,
            attachmentInBase64: receiptBase64,
            fileName: 'PaymentReceipt.pdf',
          };

          this.emailService.createEmailLogForPBCAndAdmin(paymentReceiptData);

          console.log(this.emailService.emailLogs, 'additional');
          this.emailService.saveEmailLog(false, false, toasterMessage);
        },
        error: (error) => {
          console.log('fetchReceiptHTMLElement Error: ', error);
        },
      });
  }

  renewalInvoiceEmailLogCreation(
    PBCEmail: string,
    PBCMemberName: string,
    AdminEmail: string,
    AdminMemberName: string,
    memberShip: MembershipDto,
    transactionData: any,
    companyId?: string,
    isGeneric?: boolean,
    toasterMessage?: string
  ) {
    const daysBeforeExpiry = isGeneric ? [0] : [90, 60, 31, 7, -7];

    let membershipTermEndDate = memberShip.endDate;
    if (this.membershipService.activeMembership?.boardAdvisor?.emailId) {
      this.emailService.boardAdvisorSender = {} as EmailAddress;
      this.emailService.boardAdvisorSender.Email =
        this.membershipService.activeMembership.boardAdvisor.emailId;
      this.emailService.boardAdvisorSender.Name =
        this.membershipService.activeMembership.boardAdvisor.name;
    }
    if (membershipTermEndDate) {
      let termEndDate = new Date(membershipTermEndDate);
      let invoiceVariableData: { [key: string]: string } = {};
      if (isGeneric) {
        if (memberShip.startDate) {
          termEndDate = new Date(memberShip.startDate);
          termEndDate.setDate(termEndDate.getDate() + 60);
        }
      }
      const currentTermEndDate = this.datePipe.transform(
        termEndDate,
        'MM/dd/yyyy'
      );
      if (currentTermEndDate) {
        invoiceVariableData['currentTermEndDate'] = currentTermEndDate;
      }

      const termEndDateTemp = new Date(memberShip.endDate as any);
      const renewalDiscountDateTemp = termEndDateTemp.setDate(
        termEndDateTemp.getDate() - 30
      );
      const renewalDiscountDate = this.datePipe.transform(
        renewalDiscountDateTemp,
        'MM/dd/yyyy'
      );
      if (renewalDiscountDate) {
        invoiceVariableData['renewalDiscountDate'] = renewalDiscountDate;
      }

      invoiceVariableData['invoiceNo'] = this.paymentSvc.invoiceNo;
      invoiceVariableData['currentYear'] = new Date().getFullYear()?.toString();
      invoiceVariableData['contactURL'] =
        'https://www.nacdonline.org/about/contact-us/';
      invoiceVariableData['fullAmount'] =
        transactionData.totalAmount?.toString();
      invoiceVariableData['boardName'] =
        this.companyService.activeCompany?.name!;
      let fileName = 'PaymentReceipt.pdf';
      let daysCount = 0;
      for (const days of daysBeforeExpiry) {
        const reminderDate = new Date(new Date(memberShip.endDate as any));
        reminderDate.setHours(new Date().getHours());
        reminderDate.setMinutes(new Date().getMinutes());
        const templateId = this.getTemplateId(days);
        reminderDate.setDate(termEndDate.getDate() - days);
        console.log(
          this.datePipe.transform(reminderDate, 'MM/dd/yyyy'),
          'renewalDate',
          renewalDiscountDate,
          memberShip.endDate
        );
        this.htmlRenderer
          .fetchInvoiceHTMLElement(
            transactionData.totalAmount,
            memberShip.term,
            this.datePipe.transform(
              isGeneric ? transactionData.date : reminderDate,
              'MM/dd/yyyy'
            ),
            true,
            this.paymentSvc.invoiceNo,
            false,
            transactionData.paymentDueDate
          )
          .subscribe({
            next: (invoiceBase64: any) => {
              const invoiceData = {
                PBCEmail: PBCEmail,
                PBCMemberName: PBCMemberName,
                AdminEmail: AdminEmail,
                AdminMemberName: AdminMemberName,
                emailScheduleDate: isGeneric ? new Date() : reminderDate,
                module: isGeneric
                  ? EmailType.Invoice
                  : EmailType.RenewalInvoice,
                variableData: invoiceVariableData,
                needsTrigger: isGeneric ? true : false,
                companyId: this.companyService.activeCompany?.id! || companyId!,
                templateId: templateId!,
                attachmentInBase64: invoiceBase64,
                fileName: 'Invoice.pdf',
              };

              this.emailService.createEmailLogForPBCAndAdmin(invoiceData);
              daysCount++;

              if (daysCount === daysBeforeExpiry.length) {
                this.emailService.saveEmailLog(
                  false,
                  isGeneric,
                  toasterMessage,
                  this.deleteReminderAfterPayNow ? true : false
                );
              }
            },
            error: (error) => {
              console.log('fetchInvoiceHTMLElement Error: ', error);
            },
          });
      }
    }
  }

  getTemplateId(days: number) {
    if (days === 90) {
      return EmailTriggerTemplateId.RenewalInvoice90Days;
    } else if (days === 60) {
      return EmailTriggerTemplateId.RenewalInvoice60Days;
    } else if (days === 31) {
      return EmailTriggerTemplateId.RenewalInvoice31Days;
    } else if (days === 7) {
      return EmailTriggerTemplateId.RenewalInvoice7Days;
    } else if (days === 0) {
      return EmailTriggerTemplateId.GenericInvoice;
    } else {
      return EmailTriggerTemplateId.RenewalInvoiceAfter7Days;
    }
  }

  appendRecipients(emailLogRecipients: any) {
    var recipients = '';
    emailLogRecipients.forEach((ele: any) => {
      recipients += ',';
      recipients += ele.value;
    });
    return recipients;
  }

  public getAppSetting(appSettingsData: any, key: string): Setting[] {
    return appSettingsData.filter((da: any) => da.key === key);
  }

  getRecipientsForCorporateJoins(appSettings: any) {
    var recipientList = '';
    var emailLogMETRecipients = this.getAppSetting(
      appSettings,
      AppConstants.APPSETTING_EMAILLOG_MET_RECIPIENTS
    );
    var emailLogBDTRecipients = this.getAppSetting(
      appSettings,
      AppConstants.APPSETTING_EMAILLOG_BDT_RECIPIENTS
    );
    var emailLogAccountingRecipients = this.getAppSetting(
      appSettings,
      AppConstants.APPSETTING_EMAILLOG_ACCOUNTING_RECIPIENTS
    );

    emailLogMETRecipients.forEach((ele: any) => {
      recipientList += ele.value;
    });
    recipientList += this.appendRecipients(emailLogBDTRecipients);
    recipientList += this.appendRecipients(emailLogAccountingRecipients);

    return recipientList;
  }

  notificationOnCorporateJoinsEmailLogCreation(
    boardName: string,
    paymentChoice: string,
    appSettings: any
  ) {
    let corporateJoinVariableData: { [key: string]: string } = {
      boardName: boardName,
      paymentChoice: paymentChoice,
      loginURL: environment.superadmin_url,
      currentYear: new Date().getFullYear()?.toString(),
    };
    var recipients = this.getRecipientsForCorporateJoins(appSettings);

    const logData: EmailLogDetail = {
      PBCEmail: recipients,
      emailScheduleDate: new Date(),
      module: EmailType.CorporateJoins,
      variableData: corporateJoinVariableData,
      needsTrigger: false,
      companyId: this.companyService.activeCompany?.id!,
      templateId: EmailTriggerTemplateId.NotificationOnCorporateJoins,
      attachmentInBase64: '',
      fileName: '',
    };

    this.emailService.createEmailLog(logData, recipients);
  }

  emailLogNewBpc() {
    const allProfiles = this.registrationDataService.getMemberRosterProfiles();
    const ActiveMemAdvisor = this.membershipService.activeMembership;
    const companyData = this.companyService.activeCompany;
    const primaryContactData = UtilityService.getPrimaryContact(
      allProfiles,
      companyData.id!
    );
    const adminProfile = UtilityService.getAdminstrator(
      allProfiles,
      companyData.id!
    );
    let receiverName =
      primaryContactData.firstName + ' ' + primaryContactData.lastName;
    let toEmail = primaryContactData.eMail;
    let pbcAndAdminProfile = adminProfile ? adminProfile : primaryContactData;
    let newBpcVariableData: { [key: string]: string } = {
      recipientName: receiverName,
      boardName: companyData.name,
      pbcName: primaryContactData.firstName + ' ' + primaryContactData.lastName,
      pbcEmail: primaryContactData.eMail,
      adminName:
        pbcAndAdminProfile.firstName + ' ' + pbcAndAdminProfile.lastName,
      adminEmail: pbcAndAdminProfile.eMail,
      contactURL: 'https://www.nacdonline.org/about/contact-us/',
      currentYear: new Date().getFullYear()?.toString(),
    };
    this.emailService.sender.Email =
      environment.mail_service_sender_advisorEmail;
    const companyId = companyData.id ?? '';
    const newPBCEmailLog: EmailLog = {
      id: this.emailService.id,
      module: ModuleType.NEWPBCASSIGN,
      sender: this.emailService.sender,
      recipients: toEmail,
      scheduleDateTime: new Date(),
      variableData: newBpcVariableData,
      statusCode: EmailStatus.InProgress,
      statusMessage: 'In Progress',
      companyId: companyId,
      templateId: EmailTriggerTemplateId.NewPBCEmailNotification,
      attachments: [],
    };
    this.newPBCEmailLogs.push(JSON.parse(JSON.stringify(newPBCEmailLog)));
    newBpcVariableData['recipientName'] =
      pbcAndAdminProfile.firstName + ' ' + pbcAndAdminProfile.lastName;
    newPBCEmailLog.recipients = pbcAndAdminProfile.eMail;
    this.newPBCEmailLogs.push(JSON.parse(JSON.stringify(newPBCEmailLog)));
    if (ActiveMemAdvisor?.boardAdvisor) {
      newBpcVariableData['recipientName'] = ActiveMemAdvisor?.boardAdvisor.name;
      newPBCEmailLog.recipients = ActiveMemAdvisor?.boardAdvisor.emailId;
      this.newPBCEmailLogs.push(JSON.parse(JSON.stringify(newPBCEmailLog)));
    }
    this.emailService.postEmailLog(this.newPBCEmailLogs, false).subscribe({
      next: (response) => {
        this.newPBCEmailLogs = [];
      },
      error: (err) => {
        console.log('post email response error', err);
      },
    });
  }
}
