<div class="form-input" [class.safari_only]="isSafariBrowser" [ngClass]="fieldType">
  <div class="form-label-hint" *ngIf="label || hint">
    <mat-label class="form-label" *ngIf="label">
      {{ label }}<span *ngIf="requireMark" class="require-mark">*</span>
    </mat-label>
    <mat-hint class="form-hint" *ngIf="hint" [title]="hint">{{ hint }}</mat-hint>
  </div>
  <mat-form-field appearance="outline" class="form-field-wrapper">
    <input *ngIf="!isSearchInput" matInput [formControl]="control" [placeholder]="placeholder" />
    <input *ngIf="isSearchInput" matInput name="searchInput" [formControl]="control" [placeholder]="placeholder" />
    <mat-icon class="form-element-icon" matSuffix *ngIf="!showClear">{{
      icon
      }}</mat-icon>
    <mat-icon class="form-element-icon" matSuffix (click)="onClear($event)" *ngIf="showClear">{{ icon }}</mat-icon>
  </mat-form-field>
  <div class="form-error" *ngIf="control.invalid && (control.dirty || control.touched)">
    <div *ngFor="let err of control.errors | keyvalue">
      {{ errorMsgs[err.key] }}
    </div>
  </div>
</div>