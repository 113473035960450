<div class="decrease-increase-box">
  <button
    [disabled]="disableDecreaseButton"
    (click)="decrease()"
    class="decrease-increase-box-button"
  >
    -
  </button>
  <input
    [value]="value"
    (input)="updateValue($event)"
    type="number"
    class="decrease-increase-box-input"
    (blur)="onBlur($event); onTouched()"
  />
  <button
    [disabled]="disableIncreaseButton"
    (click)="increase()"
    class="decrease-increase-box-button"
  >
    +
  </button>
</div>
