import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminPaymentConfirmationService {
  isRedirectToCompanyAccount = false;
  close = false;

  constructor(private router: Router) { }

  redirectToCompanyAccount() {
    if (this.isRedirectToCompanyAccount) {
      this.router.navigate(['admin/edit-corporate-boards']);
    }
  }
}
