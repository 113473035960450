<div class="denotes-info paddding-0-20" ><span class="astrik">*</span> denotes a required field</div>
<!-- form section starts -->
<div class="form-section">
    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-select label="Prefix" [items]="prfixSelect" [control]="formGroup.controls.prefix"
                [errorMsgs]="requireError"></app-select>
        </div>
        <div class="form-row-cell">
            <app-input label="First Name" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Middle Initial" [control]="formGroup.controls.companyName"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Last Name" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Suffix" [control]="formGroup.controls.companyName"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Phone" hint="(Please include country code)" [control]="formGroup.controls.companyName"
                [errorMsgs]="companyFieldError" [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell form-row-cell-50">
            <app-input label="Email" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell direction-row">
            <app-checkbox [requireMark]="true" [control]="formGroup.controls.isPrimary"
                checkBoxLabel="Address Autocomplete for US" (emitCheck)="getCheck()"></app-checkbox>
            <app-checkbox [requireMark]="true" [control]="formGroup.controls.isPrimary"
                checkBoxLabel="Address same as Company" (emitCheck)="getCheck()"></app-checkbox>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Address Line 1" [control]="formGroup.controls.companyName"
                [errorMsgs]="companyFieldError" [requireMark]="true"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Address Line 2" [control]="formGroup.controls.companyName"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="City" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="State" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Country" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Zip Code" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
    </div>
    <!-- form row ends -->

</div>
<!-- form section ends -->
