import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as signalR from '@microsoft/signalr';
import { BaseService } from "./base.service";

@Injectable()
export class SignalrService extends BaseService {

    connection: any = null;
    pullDataStatusForAccount: BehaviorSubject<any> = new BehaviorSubject<any>({});
    pullDataStatusForContact: BehaviorSubject<string> = new BehaviorSubject<any>({});

    public initMembershipSignalrConnection() {
        this.establishConnection(this.signalrUrl);
    }

    public initProfileAPISignalrConnection() {
        this.establishConnection(this.profileSignalrUrl)
    }

    private establishConnection(url: string) {
        this.connection = new signalR.HubConnectionBuilder().withUrl(url,
            {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            }).build();

        this.connection.on('updateAccountPullDataStatus', (data: any) => {
            this.pullDataStatusForAccount.next(data);
        });

        this.connection.on('updateContactPullDataStatus', (data: any) => {
            this.pullDataStatusForContact.next(data);
        });

        this.connection
            .start()
            .then(() => {
                console.log(`SignalR connection success! connectionId: ${this.connection.connectionId}`);
            })
            .catch((error: any) => {
                console.log(`SignalR connection error: ${error}`);
            });
    }
}