import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ModalDataService } from '../../services/modal-data.service';
import { CommonPopupComponent } from 'src/app/components/common-popup/common-popup.component';
import { ModalWrapperComponent } from '../modal-wrapper/modal-wrapper.component';
import { AdminPaymentConfirmationService } from '../../services/admin-payment-confirmation.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('receiptContainer', { static: false })
  receiptContainer!: ElementRef;

  inputFormControl = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modal: MatDialog,
    private ref: MatDialogRef<ModalComponent>,
    private builder: FormBuilder,
    private modalDataService: ModalDataService,
    private adminPaymentSvc: AdminPaymentConfirmationService
  ) {}

  modalData: any;
  modalDirectiveCloseData: any = 'this is directive close message';

  submitClose() {
    this.ref.close('this is data, after closing the modal!');
  }

  myForm = this.builder.group({
    name: this.builder.control(''),
    email: this.builder.control(''),
  });

  saveData() {
    this.modalDataService.saveButtonClick.emit();
    this.ref.close(this.dataToSubmit());
  }

  modalButtonEventOne() {
    console.log('click modal button one');
  }
  modalButtonEventTwo() {
    console.log('click modal button Two');
  }

  downloadButton() {
    this.modalDataService.downloadButtonClick.emit();
    this.ref.close(this.dataToSubmit());
  }

  emailButton() {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: CommonPopupComponent,
        buttonOneLabel: 'Cancel',
        buttonTwoLabel: 'Send',
        text: `Send ${this.modalData.title} to Primary Board Contact and Administrator email`,
        showCloseIcon: true,
      },
      panelClass: ['app-dialog', 'account-edit-modal'],
      disableClose: true,
    };
    const modalRef = this.modal.open(CommonPopupComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (item) {
        this.modalDataService.emailButtonClick.emit();
      }
    });
  }

  ngOnInit(): void {
    this.modalData = this.data;
    if (this.modalData.bodyComponent === 'input-box-validation') {
      this.inputFormControl.setValidators([Validators.required]);
    }
  }

  ngAfterViewInit(): void {
    if (this.modalData.bodyComponent == 'account-order-history-receipt') {
      this.receiptContainer.nativeElement.innerHTML = this.modalData.innerHTML;
    }
  }

  dataToSubmit(): any {
    if (
      this.modalData.bodyComponent === 'count-box' ||
      this.modalData.bodyComponent === 'input-box'
    ) {
      return this.modalData;
    }

    if (this.modalData.bodyComponent === 'input-box-validation') {
      return this.inputFormControl;
    }
    return this.myForm.value;
  }
  close() {
    if (this.adminPaymentSvc.close) {
      this.ref.close('close');
    } else {
      this.ref.close();
    }
  }
}
