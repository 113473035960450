import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from './appconfig.service';
import { Company } from '../models/company';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  baseURL: string;
  observePayNowBanner = new BehaviorSubject<any>(false);
  observeBannerStatus = new BehaviorSubject<any>(false);
  observeOpenSalesPayNow = new BehaviorSubject<any>(false);
  observeClosePopUp = new BehaviorSubject<any>(false);
  constructor(private _httpClient: HttpClient) {
    this.baseURL = AppConfig.configSettings.env.baseAPIUrl;
  }

  getBannerStatus(): Observable<any> {
    return this.observeBannerStatus.asObservable();
  }

  setBannerStatus(isShowBanner = false) {
    this.observeBannerStatus.next(isShowBanner);
  }

  getbyCompanyIdInvoice(id: any): Observable<any> {
   return this._httpClient.get<any>(
      `${this.baseURL}/Invoice/Transaction/${id}`
    );
  }

  setToggle(data=false){
    this.observePayNowBanner.next(data);
  }
  getToggle(){
    return this.observePayNowBanner.asObservable();
  }

  setPayNowBanner(isShowBanner = false){
    this.observeOpenSalesPayNow.next(isShowBanner);
  }

  getPayNowBanner(): Observable<any> {
    return this.observeOpenSalesPayNow.asObservable();
  }

  getClosePopUpInAdmin():Observable<any>{
    return this.observeClosePopUp.asObservable();
  }

  setClosePopUpInAdmin(isclose=false){
    this.observeClosePopUp.next(isclose)
  }
}
