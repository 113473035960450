<div class="gate-con">
    <h6>Gate Con Preferences</h6>
    <p>{{gateConText.value}}</p>
        <app-checkbox [inputData]="preferNoContact" 
            [control]="formGroup.controls.isContact" 
            checkBoxLabel="Do Not Contact"
            [(inputData)]="membership.preferNoContact"
            (emitCheck)="getCheck()">
        </app-checkbox>
    <app-button buttonLabel="Save" buttonClass="primary-button" (onClick)="saveGateCon()"></app-button>
</div>