<div class="modal-container">
  <div class="modal-header">
    <div *ngIf="data.title" mat-dialog-title>{{ data.title }}</div>
    <mat-icon [mat-dialog-close] *ngIf="showCloseIcon">close</mat-icon>
  </div>
  <div mat-dialog-content class="confirmation-company-content">
    <div class="modal-company-confirmation">
      {{ message }}
    </div>

    <div class="modal-footer">
      <app-button
        buttonClass="secondary-button"
        *ngIf="cancelButtonVisible"
        buttonLabel="NO"
        [mat-dialog-close]
      ></app-button>
      <app-button
        buttonClass="primary-button"
        buttonLabel="{{ okButtonText }}"
        (onClick)="closeDialog()"
      ></app-button>
    </div>
  </div>
</div>
