<div class="form-date-range">
  <div class="form-label-hint" *ngIf="label">
    <mat-label class="form-label"
      >{{ label
      }}<span *ngIf="requireMark" class="require-mark">*</span></mat-label
    >
    <mat-hint class="form-hint">{{ hint }}</mat-hint>
  </div>
  <mat-form-field>
    <mat-date-range-input [rangePicker]="picker" [min]="disabledStartDate">
      <input
        matStartDate
        placeholder="Start date"
        [formControl]="control"
        (dateChange)="onDateChange($event)"
      />
      <input
        matEndDate
        placeholder="End date"
        [formControl]="control2"
        (dateChange)="onDateChanges($event)"
      />
    </mat-date-range-input>

    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <div class="form-error" *ngIf="control.invalid && control.touched">
    <div *ngFor="let err of control.errors | keyvalue">
      {{ errorMsgs[err.key] }}
    </div>
  </div>
  <div class="form-error" *ngIf="!control.invalid && control2.invalid && control2.touched">
    <div *ngFor="let err of control2.errors | keyvalue">
      {{ errorMsgs[err.key] }}
    </div>
  </div>
</div>
