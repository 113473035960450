import { Component } from "@angular/core";
import { Setting } from 'src/app/shared/models/setting';
import { SettingService } from 'src/app/shared/services/setting.service';
import { AppConstants, TOKEN } from 'src/app/shared/constants/app-constants';


@Component({
    selector: 'app-member-roster-alert',
    templateUrl: './member-roster-alert.component.html',
    styleUrls: ['./member-roster-alert.component.scss']
  })
export class MemberRosterAlertComponent {

  data: any;
  text: any;
  commonSettings: any;

  constructor(private settingService: SettingService) {

  }

  ngOnInit(): void {
    this.data=this.settingService.getAppSettingsData()
    console.log(this.data);
    const interval = setInterval(() => {
      if (this.data) {
        this.text=this.getSysSetting(AppConstants.APPSETTING_MEMBER_ROSTER)
        this.text.forEach((ele:any) => {
          console.log(ele) 
          this.text=ele         
        });
      }
      console.log(this.text);
      clearInterval(interval);
    },30)
  }

  public getSysSetting(key: string): Setting[] {
    this.commonSettings = this.data.filter((da:any) => da.key === key);
    return this.commonSettings;
  }
}

