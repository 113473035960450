import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const modules = [
  MatIconModule,
  MatSelectModule,
  MatTableModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule
];

@NgModule({
  imports: [modules],
  exports: [modules],
})
export class AppMaterialModule {}
