<footer class="app-footer">
    <div class="app-footer-content content">
        <div class="app-footer-address">
            <div>National Association of Corporate Directors</div>
            <div>1515 N. Courthouse Rd., Suite 1200, Arlington, VA 22201</div>
            <div>Phone: 571-367-3700</div>
        </div>
        <div class="app-footer-rights-terms">
            <div class="app-footer-copy-rights">© 2023 National Association of Corporate Directors. All rights reserved.</div>
            <div class="app-footer-terms">
                <a href="https://www.nacdonline.org/about/privacy-policy/" target="_blank">Privacy Policy</a>
                <a href="https://www.nacdonline.org/about/terms-conditions/" target="_blank">Terms of Use</a>
                <a href="https://www.nacdonline.org/about/cookie-preferences/" target="_blank">Cookie Preferences</a>
            </div>
        </div>
    </div>
</footer>