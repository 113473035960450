import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';


@Component({
  selector: 'app-super-admin-edit-membership',
  templateUrl: './super-admin-edit-membership.component.html',
  styleUrls: ['./super-admin-edit-membership.component.scss']
})
export class SuperAdminEditMembershipComponent {

  public addButtonName = 'Update';
  public cancelButtonName = 'cancel';
  private membershipData: any;
  public validationMessages = {
    endDate: {
      required: "Membership End Date is required"
    }
  }
  public enableSave: boolean = false;
  showPastDateAlert = false;

  constructor(
    private modalService: ModalDataService,
    private membershipService: MembershipService
  ) { }

  ngOnInit(): void {
    this.modalService.showLoader();
    this.membershipData = this.membershipService.activeMembership;
    this.membershipForm.patchValue({
      'endDate': this.membershipData?.endDate ?? '',
    });
    this.modalService.hideLoader();
  }

  public membershipForm = new FormGroup({
    endDate: new FormControl('', [Validators.required]),
  });

  public saveMembership(): void {
    this.modalService.showLoader();
    var endDate = this.membershipForm.getRawValue().endDate;
    this.membershipData.endDate = new Date(endDate + 'Z')
    this.membershipService.updateMembership(this.membershipData).subscribe((res) => {
      this.membershipService.setActiveMembership(this.membershipData);
      this.modalService.hideLoader();
      this.modalService.closeModal({ close: true });
    });
  }
  public dateChanged(event: any): void {
    var date: any = this.membershipForm.getRawValue().endDate;
    this.enableSave = (date >= new Date(this.membershipData?.endDate));
    this.showPastDateAlert = !this.enableSave;

    if (this.showPastDateAlert) {
      this.addButtonName = 'Confirm';
    }
  }

  cancel() {
    this.showPastDateAlert = false;
    this.addButtonName = 'Update';
  }
}
