import { HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends BaseService {
  getAPIToken(isAzureFunctionToken = false): Observable<any> {
    const payload = new URLSearchParams();
    payload.set(
      'grant_type',
      isAzureFunctionToken
        ? environment.azure_grant_type
        : environment.ids_api_grant_type
    );
    payload.set(
      'client_id',
      isAzureFunctionToken
        ? environment.azure_function_client_id
        : environment.ids_api_client_id
    );
    payload.set(
      'client_secret',
      isAzureFunctionToken
        ? environment.azure_secret_id
        : environment.ids_api_client_secret
    );
    payload.set(
      'scope',
      isAzureFunctionToken
        ? environment.azure_function_scope
        : environment.ids_api_scope
    );
    return this._http.post(`${this.idServerUrl}/connect/token`, payload, {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    });
  }
}
