import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { menu } from 'src/app/shared/models/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  headerMenu: menu[] = []

  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
  @ViewChild('menu') menu: ElementRef | undefined;

  // configuration$!: Observable<OpenIdConfiguration>;
  // userDataChanged$!: Observable<OidcClientNotification<any>>;
  // userData$!: Observable<UserDataResult>;
  isAuthenticated = false;
  loggedInUser: string = '';

  ngOnInit() {

    fetch('../../../assets/config/config.menu.json').then(res => res.json())
    .then(res =>  this.headerMenu=res);
    
    // this.configuration$ = this.oidcSecurityService.getConfiguration();
    // this.userData$ = this.oidcSecurityService.userData$;

    // this.oidcSecurityService.isAuthenticated$.subscribe(
    //   ({ isAuthenticated }) => {
    //     this.isAuthenticated = isAuthenticated;

    //     console.warn('authenticated: ', isAuthenticated);
    //   }
    // );

    this.authService.user$.subscribe(data => {
      if(data) {
        this.isAuthenticated = true;
        this.loggedInUser = data.family_name ? data.given_name + ' ' + data.family_name : data.given_name;
      }
    })
  }


  constructor(private renderer: Renderer2, private authService: AuthService,
    private oidcSecurityService: OidcSecurityService,
    private msalAuthService: MsalService) {
    this.renderer.listen('window', 'click',(e:Event)=>{
        if(e.target !== this.toggleButton?.nativeElement && e.target !== this.toggleButton?.nativeElement.querySelector('mat-icon') && e.target !== this.toggleButton?.nativeElement.querySelector('span') && e.target!==this.menu?.nativeElement){
            this.mobileMenu=false;
        }
    });
  }

  mobileMenu:boolean = false;
  mobileMenuClick() {
    this.mobileMenu = !this.mobileMenu;
  }

  logout() {
    if(localStorage.getItem('adminLink')) {
      this.msalAuthService.logoutRedirect({postLogoutRedirectUri:environment.azure_ad_redirect_url});
    } else { 
      this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
    }
    this.authService.logoff();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

}
