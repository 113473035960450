import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-button',
  imports: [CommonModule, MatIconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true
})
export class ButtonComponent {
  // add other optional attributes like Id, name, type in case of form submit
  @Input() buttonLabel: any | undefined;
  @Input() buttonClass: any | undefined;
  @Input() disable: boolean | undefined;
  @Input() btnType: string = 'button';
  @Input() iconName: any | undefined;
  @Output() onClick = new EventEmitter<string>;
  emitButtonEvent() {
    this.onClick.emit();
  }
}
