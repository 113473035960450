import { Injectable } from "@angular/core";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { BehaviorSubject } from "rxjs";


@Injectable()
export class StepperService {
    private nextStepData = new BehaviorSubject<any>(null);
    nextStep$ = this.nextStepData.asObservable();

    goToNextStep(step: MatStep, matStepper: MatStepper) {
        step.completed = true;
        step.editable = true;
        matStepper.next();
    }

    goToPreviousStep(step: MatStep, matStepper: MatStepper) {
        step.editable = true;
        step.completed = false;
        const prevStep = matStepper.steps.get(matStepper.selectedIndex - 1);
        if (prevStep) {
            prevStep.completed = false;
            prevStep.editable = true;
            setTimeout(() => {
                matStepper.selectedIndex = matStepper.selectedIndex - 1;
            }, 10);
        }
    }

    updateRole(data: any) {
        this.nextStepData.next(data);
    }
}