<div class="modal-header">
  <div *ngIf="data.title" mat-dialog-title>{{data.title}}</div>
  <mat-icon [mat-dialog-close] *ngIf="showCloseIcon">close</mat-icon>
</div>
<div class="modal-container">
  <div class="dialog-text">{{ data?.text }}</div>
  <div mat-dialog-actions class="modal-footer">
    <app-button buttonClass="secondary-button" [buttonLabel]="Cancel" (click)="cancel()"></app-button>
    <app-button buttonClass="primary-button" [buttonLabel]="Send" (onClick)="saveData()"></app-button>
  </div>
</div>