export class PaymentConfiguration {

  static paymentReadyToPerform = false;

  static paymentApiLoginId: string = '';
  static paymentTransactionId: string = '';
  static paymentCustomerProfileId: string = '';
  static paymentFormUrl: string = '';
  static paymentRequestUrl: string = '';
  
  static amount: string = '0';

  currentDomainWithProtocol =
    window.location.protocol + '//' + window.location.hostname;

  static customerEmail = '';

  static billToDetails = {
    firstName: '',
    lastName: '',
    company: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
  };

  static currentDomainWithProtocol =
    window.location.protocol + '//' + window.location.hostname;

  static hostedPaymentSettings = [
    {
      settingName: 'hostedPaymentReturnOptions',
      settingValue: `{"showReceipt": false, "url": "${this.currentDomainWithProtocol}", 
        "urlText": "Continue", "cancelUrl": "${this.currentDomainWithProtocol}", 
        "cancelUrlText": "Cancel"}`,
    },
    {
      settingName: 'hostedPaymentButtonOptions',
      settingValue: '{"text": "Pay"}',
    },
    {
      settingName: 'hostedPaymentStyleOptions',
      settingValue: '{"bgColor": "blue"}',
    },
    {
      settingName: 'hostedPaymentPaymentOptions',
      settingValue:
        '{"cardCodeRequired": true, "showCreditCard": true, "showBankAccount": true}',
    },
    {
      settingName: 'hostedPaymentSecurityOptions',
      settingValue: '{"captcha": true}',
    },
    {
      settingName: 'hostedPaymentShippingAddressOptions',
      settingValue: '{"show": false, "required": false}',
    },
    {
      settingName: 'hostedPaymentBillingAddressOptions',
      settingValue: '{"show": true, "required": false}',
    },
    {
      settingName: 'hostedPaymentCustomerOptions',
      settingValue:
        '{"showEmail": true, "requiredEmail": true, "addPaymentProfile": true}',
    },
    {
      settingName: 'hostedPaymentOrderOptions',
      settingValue:
        '{"show": true, "merchantName": "NACD Corporate membership"}',
    },
    {
      settingName: 'hostedPaymentIFrameCommunicatorUrl',
      settingValue: JSON.stringify({
      url: this.currentDomainWithProtocol + '/assets/IFrameCommunicator.html',     
      }),
    },
  ];
}
