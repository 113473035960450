<div class="alert-modal">
    <div class="form-section">
       
            <div  [innerHTML]="text.value">
        </div>
    </div>
    <div class="modal-footer">
        <app-button buttonClass="secondary-button" buttonLabel="Back"
            [mat-dialog-close]></app-button>
    </div>
</div>