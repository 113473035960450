<div class="menu-header">
    <div class="menu-header-name">{{sideMenuName}}</div>
    <div class="menu-header-email" [title]="sideMenuEmail">{{sideMenuEmail}}</div>
</div>
<mat-accordion class="side-menu-accordion">
    <mat-expansion-panel *ngFor="let menu of sideMenu; let i = index; let isFirst = first"
        [expanded]="!!menu['isSubActive']" hideToggle #icon>
        <mat-expansion-panel-header
            [ngClass]="{'contain-submenu': menu.submenu, 'nav-active-link' : (!!rla.isActive || !!menu['isSubActive']) }"
            [routerLink]="menu.path" [routerLinkActiveOptions]="{exact: true}"
            [routerLinkActive]="(rla.isActive ? 'nav-active-link' : '')" #rla="routerLinkActive"
            (click)="goToResetPassword(menu.name)">
            <div class="menu-main">{{menu.name}}</div>
            <div class="menu-expand-icon" *ngIf="menu.submenu">
                <mat-icon *ngIf="!icon.expanded">add</mat-icon>
                <mat-icon *ngIf="icon.expanded">remove</mat-icon>
            </div>
        </mat-expansion-panel-header>
        <!-- <a>{{menu.path}}</a> -->
        <a [routerLink]="submenu.path" routerLinkActive="active-link" *ngFor="let submenu of menu.submenu"
            (isActiveChange)="submenu['isNavActive'] = $event; onSubRouterLinkActive(menu)">{{
            submenu.name }}</a>
    </mat-expansion-panel>
</mat-accordion>
<div class="side-menu-slogan">Empowering Directors.<br>Transforming Boards.</div>