<div class="member-role-form">
    <!-- Form section starts -->
    <form [formGroup]="roleInfoForm">
        <ng-container formArrayName="memberRoles">
            <div class="form-section">
                <!-- header -->
                <div class="form-row member-role-header">
                    <div class="form-row-cell">
                        <label>Role Type<span class="require">*</span></label>
                    </div>
                    <div class="form-row-cell">
                        <label>Role<span class="require">*</span></label>
                    </div>
                    <div class="form-row-cell">
                        <label>Start Year<span class="require">*</span></label>
                    </div>
                    <div class="form-row-cell">
                        <label>End Year<span class="require">*</span></label>
                    </div>
                </div>
                <!-- body -->
                <ng-container *ngFor="let memberRole of memberRoles.controls; index as i">
                    <div class="form-row member-role-box" [class.pointer-none]="isDeletedRole(memberRole?.value)"
                        *ngIf="!!isSuperAdmin || (!isSuperAdmin && !isDeletedRole(memberRole?.value))"
                        [title]="(isDeletedRole(memberRole?.value) ? 'Inactive Role' : 'Active Role')"
                        [formGroupName]="i">
                        <div class="form-row-cell">
                            <app-select [items]="companyRoleTypes" [control]="getControls(memberRole,'roleType')"
                                valueKey="code" labelKey="description" [errorMsgs]="validationMessages.roleTypes"
                                [requireMark]="true"></app-select>
                        </div>
                        <div class="form-row-cell">
                            <app-select [items]="getRoles(memberRole)" [control]="getControls(memberRole,'role')"
                                valueKey="code" labelKey="description" [errorMsgs]="validationMessages.role"
                                [requireMark]="true"></app-select>
                        </div>
                        <div class="form-row-cell">
                            <app-select [requireMark]="true" [items]="roleStartYears"
                                [control]="getControls(memberRole,'startYear')" labelKey="description" valueKey="id"
                                [errorMsgs]="validationMessages.startYear"></app-select>
                        </div>
                        <div class="form-row-cell">
                            <app-select [requireMark]="true" [items]="roleEndYears"
                                [control]="getControls(memberRole,'endYear')" labelKey="description" valueKey="id"
                                [errorMsgs]="validationMessages.endYear"></app-select>
                        </div>
                        <span class="remove-role" [class.disabled]="minRoleLengthInvalid()"
                            *ngIf="!isDeletedRole(memberRole?.value)">
                            <img src="../../../assets/images/delete-circle.svg" alt="delete" title="Remove member role"
                                (click)="deleteConfirmationPopup(memberRole,i)" />
                        </span>
                    </div>
                </ng-container>
                <!-- Add Role button -->
                <div class="form-row">
                    <div class="form-row-cell ml-10">
                        <app-button buttonClass="primary-button" [buttonLabel]="'Add Role'"
                            [disable]="!roleInfoForm.valid" (onClick)="addRole()"></app-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</div>