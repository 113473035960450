import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentConfiguration } from 'src/app/components/payment/payment-configuration';
import { OrderDetailInfo } from '../models/order-details';
import { PaymentDTO, SavePaymentDTO, TransactionData } from '../models/payment';
import { AppConfig } from './appconfig.service';
import { CompanyService } from './company.service';
import { MembershipService } from './membership.service';
import { GlobalDataProviderService } from './registration-data.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  baseURL!: string;

  transactionData!: TransactionData;
  invoiceNo!: string;
  isProcessed = false;
  salesOrd: any;
  multipleInvoice: any[] = [];

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private registrationDataService: GlobalDataProviderService,
    private membershipService: MembershipService
  ) {
    this.baseURL = AppConfig.configSettings.env.baseAPIUrl;
  }

  getFormToken(tokenRequestPayload: any): Observable<any> {
    const url = PaymentConfiguration.paymentRequestUrl;
    let _options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(url, tokenRequestPayload, _options);
  }

  saveResponse(savePaymentRequest: SavePaymentDTO[]): Observable<any> {
    const url = this.baseURL + `/Invoice`;

    return this.http.post<any>(url, savePaymentRequest);
  }

  setCustomerBillingDetails(
    customerDetails: CustomerDetails,
    billAmount: number
  ) {
    PaymentConfiguration.customerEmail = customerDetails.email;
    PaymentConfiguration.billToDetails.firstName = customerDetails.firstName;
    PaymentConfiguration.billToDetails.lastName = customerDetails.lastName;
    PaymentConfiguration.billToDetails.company = customerDetails.company;
    PaymentConfiguration.billToDetails.address = customerDetails.address;
    PaymentConfiguration.billToDetails.city = customerDetails.city;
    PaymentConfiguration.billToDetails.state = customerDetails.state;
    PaymentConfiguration.billToDetails.zip = customerDetails.zip;
    PaymentConfiguration.billToDetails.country = customerDetails.country;
    PaymentConfiguration.amount = billAmount.toString();
  }

  setPaymentPageState(makeActive: boolean) {
    PaymentConfiguration.paymentReadyToPerform = makeActive;
  }

  //
  getPaymentResponseToSave(
    paymentDTO: PaymentDTO,
    membershipUpdateState: string,
    isActive?: any
  ): SavePaymentDTO[] {
    const user = localStorage?.getItem('user');
    const userData = JSON.parse(user as any);
    let orderDetailInfo: any;
    const company = this.companyService.activeCompany;
    // const administrator = (this.registrationDataService.getAdministratorData() as Profile[]);
    // const primaryContact = (this.registrationDataService.getPrimaryContact() as Profile[]);
    orderDetailInfo = this.registrationDataService.orderDetailInfo;
    const invoices: SavePaymentDTO[] = [];
    if (isActive == undefined) {
      orderDetailInfo = this.registrationDataService.orderDetailInfo;
    } else {
      this.salesOrd = this.registrationDataService.getSalesOrder();
      orderDetailInfo = [];
      orderDetailInfo.push(<OrderDetailInfo>{});
    }
    orderDetailInfo.forEach((element: any) => {
      const savePaymentResponse: any = {
        invoice: {
          companyId: company.id!,
          orderId: isActive ? this.salesOrd.id : element.orderId,
          memberCount: isActive
            ? this.salesOrd.memberCount
            : element.memberCount,
          memberTerm: isActive ? this.salesOrd.memberTerm : element.memberTerm,
          orderNumber: element.orderNumber || '',
          standardPrice: isActive
            ? this.salesOrd.standardPrice
            : element.standardPrice,
          addlSeatAmount: isActive
            ? this.salesOrd.addlSeatAmount
            : element.addlSeatAmount,
          grossAmount: isActive
            ? this.salesOrd.grossAmount
            : element.grossAmount,
          netAmount: isActive ? this.salesOrd.netAmount : element.netAmount,
          paidAmount: isActive ? this.salesOrd.netAmount : element.netAmount,
          count: isActive ? this.salesOrd.count! : element.individualCount,
          discount: {
            type: isActive
              ? this.salesOrd.discount.type
              : element.discount.type,
            amount: isActive
              ? this.salesOrd.discount.amount
              : element.discount.amount,
            percentage: isActive
              ? this.salesOrd.discount.percentage
              : element.discount.percentage,
          },
          payment: paymentDTO,
          type: isActive ? this.salesOrd.type : element.type,
        },

        membershipId: isActive
          ? this.membershipService.activeMembership?.id
          : this.registrationDataService.membershipId,

        discountId: isActive
          ? this.salesOrd.discountMembershipId!
          : element.discountMembershipId,
        claimedBy: userData?.email,
        membershipStage: membershipUpdateState,
      };
      if (!isActive) {
        if (!element.discountMembershipId) {
          delete savePaymentResponse.discounts;
        }
        savePaymentResponse.invoice.otherDiscount = element.otherDiscount || [];
      } else {
        if (!this.salesOrd.discountMembershipId) {
          delete savePaymentResponse.discounts;
        }
        savePaymentResponse.invoice.otherDiscount =
          this.salesOrd.otherDiscount || [];
      }
      invoices.push(savePaymentResponse);
    });
    return invoices;
  }

  convertToISOString(timeString: string): string {
    const date = new Date(timeString);
    const isoString = date.toISOString();

    // Extract the time part without milliseconds and append ".974Z"
    const formattedTime = isoString.slice(0, 19) + '.974Z';
    return formattedTime;
  }

  convertUTCToLocalDateTime(utcDateTime: any) {
    const utcDate = new Date(utcDateTime);

    // Get the user's time zone offset in minutes
    const timeZoneOffset = new Date().getTimezoneOffset();

    // Calculate the local time by adding the time zone offset
    const localTime = new Date(utcDate.getTime() - timeZoneOffset * 60000);

    // Format the local time as an ISO string
    return localTime.toISOString();
  }
}

export class CustomerDetails {
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  company: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  country: string = '';
}
