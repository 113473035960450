import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { CompanyInfo } from './shared/models/profile';
import { AppConfig } from './shared/services/appconfig.service';
import { AppUser, AuthService } from './shared/services/auth.service';
import { CompanyService } from './shared/services/company.service';
import { PreloaderService } from './shared/services/preloader.service';
import { ProfileService } from './shared/services/profile.service';
import { GlobalDataProviderService } from './shared/services/registration-data.service';
import { SettingService } from './shared/services/setting.service';
import { TokenService } from './shared/services/token-service';
import { AppSnackBarService } from './shared/services/snackbar.service';
import { ErrorMessage } from './shared/constants/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cmmp-membership-ui';
  showLoader: boolean = false;

  isConfigReady = false;
  isAppSettingsReady = false;
  isSysSettingsReady = false;
  preloaderClass = 'preloader-page';
  //showCompanyField = true;
  isAuthenticated = false;
  redirectedFromEmail = false;
  isAdminLink = false;
  isPaymentCopyLinkUrl = false;

  formGroup = new FormGroup({
    company: new FormControl(''),
  });

  constructor(
    private loaderService: PreloaderService,
    private router: Router,
    private companyService: CompanyService,
    private profileService: ProfileService,
    private globalData: GlobalDataProviderService,
    private settingService: SettingService,
    private authService: AuthService,
    public oidcSecurityService: OidcSecurityService,
    private tokenService: TokenService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private snackbarService: AppSnackBarService
  ) {
    if (this.companyService.companyId) {
      //this.showCompanyField = false;
      this.router.navigateByUrl('/account/account-details');
    }

    const currentUrl = window.location.href;
    const paymentLinkPattern = /payment\/[a-zA-Z0-9]+/;
    const notFoundPagePattern = /not-found/;
    const adminLink = /admin/;
    const paymentCopyLinkUrl = /openlink\/payment\/[a-zA-Z0-9]+/;

    if (
      paymentCopyLinkUrl.test(currentUrl) ||
      !!this.getCopyPaymentLinkId()
    ) {
      this.authService.setUser(undefined, true);
      this.isPaymentCopyLinkUrl = true;
      const parsedUrlArr = currentUrl.split('/');

      if (paymentCopyLinkUrl.test(currentUrl)) {
        sessionStorage.setItem(
          'copyPaymentLink',
          JSON.stringify({
            copyPaymentLinkId: parsedUrlArr[5],
          })
        );
      } else {
        this.redirectToCopyPaymentLink();
      }

      this.showPreLoader();
      this.loadPrerequisites();
      return;
    }

    if (notFoundPagePattern.test(currentUrl)) {
      return;
    } else if (adminLink.test(currentUrl)) {
      this.isAdminLink = true;
      localStorage.setItem('adminLink', 'true');
      localStorage.setItem('currentURL', currentUrl);
    } else if (paymentLinkPattern.test(currentUrl)) {
      this.redirectedFromEmail = true;
      const parsedUrlArr = currentUrl.split('/');
      localStorage.setItem(
        'fromPaymentLink',
        JSON.stringify({
          companyId: parsedUrlArr[4],
          email: parsedUrlArr[5],
          emailTempValue: parsedUrlArr[6],
        })
      );
    }
  }

  ngOnInit(): void {
    if (this.isPaymentCopyLinkUrl) {
      return;
    }

    this.oidcSecurityService
      .checkAuth()
      .subscribe(({ isAuthenticated, accessToken, userData }) => {
        this.isAuthenticated = isAuthenticated;
        console.log(
          'app authenticated',
          isAuthenticated,
          accessToken,
          userData
        );
        if (isAuthenticated && accessToken) {
          //console.log(jwt_decode(accessToken));
          localStorage.setItem('accessToken', accessToken);
          this.authService.setUser({
            given_name: userData.given_name,
            family_name: userData.family_name,
            email: userData.email,
            roles: userData.role ? userData.role : [],
            token: accessToken,
          });
          //TODO: check the role and prepare current user object based on role
          this.loadPrerequisites();
        }
        if (!isAuthenticated && !this.isAdminLink) {
          this.oidcSecurityService.authorize();
        }
        // else {
        //   this.oidcSecurityService.authorize();
        // }
      });

    this.initADAuth();
    this.showPreLoader();
  }

  getCopyPaymentLinkId() {
    const sessionLinkData = sessionStorage?.getItem('copyPaymentLink')!;

    if (!sessionLinkData) {
      return null;
    }

    const parsedLinkInfo = JSON.parse(sessionLinkData);
    const copyPaymentLinkId = parsedLinkInfo.copyPaymentLinkId;

    return copyPaymentLinkId;
  }

  redirectToCopyPaymentLink() {
    const paymentLinkId = this.getCopyPaymentLinkId();

    if (!paymentLinkId) { return }

    this.router.navigate([
      'openlink/payment/',
      paymentLinkId
    ]);
  }

  showPreLoader() {
    this.loaderService.getPreloaderObs().subscribe((data) => {
      if (data) {
        setTimeout(() => {
          this.showLoader = Boolean(data.value);
        }, 100);
      }
      if (data && data.cssClass) {
        setTimeout(() => {
          this.preloaderClass = data.cssClass;
        }, 100);
      }
    });
  }

  private readonly _destroy = new Subject<void>();

  initADAuth() {
    this.msalBroadCastService.inProgress$
      .pipe(
        filter(
          (interactionStatus: InteractionStatus) =>
            interactionStatus == InteractionStatus.None
        ),
        takeUntil(this._destroy)
      )
      .subscribe((x) => {
        if (!this.isAuthenticated) {
          this.isAuthenticated =
            this.msalAuthService.instance.getAllAccounts().length > 0;

          if (this.isAuthenticated) {
            const adminAcc: AccountInfo = this.msalAuthService.instance.getAllAccounts()[0];
            const accessTokenRequest : SilentRequest = {
              account: this.msalAuthService.instance.getAllAccounts()[0],
              scopes: ["User.Read"]
            }
            this.acquireTokenSilent(accessTokenRequest).subscribe({
              next:(token) => {
                this.setAuthUser(adminAcc, token);
              },
              error : (err) => {
                console.log('Error fetching token: ',err);
                this.msalAuthService.acquireTokenRedirect(accessTokenRequest)
                this.setAuthUser(adminAcc, '');
              }
            });
            //TODO: check the role and prepare current user object based on role
            this.loadPrerequisites();
          }
        }
      });

    if (!this.isAuthenticated && this.isAdminLink) {
      if (this.msalGuardConfig.authRequest)
        this.msalAuthService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      else this.msalAuthService.loginRedirect();
    }
  }
  setAuthUser(adminAcc: AccountInfo, token: string) {
    this.authService.setUser({
      given_name: adminAcc.name ?? '',
      family_name: '',
      email: adminAcc.username,
      roles: ['Super Admin'],
      token: token
    })
  }
  
  acquireTokenSilent(accessTokenRequest : SilentRequest): Observable<string> {
    return this.msalAuthService.acquireTokenSilent(accessTokenRequest).pipe(
      map((response) => response.idToken ?? '')
    );
  }

  ngOnDestroy() {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  loadPrerequisites() {
    this.loaderService.showLoader();
    // Check if config is ready
    const interval = setInterval(() => {
      if (AppConfig.configSettings) {
        this.tokenService.getAPIToken().subscribe({
          next: (data) => {
            if (data && data.access_token) {
              localStorage.setItem('API_TOKEN', data.access_token);
              this.loadSettings();
              this.setAzureFunctionToken();
            }
          },
        });
        //this.loadSettings();
        clearInterval(interval);
      }
    }, 1000);

    const settingInterval = setInterval(() => {
      if (
        this.isAppSettingsReady &&
        this.isSysSettingsReady &&
        this.isAuthenticated
      ) {
        this.isConfigReady = true;
        this.loaderService.hideLoader();
        this.navigate();
        clearInterval(settingInterval);
      }
    }, 1000);
  }

  setAzureFunctionToken() {
    this.tokenService.getAPIToken(true).subscribe({
      next: (data) => {
        if (data && data.access_token) {
          localStorage.setItem('azure_function_token', data.access_token);
          if (this.isPaymentCopyLinkUrl) {
            this.loaderService.hideLoader();
            this.isConfigReady = true;
            this.redirectToCopyPaymentLink();
            return;
          } else {
            sessionStorage.removeItem('copyPaymentLink');
          }
        }
      },
    });
  }

  navigate() {
    if (this.authService.activeUser) {
      // if redirection for payment email link
      if (localStorage.getItem('fromPaymentLink')) {
        const paymentLink = JSON.parse(
          localStorage.getItem('fromPaymentLink')!
        );
        localStorage.removeItem('fromPaymentLink');
        if (paymentLink.email === this.authService.activeUser.email) {
          this.router.navigate([
            'payment',
            paymentLink.companyId,
            paymentLink.email,
          ]);
          return;
        } else if (paymentLink.email === 'transactionPage') {
          //email value matches to transaction page redirect to transaction history page
          this.loadCompany(paymentLink.companyId);
          this.loadProfile(paymentLink.emailTempValue);
          return;
        }
      }
      if (localStorage.getItem('adminLink')) {
        var currentURL = localStorage.getItem('currentURL');
        if (currentURL) {
          const path = currentURL.split('/');
          const component = path ? path[path.length - 1] : '';
          if (component === 'dedupe-account') {
            this.router.navigateByUrl('admin/dedupe-account');
            return;
          } else if (component === 'dedupe-contact') {
            this.router.navigateByUrl('admin/dedupe-contact');
            return;
          }
        }
        this.router.navigate(['admin']);
        return;
      }
      this.navigateToNormalUserFlow();
    }
  }

  loadCompany(companyId = '') {
    this.companyService.getCompanyById(companyId).subscribe({
      next: (data) => {
        this.companyService.activeCompany = data.data;
        this.companyService.companyId = companyId!;
        localStorage.setItem('companyId', companyId!);
        this.globalData.setCompanyId(companyId!);
        sessionStorage.setItem('enableOpenInvoice', 'true');
        this.router.navigate(['account/order-history'], {
          state: { isOpenInvoice: true },
        });
        return;
      },
      error: (err) => {
        this.loaderService.hideLoader();
        this.snackbarService.showErrorMessage(err.error.message || ErrorMessage.UnexpectedError, 'OK')
      },
    });
  }

  loadProfile(emailId = '') {
    this.profileService.getProfileByEmail(emailId).subscribe({
      next: (data) => {
        this.profileService.profile = data.data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  navigateToNormalUserFlow() {
    const user = this.authService.activeUser!;
    this.loadLoggedUserProfile(user);
  }

  loadSettings() {
    this.profileService.getRoleTypes();
    this.profileService.getRole();
    this.settingService.getSysSettings().subscribe({
      next: (data) => {
        this.isSysSettingsReady = true;
      },
      error: (err) => {
        console.log(err);
        this.loaderService.hideLoader();
      },
    });

    this.settingService.getAppSettings().subscribe({
      next: (data) => {
        this.isAppSettingsReady = true;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  loadLoggedUserProfile(user: AppUser) {
    this.loaderService.showLoader();
    this.globalData.setUserId(user.email);

    this.profileService.getProfileByEmail(user.email).subscribe({
      next: (profile) => {
        this.profileService.profile = profile.data;
        const companyInfo: CompanyInfo[] = profile.data.companyInfo;

        if (!companyInfo || companyInfo.length == 0) {
          this.loaderService.hideLoader();
          if (this.authService.isSuperAdminUser())
            this.router.navigateByUrl('admin');
          else this.router.navigateByUrl('choose-path');
        } else {
          //TODO: check if user is PBC or Admin
          if (
            this.authService.isAdmin() ||
            this.authService.isPbc() ||
            this.checkPbcOrAdminFromDB(companyInfo)
          ) {
            const companyId = companyInfo.find(
              (c) => c.isPrimary || c.isAdmin
            )?.id;
            this.companyService.companyId = companyId!;
            localStorage.setItem('companyId', companyId!);
            this.globalData.setCompanyId(companyId!);
            this.loaderService.hideLoader();
            this.router.navigateByUrl('account/account-details');
          } else {
            //TODO: redirect to unauthorized page
            this.router.navigateByUrl('choose-path');
            this.loaderService.hideLoader();
          }
        }
      },
      error: (err) => {
        this.loaderService.hideLoader();
        this.snackbarService.showErrorMessage(err.error.message || ErrorMessage.UnexpectedError,'OK');
      },
    });
  }

  // when there are no roles associated via IDS API calls, check user is a pbc or admin based on company details.
  checkPbcOrAdminFromDB(companyInfo: CompanyInfo[]): boolean {
    return companyInfo.find((c) => c.isPrimary || c.isAdmin)?.id ? true : false;
  }
}
