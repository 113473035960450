import { Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
  
  mobileMenu: boolean = false;
  mainMenu: boolean = false;
  selectedIndex : any = null;
  @Input() headerMenu: any[] = [];

  @ViewChild('sideMenu') sideMenu: ElementRef | undefined;

  constructor( private renderer: Renderer2){

    // this.renderer.listen('window', 'click', (e: Event) => {
    //   if (e.target !== this.sideMenu?.nativeElement) {
    //     console.log("mobilr menu");
    //   }
    // });

  }

  mobileMenuClick() {
    this.mobileMenu = !this.mobileMenu;
  }

  setIndex(index: any) {
    // this.selectedIndex = index;
    this.mainMenu = false;
    this.selectedIndex = (this.selectedIndex === index ? null : index); 
  }

  clickedOutside(): void {
    this.mobileMenu = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event:any) {
    this.mainMenu = true;
    if(this.mainMenu){
      this.selectedIndex = null;
    }
  }


}
