import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { UtilityService } from 'src/app/shared/services/app-util.service';

@Component({
  selector: 'app-edit-board',
  templateUrl: './edit-board.component.html',
  styleUrls: ['./edit-board.component.scss']
})
export class EditBoardComponent {

  constructor(private utilityService:UtilityService){}

  companyFieldError = {
    required: "Company Name is required",
    minlength: "Company Name should be more than 3 characters",
    maxlength: "Company Name cannot be more than 10 characters",
    pattern: AppConstants.COMPANY_NAME_ERROR_MESSAGE
  }

  requireError = {
    required: "Prefix is required",
  }

  prfixSelect = [
    'Admiral.',
    'Ambassador.',
    'Bishop.',
    'Brig.Gen.',
    'Colonel.',
    'Dr.',
    'Father.',
    'General.',
    'Governor.',
    'Honorable.',
    'Lady.',
    'Lord.',
    'Lt. Gen.',
    'Maj. Gen.',
    'Major.',
    'Miss.',
    'Monsignor.',
    'Mr.',
    'Mrs.',
    'Ms.',
    'Pastor.',
    'Prof.',
    'Professor.',
    'Rear Admiral.',
    'Rep.',
    'Rev.',
    'Senator.',
    'Sir.',
    'Sister.',
    'Vice Admiral.',
    'Madam.',
  ]

  formGroup = new FormGroup({
    companyName: new FormControl('', [this.utilityService.noWhitespaceValidator, this.utilityService.minLengthValidator(3), this.utilityService.maxLengthValidator(10), Validators.pattern(AppConstants.COMPANY_NAME_REGEX)]),
    prefix: new FormControl('', [this.utilityService.noWhitespaceValidator]),
    isPrimary: new FormControl('', [Validators.required]),
  })

  getCheck(){
    console.log("checked!")
  }

}
