import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyService } from 'src/app/shared/services/company.service';
import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private companyService: CompanyService,
    private authService: AuthService,
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const reqHeaders: any = <any>{};

    if (this.companyService.activeCompany?.id) {
      // skip for authorize.net
      if (request.url.indexOf('authorize.net') > -1) {
        return next.handle(request);
      }

      reqHeaders.companyId = btoa(this.companyService.activeCompany.id);
    }

    this.authService.user$.subscribe(data => {
      if (data) {
        const loggedInUser = (data.family_name ? data.given_name + ' ' + data.family_name : data.given_name);
        reqHeaders.loggedInUser = btoa(loggedInUser);
      }
    })

    if (!!reqHeaders.companyId || !!reqHeaders.loggedInUser) {
      request = request.clone({
        setHeaders: reqHeaders
      });
    }

    return next.handle(request);
  }
}
