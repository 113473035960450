export interface Validator {
    name: string;
    validatorName: string;
    value?: string;
    validator?: any;
    message: string;
}

import { Injectable } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { UtilityService } from '../services/app-util.service';
import { AppConstants } from '../constants/app-constants';
import { VALIDATION_MESSAGES_CONST } from '../constants/common-constants';

@Injectable({
  providedIn: 'root',
})
export class AddressValidatorService {

  constructor(private utilityService: UtilityService) {}

  setZipCodeValidators(
    formControl: FormControl,
    isUSAddress: boolean,
    zipcode: any
  ) {
    formControl.clearValidators();
    if (!isUSAddress) {
      formControl.setValidators([
        this.utilityService.minLengthValidator(2),
        Validators.pattern(AppConstants.ZIPCODE_NonUS_REGEX),
        Validators.required,
      ]);
      zipcode.pattern = AppConstants.ZIPCODE_NonUS_ERROR_MESSAGE;
      zipcode.minlength = AppConstants.ZIPCODE_NonUS_MIN_LENGTH_MESSAGE;
    } else {
      formControl.setValidators([
        this.utilityService.minLengthValidator(5),
        this.utilityService.maxLengthValidator(10),
        Validators.pattern(AppConstants.ZIPCODE_US_REGEX),
        Validators.required,
      ]);
      zipcode.pattern = AppConstants.ZIPCODE_US_ERROR_MESSAGE;
      zipcode.minlength = AppConstants.ZIPCODE_US_MIN_LENGTH_MESSAGE;
    }

    formControl.updateValueAndValidity();
  }

  setAddressLine1Validators(
    formControl: FormControl,
    isUSAddress: boolean,
    isAddressLine1Required: boolean = true
  ) {
    const validators = [Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)];
    if (isAddressLine1Required) {
      validators.push(Validators.required);
    }
  
    if (!isUSAddress) {
      validators.push(
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(5),
        this.utilityService.maxLengthValidator(255)        
      );
    } else {
      validators.push(
        Validators.minLength(5),
        Validators.maxLength(255)
      );
    }
    
    formControl.clearValidators();
    formControl.setValidators(validators);
    formControl.updateValueAndValidity();
  }
  
}
