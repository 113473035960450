import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-decrease-increase-box',
  imports: [CommonModule, MatFormFieldModule],
  templateUrl: './decrease-increase-box.component.html',
  styleUrls: ['./decrease-increase-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecreaseIncreaseBoxComponent),
      multi: true,
    },
  ],
  standalone: true,
})

/**
 * @description This component is used to create a box with a decrease and increase button
 * supporting both template and reactive forms.
 */
export class DecreaseIncreaseBoxComponent
  implements OnInit, ControlValueAccessor
{
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Output() countChange = new EventEmitter<number>();

  get disableDecreaseButton(): boolean {
    return this.value <= this.min;
  }

  get disableIncreaseButton(): boolean {
    return this.value >= this.max;
  }

  ngOnInit(): void {
    // check if the value is within the min and max range
    if (this.value < this.min) {
      this.value = this.min;
    }
    if (this.value > this.max) {
      this.value = this.max;
    }
  }

  decrease(): void {
    if (this.value > this.min) {
      this.value--;
      this.onChange(this.value);
    }
  }

  increase(): void {
    if (this.value < this.max) {
      this.value++;
      this.onChange(this.value);
    }
  }

  // Define a new method to update the value
  updateValue(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    let value: number;

    if (inputValue === '') {
      value = this.min;
    } else {
      value = parseInt(inputValue, 10);
    }

    if (isNaN(value)) {
      value = this.min;
    }

    if (value < this.min) {
      this.value = this.min;
    } else if (value > this.max) {
      this.value = this.max;
    } else {
      this.value = value;
    }
    this.countChange.emit(this.value);
    this.onChange(this.value);
  }

  // ControlValueAccessor interface methods
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onBlur(event: Event): void {
    let inputElement = event.target as HTMLInputElement;
    let inputValue = Number(inputElement.value);
  
    if (isNaN(inputValue) || inputValue < this.min) {
      this.value = this.min;
    } else if (inputValue > this.max) {
      this.value = this.max;
    } else {
      this.value = inputValue;
    }

    this.onChange(this.value);
    inputElement.value = String(this.value);
    
    this.onTouched();
  }
  

  onChange: any = () => {};
  onTouched: any = () => {};
}
