<div class="add-member renew-roster-table">
  <div class="form-section">
    <app-member-roster-table
      [hideDownload]="true"
      [displayColumns]="displayColumnsDefault"
      [dataSource]="datasource"
      [fromModal]="true"
    ></app-member-roster-table>
  </div>
  <div class="modal-footer">
    <app-button
      buttonClass="secondary-button"
      buttonLabel="Update"
      (onClick)="navigateToRoster()"
    ></app-button>
    <app-button
      buttonClass="primary-button"
      buttonLabel="Confirm"
      (onClick)="navigateToPayment()"
    ></app-button>
  </div>
</div>
