<div class="form-select">
  <div class="form-label-hint" *ngIf="label">
    <mat-label class="form-label"
      >{{ label
      }}<span *ngIf="requireMark" class="require-mark">*</span></mat-label
    >
    <mat-hint class="form-hint">{{ hint }}</mat-hint>
  </div>
  <mat-form-field>
    <mat-select [formControl]="control" value="" panelClass="form-select-panel">
      <mat-option value="">{{ DEFAULT_SELECT_VALUE }}</mat-option>
      <mat-option *ngFor="let item of items" [value]="item[valueKey]">{{
        item[labelKey]
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div
    class="form-error"
    *ngIf="control.invalid && (control.dirty || control.touched)"
  >
    <div *ngFor="let err of control?.errors | keyvalue">
      {{ errorMsgs[err.key] }}
    </div>
  </div>
</div>
