import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/common-components/modal/modal.component';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss'],
})
export class CommonPopupComponent {
  Cancel = 'Cancel';
  Send = 'Confirm';
  showCloseIcon = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<ModalComponent>
  ) {
    this.Send = data['ConfirmBtn'] || 'Confirm';
    this.Cancel = data['CancelBtn'] || 'Cancel';
    this.showCloseIcon = !!data.hasOwnProperty('showCloseIcon') ? data['showCloseIcon'] : true;
  }

  saveData() {
    this.ref.close(true);
  }

  cancel() {
    this.ref.close(false);
  }
}
