import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AppSnackBarService {

    constructor(private _snackBar: MatSnackBar) { }

    private showMessage(message: string, action: string, cssClasses: string[], duration?: number) {
        this._snackBar.open(message, '', {
            duration: duration || 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: cssClasses
        })
    }
    showSuccessMessage(message: string, action: string, duration?: number) {
        this.showMessage(message, action, ['toaster-success'], duration);
    }

    showErrorMessage(message: string, action: string, duration?: number) {
        this.showMessage(message, action, ['toaster-failure'], duration);
    }
}