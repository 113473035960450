<div class="form-input" [class.safari_only]="isSafariBrowser" [ngClass]="fieldType">
  <div class="form-label-hint" *ngIf="label">
    <mat-label class="form-label"
      >{{ label }}<span *ngIf="requireMark" class="require-mark">*</span>
    </mat-label>
    <!-- <mat-hint class="form-hint">{{ hint }}</mat-hint> -->
  </div>
  <mat-form-field appearance="outline" class="form-field-wrapper">
    <div class="autocomplete-input-container">
      <input
        matInput
        class="form-control"
        [formControl]="formControl"
        [matAutocomplete]="auto"
        #autoCompleteInput
      />
      <mat-icon (click)="onClear($event)" *ngIf="showClear">close</mat-icon>
    </div>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="getOptionText"
      (optionSelected)="selectSuggestion($event)"
    >
      <ng-container>
        <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion">
          <span>{{ suggestion.formattedAddress }}</span>
        </mat-option>
        <mat-option *ngIf="isShowAdd" [value]="''" class="add-new-button">
          <mat-icon>add</mat-icon><span>New Company</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-hint class="form-hint" *ngIf="hint">{{ hint }}</mat-hint>
  <div
    class="form-error"
    *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
  >
    <div *ngFor="let err of formControl.errors | keyvalue">
      {{ errorMsgs[err.key] }}
    </div>
  </div>
</div>
