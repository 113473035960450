import { Injectable } from '@angular/core';
import { BoardInfo, Company } from '../models/company';
import { BehaviorSubject, Observable } from 'rxjs';
import { Profile } from '../models/profile';
import { MembershipDto, OrderDetailInfo, SalesOrderSaveDto } from '../models/order-details';
import { MembershipRequestType } from '../constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataProviderService {
  membershipUpdateState = MembershipRequestType.NEW;
  individualMembershipBuyCount = 1;

  // below fields are required globally
  private userId!: string;
  private companyId!: string;
  companyData!: Company;
  memberRosterProfiles!: Profile[];
  membershipDto!: MembershipDto;
  boardInfoDto!: BoardInfo;
  private salesOrder!: any;

  // required for board contact
  primaryContactData!: Profile;
  adminstratorData!: Profile;

  // required for payments
  membershipId!: string;
  salesOrderId!: string;
  salesOrderSaveDto!: SalesOrderSaveDto;
  orderDetailInfo!: OrderDetailInfo[];

  isUpdateBoardInfo = false;
  isAutoCompleteEnabled = true;
  companySearchValue = ''; //corporate board company search value store
  isSavedPrimaryBoardInfo = false;
  isHistoricCompany = false;

  private companyDataObservable$ = new BehaviorSubject<any>({});
  private primaryContactDataObservable$ = new BehaviorSubject<any>({});
  private administratorDataObservable$ = new BehaviorSubject<any>({});
  private memberRosterDataObservable$ = new BehaviorSubject<any>({});
  private boardInfoObservable$ = new BehaviorSubject<any>({});

  public getUserId() {
    return this.userId;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public getCompanyId() {
    return this.companyId;
  }

  public setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  public getCompanyData(): any {
    return this.companyData;
  }

  public getBoardInfo(): any {
    return this.boardInfoDto;
  }

  public getBoardInfoObservable(): Observable<BoardInfo> {
    return this.boardInfoObservable$.asObservable();
  }

  public getCompanyDataObservable(): Observable<Company> {
    return this.companyDataObservable$.asObservable();
  }

  public setCompanyData(company: Company) {
    this.companyDataObservable$.next(company);
    this.companyData = company;
  }

  public setBoardInfo(boardInfo: BoardInfo) {
    this.boardInfoDto = boardInfo;
    this.companyData = boardInfo.company;
    this.boardInfoObservable$.next(boardInfo);
  }

  public setPrimaryContact(profile: Profile) {
    this.primaryContactData = profile;
    this.primaryContactDataObservable$.next(profile);
  }

  public getPrimaryContact() {
    return this.primaryContactData;
  }

  public setAdminstratorData(profile: Profile) {
    this.adminstratorData = profile;
    this.administratorDataObservable$.next(profile);
  }

  public getAdministratorData() {
    return this.adminstratorData;
  }

  public getMemberRosterObservable() {
    return this.memberRosterDataObservable$.asObservable();
  }

  public getMemberRosterProfiles() {
    return this.memberRosterProfiles.filter(profile =>
      profile.companyInfo?.some(company =>
        company.id === this.companyId && company.isHistoricUser !== true
      )
    );
  }

  public getHistoricUserProfileOfCurrentCompany(email: string) {
    return this.memberRosterProfiles.find(profile =>
      profile.eMail === email &&
      profile.companyInfo?.some(company =>
        company.id === this.companyId && company.isHistoricUser
      )
    );
  }

  public setIsHistoricCompany(isHistoric: boolean) {
    this.isHistoricCompany = isHistoric;
  }

  public getIsHistoricCompany(){
    return this.isHistoricCompany;
  }

  public setMemberRosterProfiles(profiles: Profile[]) {
    this.memberRosterProfiles = profiles;
  }

  public setMemberRosterProfile(profile: Profile) {
    if (!!this.memberRosterProfiles?.length) {
      //To remove existing PBC while adding new PBC in 2nd stepper
      if (profile.companyInfo?.some(c => c.id === this.companyId && c.isPrimary) &&
        this.memberRosterProfiles.some(
          (data) => data && data.companyInfo?.some(c => c.id === this.companyId && c.isPrimary)
        )
      ) {
        const oldPBCIndex = this.memberRosterProfiles.findIndex(
          (data) => data && data.companyInfo?.some(c => c.id === this.companyId && c.isPrimary)
        );
        this.memberRosterProfiles.splice(oldPBCIndex, 1);
      }

      let index = this.memberRosterProfiles.findIndex(
        (data) => data && data.id === profile.id
      );
      if (index >= 0) {
        let existingData = this.memberRosterProfiles[index];
        existingData = { ...existingData, ...profile };
        this.memberRosterProfiles[index] = existingData;
      } else {
        this.memberRosterProfiles.push(profile);
      }
    } else {
      this.memberRosterProfiles = [];
      this.memberRosterProfiles.push(profile);
    }
    this.setMemberRosterDataObservable();
  }

  clearMemberRoster() {
    this.memberRosterDataObservable$.next([]);
  }

  setMemberRosterDataObservable() {
    this.memberRosterDataObservable$.next(this.memberRosterProfiles.filter(profile =>
      profile.companyInfo?.some(company =>
        company.id === this.companyId && company.isHistoricUser !== true
      )
    ));
  }

  getMembershipDto() {
    return this.membershipDto;
  }

  setMembershipDto(membership: MembershipDto) {
    this.membershipDto = membership;
  }

  clearLocalData() {
    this.membershipId = undefined!;
    this.companyId = undefined!;
    this.companyData = undefined!;
    this.memberRosterProfiles = [];
    this.adminstratorData = undefined!;
    this.primaryContactData = undefined!;
    this.membershipDto = undefined!;
  }

  public getSalesOrder() {
    return this.salesOrder;
  }

  public setSalesOrder(salesData: any) {
    this.salesOrder = salesData;
  }
}
