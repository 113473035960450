import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUTC',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return new Date(
      new Date(value).setHours(new Date().getHours(), new Date().getMinutes())
    ).toISOString();
  }
}
