<!-- <div class="denotes-info paddding-0-20" ><span class="astrik">*</span> denotes a required field</div> -->
<!-- form section starts -->
<form [formGroup]="adminInfoForm" (ngSubmit)="saveProfile()" class="add-member">
    <div class="form-section">

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell">
                <app-select label="Prefix" [items]="prefixData" valueKey="value" labelKey="displayText"
                    [control]="adminInfoForm.controls.prefix" [errorMsgs]=""></app-select>
            </div>
            <div class="form-row-cell">
                <app-input label="First Name" [control]="adminInfoForm.controls.firstName"
                    [errorMsgs]="validationMessages.firstName" [requireMark]="true"></app-input>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell">
                <app-input label="Middle Name" [control]="adminInfoForm.controls.mi"
                    [errorMsgs]="validationMessages.mi"></app-input>
            </div>
            <div class="form-row-cell">
                <app-input label="Last Name" [control]="adminInfoForm.controls.lastName"
                    [errorMsgs]="validationMessages.lastName" [requireMark]="true"></app-input>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell">
                <app-select label="Suffix" [items]="suffixData" valueKey="value" labelKey="displayText"
                    [control]="adminInfoForm.controls.suffix" [errorMsgs]=""></app-select>
            </div>
            <div class="form-row-cell">
                <app-input label="Preferred Name" [control]="adminInfoForm.controls.preferredName"
                    [errorMsgs]="validationMessages.preferredName"></app-input>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell">
                <app-input label="Phone" hint="(Please include country code)" [control]="adminInfoForm.controls.phone"
                    [errorMsgs]="validationMessages.phone" [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
                <app-input label="Email" [control]="adminInfoForm.controls.email" [errorMsgs]="validationMessages.email"
                    [requireMark]="true"></app-input>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell direction-row">
                <app-checkbox [requireMark]="true" checkBoxLabel="Address Autocomplete (US only)"
                    [disable]="disableUSAddress" (emitChange)="changeUSAddress($event)" [control]="adminInfoForm.controls.isUSAddress"  [inputData]="defaultUSAddress"></app-checkbox>
                <app-checkbox [requireMark]="true" checkBoxLabel="Address same as Company" [disable]="false"
                    [disable]="adminInfoForm.controls.isEnteredAHomeAddress.value == true" (emitChange)="selectCompanyAddress($event)"></app-checkbox>  
                <app-checkbox checkBoxLabel="Home Address" (emitChange)="setAddressAsHome($event)"
                    [disable]="disableUSAddress" [title]="getHomeAddressToolTip()" [inputData]="adminInfoForm.controls.isEnteredAHomeAddress.value"></app-checkbox>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row">
            <div class="form-row-cell">
                <app-input label="Address Line 1" *ngIf="!defaultUSAddress"
                    [control]="adminInfoForm.controls.addressLine1" [errorMsgs]="validationMessages.addressLine1"
                    [requireMark]="true"></app-input>

                <app-address-autocomplete label="Address Line 1" *ngIf="defaultUSAddress"
                    [formControl]="adminInfoForm.controls.addressLine1"
                    [errorMsgs]="validationMessages.addressLine1" [requireMark]="true"
                    (emitAddress)="selectSuggestion($event)"
                    [suggestions]="suggestions"></app-address-autocomplete>
            </div>
            <div class="form-row-cell">
                <app-input label="Address Line 2" [control]="adminInfoForm.controls.addressLine2"
                    [errorMsgs]="validationMessages.addressLine2"></app-input>
            </div>
        </div>
        <!-- form row ends -->

        <!-- form row starts -->
        <div class="form-row four-column">
            <div class="form-row-cell">
                <app-input label="City" [control]="adminInfoForm.controls.city" [errorMsgs]="validationMessages.city"
                    [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
                <app-input label="State" [control]="adminInfoForm.controls.state" [errorMsgs]="validationMessages.state"
                    [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
                <app-input label="Country" [control]="adminInfoForm.controls.country"
                    [errorMsgs]="validationMessages.country" [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
                <app-input label="Zip Code" [control]="adminInfoForm.controls.zipcode"
                    [errorMsgs]="validationMessages.zipcode" [requireMark]="true"></app-input>
            </div>
        </div>
        <!-- form row ends -->
    </div>
    <div class="modal-footer">
        <app-button buttonClass="secondary-button" buttonLabel="Cancel" [mat-dialog-close]></app-button>
        <app-button buttonClass="primary-button" buttonLabel="Update" [disable]="!adminInfoForm.valid"
            btnType="submit"></app-button>
    </div>
    <!-- form section ends -->
</form>