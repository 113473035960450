<nav class="app-header-nav" clickOutside (clickOutside)="clickedOutside()" (click)="$event.stopPropagation()">
    <div class="menu-button" (click)="mobileMenuClick()">
        <span>Menu</span>
        <mat-icon>{{mobileMenu ? "close" : "menu"}}</mat-icon>
    </div>
    <ul [ngClass]="mobileMenu ? 'menu-list-ul show-menu' : 'menu-list-ul'">
        <li *ngFor="let item of headerMenu let i = index" [class.active]="selectedIndex === i" (click)="setIndex(i)" #sideMenu class="menu-list-item">
            <div class="main-menu-label">
                <span *ngIf="item.link" ><a [href]="item.link" target="_blank">{{item.mainMenu}}</a></span>
                <span *ngIf="!item.link">{{item.mainMenu}}</span>
                <mat-icon *ngIf="item.subMenu">expand_more</mat-icon>
            </div>
            <ul *ngIf="item.subMenu" class="submenu">
                <li *ngFor="let itemSubMenu of item.subMenu"><a [href]="itemSubMenu.link" target="_blank">{{itemSubMenu.label}}</a></li>
            </ul>
        </li>
    </ul>
</nav>