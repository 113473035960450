import { Component } from '@angular/core';

@Component({
  selector: 'app-super-admin-edit-member-roster',
  templateUrl: './super-admin-edit-member-roster.component.html',
  styleUrls: ['./super-admin-edit-member-roster.component.scss']
})
export class SuperAdminEditMemberRosterComponent {

  tableColumns = [
    { label: 'Name', def: 'name', dataKey: 'name' },
    {
      label: 'Category',
      def: 'category',
      dataKey: 'category',
    },
    { label: 'Description', def: 'description', dataKey: 'description' },
  ];

  productsList = [
    {
      name: 'Laptop',
      category: 'Computer',
      description: 'Lorem Ipsum is simply dummy text',
    },
    {
      name: 'Mouse',
      category: 'Accessories',
      description: 'Lorem Ipsum is simply dummy text',
    },
    {
      name: 'Monitor',
      category: 'Gaming System',
      description: 'cLorem Ipsum is simply dummy text',
    },
    {
      name: 'PC',
      category: 'COMPUTER',
      description: 'Lorem Ipsum is simply dummy text',
    },
  ];

  editTable(item: any) {
    console.log(item);
  }
}
