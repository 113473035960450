<!-- form section starts -->
<div class="form-section">

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Company Name" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                [requireMark]="true"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Website" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError">
                </app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-checkbox [requireMark]="true" [control]="formGroup.controls.isPrimary"
        checkBoxLabel="Address Autocomplete for US" (emitCheck)="getCheck()"></app-checkbox>
        </div>
    </div>
    <!-- form row ends -->

    

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Address Line 1" [control]="formGroup.controls.companyName"
            [requireMark]="true" [errorMsgs]="companyFieldError"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Address Line 2" [control]="formGroup.controls.companyName" [errorMsgs]="companyFieldError"
                ></app-input>
        </div>
    </div>
    <!-- form row ends -->


    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="City" [control]="formGroup.controls.companyName" [requireMark]="true"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="State" [control]="formGroup.controls.companyName" [requireMark]="true"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-input label="Country" [control]="formGroup.controls.companyName" [requireMark]="true"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
        <div class="form-row-cell">
            <app-input label="Zip Code" [control]="formGroup.controls.companyName" [requireMark]="true"
                [errorMsgs]="companyFieldError"></app-input>
        </div>
    </div>
    <!-- form row ends -->

    <!-- form row starts -->
    <div class="form-row">
        <div class="form-row-cell">
            <app-select label="Industry" [items]="prfixSelect" [control]="formGroup.controls.prefix"
                [errorMsgs]="requireError"></app-select>
        </div>
        <div class="form-row-cell">
            <app-select label="Company Type" [items]="prfixSelect" [control]="formGroup.controls.prefix"
                [errorMsgs]="requireError"></app-select>
        </div>
    </div>
    <!-- form row ends -->

</div>
<!-- form section ends -->

