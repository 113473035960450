import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Observable, Observer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Profile } from '../models/profile';
import { UtilityService } from './app-util.service';
import { CompanyService } from './company.service';
import { MembershipService } from './membership.service';
import { GlobalDataProviderService } from './registration-data.service';
import { AppSnackBarService } from './snackbar.service';
import { SettingService } from './setting.service';
import { Setting } from '../models/setting';
import { AppConstants } from '../constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class HtmlRendererService {
  hiddenDiv: HTMLDivElement | undefined;

  constructor(
    private _http: HttpClient,
    private companyService: CompanyService,
    private globalData: GlobalDataProviderService,
    private membershipService: MembershipService,
    private datePipe: DatePipe,
    private snackBarService: AppSnackBarService,
    private registrationDataService: GlobalDataProviderService,
    private settingService: SettingService
  ) {}

  fetchInvoiceHTMLElement(
    netAmount: any,
    memberTerm: any,
    invoiceDate: any,
    base64: any,
    invoiceId?: string,
    isPrint?: boolean,
    tempPaymentDueDate?: any
  ): Observable<string> {
    const invoiceHTMLFilePath =
      '.\\assets\\html-templates\\invoice-template.html';

    const allProfiles = this.registrationDataService.getMemberRosterProfiles();
    const companyData = this.companyService.activeCompany;
    const primaryContactData = UtilityService.getPrimaryContact(
      allProfiles,
      companyData.id!
    );

    return new Observable((observer: Observer<string>) => {
      const membershipStartDate =
        this.membershipService.activeMembership?.startDate;

      let paymentDueDate;
      let dueDate = new Date();
      if (membershipStartDate) {
        paymentDueDate = new Date(membershipStartDate);
        paymentDueDate.setDate(paymentDueDate.getDate() + 60);
      } else {
        dueDate.setDate(dueDate.getDate() + 60);
      }

      if (tempPaymentDueDate) {
        paymentDueDate = tempPaymentDueDate;
      }

      const companyData = this.companyService.activeCompany;
      const toAddress = companyData?.address;

      var officeAddress = this.settingService.getAppSettingValue(AppConstants.APPSETTING_NACD_OFFICE_ADDRESS);
      var officePhone = this.settingService.getAppSettingValue(AppConstants.APPSETTING_NACD_OFFICE_PHONE);
      const lines = officeAddress.split(", Arlington");
      const formattedAddress = `${lines[0].trim()}<br/>Arlington${lines[1]}`;

      netAmount = netAmount;
      const dynamicData = {
        InvoiceDate: invoiceDate,
        PaymentDueDate: this.datePipe.transform(
          paymentDueDate ? paymentDueDate : dueDate,
          'MM/dd/yyyy'
        ),
        Name: `${this.companyService.activeCompany.name}<br> ${
          primaryContactData.firstName + ' ' + primaryContactData.lastName
        }`,
        ToAddress: `${toAddress?.line1}<br>${
          toAddress?.line2 ? `${toAddress?.line2}<br>` : ''
        }${toAddress?.city}<br>${toAddress?.state}, ${toAddress?.zipCode}<br>${
          toAddress?.country
        }`,
        Description: 'Full Board Membership Dues - ' + memberTerm,
        Amount: netAmount,
        InvoiceID: invoiceId,
        FormattedAddress: formattedAddress,
        OfficeAddress: officeAddress,
        OfficePhone: officePhone
      };

      this.loadHTMLContent(invoiceHTMLFilePath, dynamicData).subscribe({
        next: (populatedHTMLContent: any) => {
          if (isPrint) {
            observer.next(populatedHTMLContent);
            observer.complete();
            return;
          }
          this.embedHTMLContentToHiddenDiv(populatedHTMLContent);

          const pdfMargin = 0.6;
          const bottomMargin = 0;

          this.downloadInvoiceAsPDFObservable(
            base64,
            pdfMargin,
            bottomMargin
          ).subscribe({
            next: (invoiceBase64Data: string) => {
              observer.next(invoiceBase64Data);
              observer.complete();
            },
            error: (error) => {
              console.error('Error generating PDF:', error);
              observer.next('');
              observer.complete();
            },
          });
        },
        error: (error) => {
          console.log('fetchInvoiceHTMLElement Error: ', error);
          observer.next('');
          observer.complete();
        },
      });
    });
  }

  fetchReceiptHTMLElement(
    transactionId: any,
    memberTerm: any,
    paymentMethod: any,
    amount: any,
    date: any,
    base64: any,
    membershipEndDateTemp?: any
  ): Observable<string> {
    const receiptHTMLFilePath =
      '.\\assets\\html-templates\\receipt-template.html';

    return new Observable((observer: Observer<string>) => {
      const currentDate = this.datePipe.transform(new Date(), 'MMMM dd, yyyy');

      const membershipEndDate = membershipEndDateTemp
        ? membershipEndDateTemp
        : this.membershipService.activeMembership?.endDate;
      let membershipActiveThrough;
      if (membershipEndDate) {
        membershipActiveThrough = this.datePipe.transform(
          new Date(membershipEndDate),
          'MMMM dd, yyyy'
        );
      }

      const pbcContact: Profile = UtilityService.getPrimaryContact(
        this.globalData.getMemberRosterProfiles(),
        this.companyService.activeCompany.id!
      );
      const PBCName = pbcContact
        ? pbcContact.firstName + ' ' + pbcContact.lastName
        : '';

      const transactionDate = this.datePipe.transform(
        new Date(date),
        'MMMM dd, yyyy'
      );

      var officeAddress = this.settingService.getAppSettingValue(AppConstants.APPSETTING_NACD_OFFICE_ADDRESS);

      const dynamicData = {
        currentDate: currentDate,
        name: PBCName,
        boardName: this.companyService.activeCompany.name,
        transactionID: transactionId,
        transactionDate: transactionDate,
        summary: 'Full Board Membership - ' + memberTerm + ' Dues',
        paymentType:
          paymentMethod?.toLowerCase() == 'echeck' ||
          paymentMethod?.toLowerCase() === 'check'
            ? 'Bank Account'
            : 'Credit Card',
        paymentTotal: '$' + amount,
        paymentMethod: paymentMethod,
        membershipEndDate: membershipActiveThrough,
        OfficeAddress: officeAddress
      };

      this.loadHTMLContent(receiptHTMLFilePath, dynamicData).subscribe({
        next: (populatedHTMLContent: any) => {
          if (base64) {
            this.embedHTMLContentToHiddenDiv(populatedHTMLContent);

            const pdfMargin = 0;
            const bottomMargin = 0.3;

            this.downloadInvoiceAsPDFObservable(
              base64,
              pdfMargin,
              bottomMargin
            ).subscribe({
              next: (invoiceBase64Data: string) => {
                observer.next(invoiceBase64Data);
                observer.complete();
              },
              error: (error) => {
                console.error('Error generating PDF:', error);
                observer.next('');
                observer.complete();
              },
            });
          } else {
            observer.next(populatedHTMLContent);
            observer.complete();
          }
        },
        error: (error) => {
          console.log('fetchReceiptHTMLElement Error: ', error);
          observer.next('');
          observer.complete();
        },
      });
    });
  }

  embedHTMLContentToHiddenDiv(content: string) {
    this.hiddenDiv = document.createElement('div');
    this.hiddenDiv.style.position = 'absolute';
    this.hiddenDiv.style.left = '-9999px';
    this.hiddenDiv.style.top = '-9999px';

    document.body.appendChild(this.hiddenDiv);

    this.hiddenDiv.innerHTML = content;
  }

  downloadInvoiceAsPDFObservable(
    base64: any,
    pdfMargin: any,
    bottomMargin: any
  ): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const pdf = new jsPDF('p', 'pt', 'a4', true);
      pdfMargin = pdfMargin * 72;

      if (this.hiddenDiv) {
        html2canvas(this.hiddenDiv, { scale: 2.5 })
          .then((canvas) => {
            const contentDataURL = canvas.toDataURL('image/png');
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            const contentWidth = width - 2 * pdfMargin;
            const contentHeight = height - 2 * pdfMargin;
            const offsetX = pdfMargin;
            const offsetY = pdfMargin;
            pdf.addImage(
              contentDataURL,
              'PNG',
              offsetX,
              offsetY,
              contentWidth,
              contentHeight - bottomMargin * 72,
              undefined,
              'FAST'
            );

            if (base64) {
              const base64Data = pdf.output('datauristring');
              const endIndexOfMetaData = base64Data.indexOf(',');
              const invoiceBase64Data =
                endIndexOfMetaData !== -1
                  ? base64Data.substring(endIndexOfMetaData + 1)
                  : base64Data;
              observer.next(invoiceBase64Data);
              observer.complete();
            } else {
              pdf.save('Invoice.pdf');
              observer.next('');
              observer.complete();
            }
          })
          .catch((error) => {
            observer.error(error);
          });
      } else {
        observer.next('');
        observer.complete();
      }
    });
  }

  loadHTMLContent(htmlFilePath: string, dynamicData: any): Observable<string> {
    return this._http.get(htmlFilePath, { responseType: 'text' }).pipe(
      map((data) => this.populatePlaceholders(data, dynamicData)),
      catchError((error) => {
        console.error('Error loading HTML content:', error);
        return '';
      })
    );
  }

  populatePlaceholders(content: string, data: { [key: string]: any }): string {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const placeholder = `{${key}}`;
        const value = data[key];
        content = content.replace(new RegExp(placeholder, 'g'), value);
      }
    }
    return content;
  }

  downloadReciptAsPDF(
    content: any,
    base64: any,
    pdfMargin: any,
    bottomMargin: any,
    type: any
  ): Observable<string> {
    this.embedHTMLContentToHiddenDiv(content);
    return new Observable((observer: Observer<string>) => {
      const pdf = new jsPDF('p', 'pt', 'a4', true);
      pdfMargin = 0.05 * 72;

      if (this.hiddenDiv) {
        html2canvas(this.hiddenDiv, { scale: 2.5 })
          .then((canvas) => {
            const contentDataURL = canvas.toDataURL('image/png');
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            const contentWidth = width + pdfMargin;
            const contentHeight = height - pdfMargin;
            const offsetX = 0;
            const offsetY = 0;
            pdf.addImage(
              contentDataURL,
              'PNG',
              offsetX,
              offsetY,
              contentWidth,
              contentHeight - bottomMargin * 72,
              undefined,
              'FAST'
            );
            if (base64) {
              const base64Data = pdf.output('datauristring');
              const endIndexOfMetaData = base64Data.indexOf(',');
              const invoiceBase64Data =
                endIndexOfMetaData !== -1
                  ? base64Data.substring(endIndexOfMetaData + 1)
                  : base64Data;
              observer.next(invoiceBase64Data);
              observer.complete();
            } else {
              pdf.save(type + '.pdf');
              this.snackBarService.showSuccessMessage(
                `${type} downloaded successfully`,
                'OK'
              );
              observer.next('');
              observer.complete();
            }
          })
          .catch((error) => {
            observer.error(error);
          });
      }
    });
  }
}
