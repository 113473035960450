import { CommonModule } from '@angular/common';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { isSafari } from '../../util-functions/util_functions';

@Component({
  selector: 'app-input',
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
})
export class InputComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() hint = '';
  @Input() placeholder = '';
  @Input() control = new FormControl();
  @Input() errorMsgs: any;
  @Input() requireMark: boolean = false;
  @Input() fieldType: any;
  @Input() showClear = false;
  @Input() isSearchInput = false;
  @Output() onTextClear = new EventEmitter<string>();

  isSafariBrowser = isSafari;

  onClear(event: any) {
    this.onTextClear.emit(event);
  }
}
