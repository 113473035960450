import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { EMPTY, Observable, catchError, debounceTime, distinctUntilChanged, lastValueFrom, of, startWith, switchMap } from 'rxjs';
import { AddressAutoCompleteComponent } from 'src/app/shared/common-components/address-autocomplete/address-autocomplete.component';
import { ButtonComponent } from 'src/app/shared/common-components/button/button.component';
import { CheckboxComponent } from 'src/app/shared/common-components/checkbox/checkbox.component';
import { InputComponent } from 'src/app/shared/common-components/input/input.component';
import { SelectComponent } from 'src/app/shared/common-components/select/select.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { Company } from 'src/app/shared/models/company';
import { Setting } from 'src/app/shared/models/setting';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AutocompleteService } from 'src/app/shared/services/autocomplete.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { PreloaderService } from 'src/app/shared/services/preloader.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { AppSnackBarService } from 'src/app/shared/services/snackbar.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { ChooseRoleComponent } from '../choose-role/choose-role.component';
import { CompanynameConfirmboxComponent } from '../companyname-confirmbox/companyname-confirmbox.component';
import { UnsavedChangesService } from 'src/app/shared/services/unsaved-changes.service';
import { VALIDATION_MESSAGES_CONST } from 'src/app/shared/constants/common-constants';
import { AddressValidatorService } from 'src/app/shared/models/validator';

@Component({
  selector: 'app-board-information',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    MatDialogModule,
    AddressAutoCompleteComponent,
  ],
  templateUrl: './board-information.component.html',
  styleUrls: ['./board-information.component.scss'],
  standalone: true,
})
export class BoardInformationComponent implements OnInit {
  industryData: any;
  companyTypeData: any;
  referenceTypeData: any;
  public sysSettings: Setting[] = [];
  public commonSettings: Setting[] = [];
  @Input() matStep: MatStep | undefined;
  @Input() stepperObj: MatStepper | undefined;
  formValuesChanged: boolean = false;
  initialFormValues: any;
  suggestions: any[] = [];
  companySuggestions: any[] = [];
  defaultUSAddress = true;
  disableCompanyType = false;
  hasAPIError = false;
  static ADDRESS_LINE_1 = 'addressLine1';
  companyStatus?: any;
  userRole: any;
  isSubmit = false;
  role: any;

  validationMessages = {
    companyName: {
      required: 'Company Name is required',
      minlength: 'Company Name should be atleast 3 characters',
      maxlength: 'Company Name cannot be more than 255 characters',
      pattern: AppConstants.COMPANY_NAME_ERROR_MESSAGE
    },
    website: {
      minlength: 'Website should be atleast 10 characters',
      maxlength: 'Website cannot be more than 255 characters',
    },
    addressLine1: {
      ...VALIDATION_MESSAGES_CONST.addressLine1,
      whiteSpaceValidator: 'Empty spaces not allowed',
    },
    addressLine2: VALIDATION_MESSAGES_CONST.addressLine2,
    city: VALIDATION_MESSAGES_CONST.city,
    state: VALIDATION_MESSAGES_CONST.state,
    country: VALIDATION_MESSAGES_CONST.country,
    zipcode: VALIDATION_MESSAGES_CONST.zipcode,
    industry: {
      required: 'Industry is required',
    },
    companyType: {
      required: 'Company Type is required',
    },
    referenceType: {
      required: 'How did you hear about us? is required',
    },
    isLegelCompany: {
      requiredTrue: 'LegelCompany Terms should be Accepted',
    },
  };

  boardInfoForm = new FormGroup({
    companyName: new FormControl('', [
      this.utilityService.minLengthValidator(3),
      this.utilityService.maxLengthValidator(255),
      this.utilityService.noWhitespaceValidator,
      Validators.required,
      Validators.pattern(AppConstants.COMPANY_NAME_REGEX)
    ]), //,this.utilityService.minimumLengthWithTrim(2)
    website: new FormControl('', [
      this.utilityService.minLengthValidator(10),
      this.utilityService.maxLengthValidator(255),
    ]),
    addressLine1: new FormControl('', [
      this.utilityService.noWhitespace,
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(255),
      Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
    ]),
    addressLine2: new FormControl('', [
      this.utilityService.minLengthValidator(1),
      this.utilityService.maxLengthValidator(255),
      Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
    ]),
    city: new FormControl('', [
      this.utilityService.minLengthValidator(3),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern(AppConstants.CITY_REGEX),
      Validators.required,
    ]),
    state: new FormControl('', [
      this.utilityService.noWhitespaceValidator,
      this.utilityService.minLengthValidator(2),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern('^[a-zA-Z ]*$'),
      Validators.required,
    ]),
    country: new FormControl('', [
      this.utilityService.noWhitespaceValidator,
      this.utilityService.minLengthValidator(3),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern('^[a-zA-Z ]*$'),
      Validators.required,
    ]),
    zipcode: new FormControl('', [
      Validators.required,
      this.utilityService.minLengthValidator(5),
      this.utilityService.maxLengthValidator(10),
      Validators.pattern(AppConstants.ZIPCODE_US_REGEX),
    ]),
    industry: new FormControl('', [Validators.required]),
    companyType: new FormControl('', [Validators.required]),
    referenceType: new FormControl('', [Validators.required]),
    isUSAddress: new FormControl(true),
    isLegelCompany: new FormControl(false, [Validators.requiredTrue]),
  });
  isRequiredFalse = false;
  companyName = '';
  isAdmin = false;
  companyId = '';
  constructor(
    private settingService: SettingService,
    private companyService: CompanyService,
    private stepperService: StepperService,
    private snackBarService: AppSnackBarService,
    public registrationDataService: GlobalDataProviderService,
    private spinner: PreloaderService,
    private modalService: ModalDataService,
    private autoCompleteService: AutocompleteService,
    private membershipService: MembershipService,
    private authService: AuthService,
    private modal: MatDialog,
    private utilityService: UtilityService,
    private profileService: ProfileService,
    private globalData: GlobalDataProviderService,
    private unsavedChangesService: UnsavedChangesService,
    private addressValidatorService: AddressValidatorService
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe((data) => {
      if (data?.roles.includes('Super Admin')) {
        this.isAdmin = true;
      }
    });
    if (
      this.membershipService.activeMembership &&
      !this.authService.isSuperAdminUser()
    )
      this.disableCompanyType = true;
    this.sysSettings = this.settingService.getSysSettingsData();
    this.boardInfoForm.controls.isUSAddress.setValue(true);
    this.initialFormValues = this.boardInfoForm.value;
    this.companyName = this.membershipService.companyName || '';
    if (this.companyName) {
      this.removeValidators();
    }
    const interval = setInterval(() => {
      if (this.sysSettings) {
        this.industryData = this.getSysSetting(AppConstants.SYSSETTINGINDUSTRY);
        if (this.industryData && this.industryData instanceof Array) {
          // this.industryData = this.industryData.sort((i, j) =>
          //   i.displayText.localeCompare(j.displayText)
          // );
          this.industryData = this.industryData.sort((a: any, b: any) =>
            a.displayText === 'Other'
              ? 1
              : b.displayText === 'Other'
                ? -1
                : a.displayText.localeCompare(b.displayText)
          );
        }
        this.companyTypeData = this.getSysSetting(
          AppConstants.SYSSETTINGCOMPANYTYPE
        );
        if (this.companyTypeData) {
          this.companyTypeData = this.companyTypeData.sort((i: any, j: any) =>
            i.displayText.localeCompare(j.displayText)
          );
        }
        this.referenceTypeData = this.getSysSetting(
          AppConstants.SYSSETTINGREFERENCETYPE
        );
        if (this.referenceTypeData) {
          // this.referenceTypeData = this.referenceTypeData.sort(
          //   (i: any, j: any) => i.displayText.localeCompare(j.displayText)
          //   );
          this.referenceTypeData = this.referenceTypeData.sort(
            (a: any, b: any) =>
              a.displayText === 'Other'
                ? 1
                : b.displayText === 'Other'
                  ? -1
                  : a.displayText.localeCompare(b.displayText)
          );
        }
        this.spinner.hideLoader();
        if (this.companyService.activeCompany && !this.companyName) {
          this.boardInfoForm.patchValue(
            this.prepareFormData(this.companyService.activeCompany),
            { emitEvent: false }
          );
          this.boardInfoForm.markAllAsTouched();
          this.setZipcodeValidation();
          if (this.disableCompanyType)
            FormControlService.disableFormControl(
              this.boardInfoForm.controls.companyType
            );
          this.initialFormValues = this.boardInfoForm.value;
          if (!this.matStep) this.boardInfoForm.markAllAsTouched();
        }
        clearInterval(interval);
      }
    }, 500);
    this.onAddressLineChange();
    this.onCompanyNameChange();

    this.boardInfoForm.valueChanges.subscribe((data) => {
      this.unsavedChangesService.hasUnsavedChanges =
        this.hasFormValuesChanged() && this.boardInfoForm.dirty;
    });
  }

  removeValidators() {
    const formControls = [
      'addressLine1',
      'city',
      'state',
      'country',
      'zipcode',
      'industry',
      'companyType',
      'referenceType',
    ];
    formControls.forEach((se) => {
      const field = this.boardInfoForm.get(se);
      field?.removeValidators(Validators.required);
    });
    this.isRequiredFalse = true;
    if (this.companyName !== 'isAdd') {
      this.boardInfoForm.controls.companyName.setValue(this.companyName);
    }
    this.boardInfoForm.controls.isLegelCompany.clearValidators();
  }

  prepareFormData(companyData: any): any {
    const patch = {
      companyName: companyData.name ?? '',
      website: companyData.website ?? '',
      addressLine1: companyData.address.line1 ?? '',
      addressLine2: companyData.address.line2 ?? '',
      city: companyData.address.city ?? '',
      state: companyData.address.state ?? '',
      country: companyData.address.country ?? '',
      zipcode: companyData.address.zipCode ?? '',
      industry: companyData.industry ?? '',
      companyType: companyData.type ?? '',
      referenceType: companyData.referenceType ?? '',
      isUSAddress: true,
    };
    return patch;
  }

  public getSysSetting(key: string): Setting[] {
    this.commonSettings = this.sysSettings.filter((data) => data.key === key);
    return this.commonSettings;
  }

  setZipcodeValidation(){
    this.addressValidatorService.setZipCodeValidators(
      this.boardInfoForm.controls['zipcode'],
      this.defaultUSAddress,
      this.validationMessages.zipcode
    );
  }

  changeUSAddress(event: MatCheckboxChange) {
    this.defaultUSAddress = event.checked;
    if (!this.defaultUSAddress) {
      this.boardInfoForm.patchValue(
        {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
        },
        { emitEvent: false }
      );
      this.suggestions = [];
    } else {
      this.boardInfoForm.patchValue({
        isUSAddress: true,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
      });
    }

    this.addressValidatorService.setAddressLine1Validators(
      this.boardInfoForm.controls['addressLine1'],
      this.defaultUSAddress
    );

    this.setZipcodeValidation();
  }

  hasFormValuesChanged(): boolean {
    return (
      JSON.stringify(this.initialFormValues) !==
      JSON.stringify(this.boardInfoForm.value)
    );
  }

  async save() {
    this.isSubmit = true;
    if (
      !this.boardInfoForm.controls.isLegelCompany.value &&
      !this.isRequiredFalse
    )
      return;
    if (
      !this.hasFormValuesChanged() &&
      !this.hasAPIError &&
      (this.role || this.isAdmin)
    ) {
      this.registrationDataService.setBoardInfo({
        company: this.companyService.activeCompany,
        defaultUSAddress: this.defaultUSAddress,
      });
      this.goToNextStep();
      // this.editAdvisor();
      return;
    } else if (
      !this.hasFormValuesChanged() &&
      !this.hasAPIError &&
      !this.role
    ) {
      this.editAdvisor();
      return;
    }
    this.matStep ? this.spinner.showLoader() : this.modalService.showLoader();
    const formData = this.boardInfoForm.getRawValue();
    this.initialFormValues = formData;
    let payload: Company = {
      name: formData.companyName ? formData.companyName : '',
      type: formData.companyType ? formData.companyType : '',
      website: formData.website ? formData.website : '',
      industry: formData.industry ? formData.industry : '',
      referenceType: formData.referenceType ? formData.referenceType : '',
      isActive: true,
      isDeleted: false,
      address: {
        line1: formData.addressLine1 ? formData.addressLine1 : '',
        line2: formData.addressLine2 ? formData.addressLine2.trim() : '',
        city: formData.city ? formData.city.trim() : '',
        state: formData.state ? formData.state.trim() : '',
        country: formData.country ? formData.country.trim() : '',
        zipCode: formData.zipcode ? formData.zipcode.trim() : '',
      },
    };
    // payload.name=payload.name.trim();
    // payload.address.city=payload.address.city?.trim()
    if (this.isRequiredFalse) {
      this.saveDayJobCompany(payload);
    } else {
      let existingCompanyData: Company = this.companyService.activeCompany;
      if (existingCompanyData && !existingCompanyData.salesforceAccountId) {
        this.getLatestCompanyData(existingCompanyData.id!).subscribe({
          next: (data: any) => {
            existingCompanyData = data.data;
            this.companyService.activeCompany = data.data;
            payload = existingCompanyData
              ? { ...existingCompanyData, ...payload }
              : payload;
            payload.id
              ? this.updateCompanyData(payload)
              : this.saveCompanyData(payload);
          },
        });
      } else {
        payload = existingCompanyData
          ? { ...existingCompanyData, ...payload }
          : payload;

        if ((payload.id || this.companyId) && !this.isRequiredFalse) {
          if (this.companyId) {
            await this.setCompanyDetails()
            payload.id = this.companyId;
          }
          else if (!payload.id) payload.id = '';
          this.updateCompanyData(payload);
        } else this.saveCompanyData(payload);
      }
    }
  }

  goToNextStep() {
    if (this.matStep) {
      this.stepperService.goToNextStep(this.matStep, this.stepperObj!);
    } else {
      this.modalService.closeModal({
        close: true,
        result: this.companyService.activeCompany,
      });
    }

    this.unsavedChangesService.hasUnsavedChanges = false;
  }

  saveCompanyData(company: Company) {
    this.companyService.saveCompany(company).subscribe({
      next: (response) => {
        this.companyService.activeCompany = response.data;
        localStorage.setItem('companyId', response.data.id); // TEMP till auth integration
        this.handleSuccess(response);
      },
      error: (err) => {
        this.handleError(err);
      },
    });
  }

  saveDayJobCompany(company: Company) {
    this.companyService.saveDayJobCompany(company).subscribe({
      next: (response) => {
        this.companyService.setDayJobCompany(response.data);
        this.matStep
          ? this.spinner.hideLoader()
          : this.modalService.hideLoader();
        if (this.isRequiredFalse) {
          this.membershipService.companyName = '';
          document.getElementById('close')?.click();
          return;
        }
      },
      error: (err) => {
        this.handleError(err);
      },
    });
  }

  updateCompanyData(company: Company) {
    this.companyService.updateCompany(company).subscribe({
      next: (response) => {
        this.companyService.activeCompany = response.data;
        this.handleSuccess(response);
      },
      error: (err) => {
        this.handleError(err);
      },
    });
  }

  handleSuccess(response: any) {
    this.hasAPIError = false;
    const boardInfo: any = {};
    boardInfo.company = response.data;
    boardInfo.defaultUSAddress = this.defaultUSAddress;
    //this.registrationDataService.setCompanyData(response.data);
    this.registrationDataService.setBoardInfo(boardInfo);
    this.companyService.activeCompany = response.data;
    this.matStep ? this.spinner.hideLoader() : this.modalService.hideLoader();
    if (this.isAdmin || !this.matStep || this.role) {
      this.goToNextStep();
    } else {
      this.editAdvisor();
    }
  }

  handleError(err: any) {
    this.hasAPIError = true;
    this.matStep ? this.spinner.hideLoader() : this.modalService.hideLoader();
    const msg =
      err && err.error && err.error.message.indexOf('already exists') != -1
        ? err.error.message
        : 'Failed to create a company';
    this.snackBarService.showErrorMessage(msg, 'OK');
  }

  selectSuggestion(value: any): void {
    if (value) {
      const line1 = `${value.number ? value.number : ''}  ${value.street ? value.street : ''
        }`;
      this.boardInfoForm.patchValue(
        {
          addressLine1: value.addressLabel ? value.addressLabel : line1.trim(),
          city: value.city,
          state: value.state,
          zipcode: value.postalCode,
          country: value.country,
          addressLine2: '',
        },
        { emitEvent: false }
      );
      // updating the formControl state
      [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'country',
        'zipcode',
      ].forEach((se) => {
        const field = this.boardInfoForm.get(se);
        field?.markAsDirty();
        field?.markAsTouched();
      });
    }
  }

  onAddressLineChange() {
    this.boardInfoForm.controls.addressLine1.valueChanges.pipe(
      startWith(''), debounceTime(500), distinctUntilChanged(),
      switchMap((filterValue) => {
        const data = (typeof filterValue === 'string') ? filterValue.trim() : '';

        if (!!data && this.defaultUSAddress) {
          return this.autoCompleteService
            .getAutocompleteSuggestions(data)
            .pipe(
              catchError((error) => {
                console.log(error);
                return EMPTY;
              })
            );
        } else {
          return of({ data: [] });
        }
      })
    ).subscribe({
      next: (response) => {
        const output = response.addresses || response.places || response.pois || [];
        this.suggestions = [...output];
      },
      error: (err) => {
        console.log(err);
        this.suggestions = [];
      },
    });
  }

  onCompanyNameChange() {
    this.boardInfoForm.controls.companyName.valueChanges.pipe(
      startWith(''), debounceTime(500), distinctUntilChanged(),
      switchMap((filterValue) => {
        if (!filterValue || !filterValue.length) {
          this.companyId = '';
          this.companySuggestions = [];
          return EMPTY;
        }
        if (!this.isRequiredFalse && this.globalData.isAutoCompleteEnabled
          && filterValue && filterValue.length > 2) {
          return this.companyService.searchCompanyByName(filterValue).pipe(
            catchError((error) => {
              console.log(error);
              this.companySuggestions = [];
              return EMPTY;
            })
          );
        }
        return EMPTY;
      })
    ).subscribe({
      next: (data) => {
        if (data.data && (this.matStep || this.isAdmin)) {
          this.companySuggestions = data.data;
          this.companySuggestions.map((d) => {
            d.formattedAddress = d.name;
          });
          this.companySuggestions = [...this.companySuggestions];
        }
      },
      error: (err) => {
        console.log(err);
        this.companySuggestions = [];
      },
    });
  }

  selectCompany(value: any) {
    if (value) {
      const selectedCompanyId = value.id;
      this.membershipService.getCompanyIdWithSubscription(selectedCompanyId).subscribe({
        next: (data) => {
          this.companyStatus = data;
          var subscriptionStatus = this.companyStatus.data.subscription;
          var companyId = selectedCompanyId;
          this.companySuggestions = [];
          if (subscriptionStatus == 'Active') {
            this.boardInfoForm.patchValue(
              {
                companyName: this.companyStatus.data.company.name,
              },
              { emitEvent: false }
            );
            this.registrationDataService.setCompanyId(companyId);
            if (this.authService.isSuperAdminUser()) {
              this.modalConfig(
                AppConstants.SUPER_ADMIN_COMPANY_SUBSCRIPTION_ACTIVE,
                this.companyStatus.data.company?.id
              );
            } else {
              // if (this.authService.isAdmin() || this.authService.isPbc()) {
              this.modalConfig(
                AppConstants.ADMIN_PBC_SUBSCRIPTION_ACTIVE,
                this.companyStatus.data.company?.id
              );
            }
          }
          if (
            subscriptionStatus == 'Expired' ||
            subscriptionStatus === 'InActive'
          ) {
            this.boardInfoForm.patchValue(
              {
                companyName: this.companyStatus.data.company.name,
              },
              { emitEvent: false }
            );
            let isInactive = subscriptionStatus === 'InActive';
            if (!this.authService.isSuperAdminUser()) {
              this.registrationDataService.setCompanyId(companyId);
              this.modalConfig(
                isInactive
                  ? AppConstants.ADMIN_PBC_SUBSCRIPTION_INACTIVE
                  : AppConstants.ADMIN_PBC_SUBSCRIPTION_EXPIRED,
                this.companyStatus.data.company?.id
              );
            }
            if (this.authService.isSuperAdminUser()) {
              this.modalConfig(
                isInactive
                  ? AppConstants.SUPER_ADMIN_COMPANY_SUBSCRIPTION_INACTIVE
                  : AppConstants.SUPER_ADMIN_COMPANY_SUBSCRIPTION_EXPIRED,
                this.companyStatus.data.company?.id
              );
            }
          } else if (subscriptionStatus == 'No membership Available') {
            this.companyId = selectedCompanyId;
            this.boardInfoForm.patchValue({
              companyName: this.companyStatus.data.company.name,
              website: this.companyStatus.data.company.website,
              addressLine1: this.companyStatus.data.company.address.line1,
              addressLine2: this.companyStatus.data.company.address.line2,
              city: this.companyStatus.data.company.address.city,
              state: this.companyStatus.data.company.address.state,
              country: this.companyStatus.data.company.address.country,
              zipcode: this.companyStatus.data.company.address.zipCode,
              industry: this.companyStatus.data.company.industry,
              companyType: this.companyStatus.data.company.type,
              referenceType: this.companyStatus.data.company.referenceType,
            }),
              { emitEvent: false };
          }
        }, error: (err: any) => {
          console.log(err);
          this.companyStatus = '';
        }
      });

      // if(this.companyStatus.data.subscription=="No Member")
      // this.moalConfig(" Entered Company Name already exists and has an active subscription. Would you like to review the existing account?");
    }
  }
  modalConfig(msg: string, companyId: string) {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: CompanynameConfirmboxComponent,
        data: msg,
        companyId: companyId,
        showCloseIcon: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(
      CompanynameConfirmboxComponent,
      modalConfig
    );
    modalRef.afterClosed().subscribe((item) => {
      this.boardInfoForm.patchValue({
        companyName: '',
        website: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        industry: '',
        companyType: '',
        referenceType: '',
      }),
        { emitEvent: false };
      this.companyId = '';
    });
  }
  getLatestCompanyData(companyId: string): Observable<any> {
    return this.companyService.getCompanyById(companyId);
  }
  editAdvisor() {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: ChooseRoleComponent,
        title: 'Please Select Your Role on the Board',
        showCloseIcon: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(ChooseRoleComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      this.role = this.companyService.setRole;
      console.log(this.role);
      if (this.role) {
        this.onBackButtonPressed();
        this.goToNextStep();
      }
    });
  }

  onBackButtonPressed() {
    this.stepperService.updateRole(this.role);
  }

  async setCompanyDetails() {
    const data: any = await lastValueFrom(this.profileService.getProfilesByCompany(this.companyId));
    this.profileService.roasters = data.data;
    this.globalData.setMemberRosterProfiles(data.data);
    const adminData = UtilityService.getAdminstrator(data.data, this.companyId);
    const primaryContact = UtilityService.getPrimaryContact(data.data, this.companyId);
    if (adminData) this.globalData.setAdminstratorData(adminData);
    if (primaryContact) this.globalData.setPrimaryContact(primaryContact);
  }

}
