<header class="app-header">
    <div class="app-header-top">
        <div class="app-header-content content">
            <div class="app-header-top-nav">
                <!-- <div class="app-header-top-item" (click)="login()" *ngIf="!isAuthenticated">
                    <img src="../../../assets/images/user-icon.svg" alt="" title="" />
                    Login
                </div> -->
                <div class="app-header-top-item" *ngIf="isAuthenticated">{{loggedInUser}}</div>
                <div class="app-header-top-item" (click)="logout()" *ngIf="isAuthenticated">
                    <img src="../../../assets/images/user-icon.svg" alt="" title="" />
                    Logout
                </div>
            </div>
        </div>
    </div>
    <div class="app-header-bottom">
        <div class="app-header-content content">
            <div class="app-header-logo">
                <img src="../../../assets/images/logo.png" alt="" title="" />
            </div>
            <app-header-menu [headerMenu]="headerMenu"></app-header-menu>
        </div>
    </div>
</header>