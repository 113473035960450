import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { ButtonComponent } from '../button/button.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { onPageChanges } from '../../util-functions/util_functions';
import { AppConstants } from '../../constants/app-constants';

@Component({
  selector: 'app-dedupe-table',
  templateUrl: './dedupe-table.component.html',
  styleUrls: ['./dedupe-table.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CommonModule,
    MatIconModule,
    CheckboxComponent,
    ButtonComponent
  ],
})
export class DedupeTableComponent {
  @Input() isAction: boolean = true;
  @Input() isEdit: boolean = false;
  @Input() isDelete: boolean = false;
  @Input() dataSourceTemp = [];
  @Input() displayedColumns: any;
  @Input() innerTableColumns: any;
  @Input() sortColumns: boolean = false;
  @Input() pageination: boolean = false;
  @Input() innerTable: boolean = false;
  @Input() sortValue: any;
  @Input() sortColumnList: any;
  @Input() parentTableColumnMapping: any;
  @Input() isActionInnerTable: boolean = true;
  @Input() isMasterRecord: boolean = false;
  @Input() isDeleteInnerTable: boolean = false;
  @Input() childTableColumnMapping: any;
  @Input() checkBoxColumn: any;
  @Input() arrowColumn: any;
  @Input() parentPageSize: any;
  @Input() totalCount = 0;
  @Input() mergeText: any;
  @Input() searchstatus = false;

  @Output() onEditClick = new EventEmitter<string>();
  @Output() onMasterRecordClick = new EventEmitter<any>();
  @Output() onDeleteClick = new EventEmitter<string>();
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onSortChange = new EventEmitter<string>();
  @Output() onDeleteDedupeChildClick = new EventEmitter<any>();
  @Output() onDeleteChild = new EventEmitter<any>();
  @Output() onChildItemCheckboxClick = new EventEmitter<any>();
  @Output() onClickParent = new EventEmitter<string>();
  
  expandedElement: any;

  sortColumnListInnerTable: [] = [];

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  @ViewChildren('innerPaginator') innerPaginator!: QueryList<MatPaginator>;
  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<any>>;
  dataSource = new MatTableDataSource();
  pageSize = 5; // Set your desired initial page size
  pageSizeOptions: number[] = [5, 10, 25, 50];
  parentIndex: any;
  parentRow: any;
  index = -1;
  parentPageSz = 10;
  duplicateItemsToMerge: any[] = [];

  ngOnChanges() {
    this.dataSource = new MatTableDataSource();
    let tableData = [];
    if (this.dataSourceTemp?.length) {
      tableData = JSON.parse(JSON.stringify(this.dataSourceTemp));

      tableData.forEach((dd: any) => {
        dd.duplicateList = new MatTableDataSource(dd.duplicateList);
      });

      this.dataSource = new MatTableDataSource(tableData) as any;
      
      if (this.index >= 0) {

        if (this.dataSource.data[this.index]) {
          let updateDuplicateCount = this.dataSource.data[this.index] as any;
          updateDuplicateCount.count = updateDuplicateCount.duplicateList.data.length;
          this.dataSource.data[this.index] = updateDuplicateCount;
        }

        const interval = setTimeout(() => {
          if (this.innerTables.length) {
            this.innerTables?.forEach(
              (table: any, index) =>
              ((table?.dataSource as MatTableDataSource<any>).paginator =
                this.innerPaginator?.toArray()[index])
            );
          }
        });
      }
    } else {
      this.expandedElement = null;
      this.index = -1;
    }
  }

  onEdit(item: any, event: any) {
    event.stopPropagation();
    this.onEditClick.emit(item);
  }

  onDelete(item: any, event: any) {
    event.stopPropagation();
    const group = item.accountDuplicateGroup || item.contactDuplicateGroup;
    const childDuplicates = (this.parentRow && item.mergeText === AppConstants.MERGE_ACCOUNT)
    ? ((group === item.accountDuplicateGroup) 
      ? this.parentRow.accountDuplicateGroup.duplicateCompanies 
      : this.parentRow.contactDuplicateGroup.duplicateMember)
    : null

    if(group && group.groupDataPullStatus === AppConstants.COMPLETED && childDuplicates)
    {
      (group === item.accountDuplicateGroup) 
      ? item.accountDuplicateGroup.companyId = childDuplicates[0].id
      : item.contactDuplicateGroup.memberId = childDuplicates[0].id
    }
    this.onDeleteClick.emit(item);
  }

  onDeleteDedupeChild(item: any, event: any, index: any) {
    event.stopPropagation();
    this.onDeleteChild.emit({
      data: this.dataSourceTemp,
      parentIndex: this.index,
      childIndex: index,
      rowData: this.parentRow,
      item: item,
    });
  }

  ngOnInit(): void {
    if (this.isAction) {
      this.displayedColumns.push('action');
    }
    if (this.isActionInnerTable) {
      this.innerTableColumns.push('action');
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  toggleInnerTable(element: any) {
    if (element?.accountDuplicateGroup) {
      this.expandedElement = this.expandedElement && this.parentRow?.accountDuplicateGroup?.id === element?.accountDuplicateGroup.id ? null : element;
    }
    if (element?.contactDuplicateGroup) {
      this.expandedElement = this.expandedElement && this.parentRow?.contactDuplicateGroup?.id === element?.contactDuplicateGroup.id ? null : element;
    }

    this.innerTables?.forEach(
      (table: any, index) =>
      ((table?.dataSource as MatTableDataSource<any>).paginator =
        this.innerPaginator?.toArray()[index])
    );
    this.parentRow = element;
    if (this.expandedElement && this.expandedElement.duplicateList.data.length == 0) {
      this.onClickParent.emit(element);
    }
  }

  changeMasterRecord(item: any, event: any, childIndex: any) {
    event.stopPropagation();
    this.onMasterRecordClick.emit({
      data: this.parentRow,
      childIndex: childIndex,
      item: item,
    });
  }

  emitParentPageChange(page: PageEvent) {
    if (this.parentPageSz !== page.pageSize) {
      this.parentPageSz = page.pageSize;
      this.paginator.pageIndex = 0;
      page.pageIndex = 0;
      page.previousPageIndex = 0;
    }
    const pageData: any = onPageChanges(page, page.pageSize);
    pageData.pageIndex = page.pageIndex;
    this.onPageChange.emit(pageData);
    this.index = -1;
  }

  onInnerTablePageChange(event: PageEvent): void {
    // Update the page size and handle data as needed
    this.pageSize = event.pageSize;
  }

  handleParentTableSort($event: any) {    
    this.expandedElement = null
    this.index = -1;
    this.paginator.pageIndex = 0;
    this.onSortChange.emit($event);
  }

  logIndex(index: number) {
    this.index = index;
    if (!this.expandedElement) {
      this.index = -1;
    }
  }

  toggleChildItemCheckbox(event: MatCheckboxChange, element: any) {
    const parentId = this.parentRow.accountDuplicateGroup ? this.parentRow.accountDuplicateGroup.id : this.parentRow.contactDuplicateGroup.id;
    console.log(parentId);
    if (event.checked) {
      if (!this.duplicateItemsToMerge[parentId])
        this.duplicateItemsToMerge[parentId] = [];
      this.duplicateItemsToMerge[parentId].push(element);
    } else {
      if (this.duplicateItemsToMerge[parentId]) {
        const items = this.duplicateItemsToMerge[parentId];
        items.splice(items.findIndex((el: any) => el.id === element.id), 1);
      }
    }
    this.onChildItemCheckboxClick.emit(this.duplicateItemsToMerge);
  }

  findItemFromDuplicateItemsToMerge(duplicateGroupId: any, duplicateItemId: any): boolean {
    const parentId = this.parentRow.accountDuplicateGroup ? this.parentRow.accountDuplicateGroup.id : this.parentRow.contactDuplicateGroup.id;
    if (this.duplicateItemsToMerge[parentId]) {
      const index = this.duplicateItemsToMerge[parentId].findIndex((el: any) => el.id === duplicateItemId)
      return index != -1 ? true : false;
    }
    return false;
  }

  
}
