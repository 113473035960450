import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-radio',
  imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, MatRadioModule],
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: true
})
export class RadioComponent {

  @Input() radioList: any[] = [];
  @Input() control = new FormControl();
  @Input() errorMsgs: any;
  @Input() requireMark: boolean = false;
  @Input() rowRadio: boolean = false;
  @Input() label = "";
  @Input() hint = '';
  @Input() data = "";
  @Input() selectedData="";
  @Input() isSubmit:boolean=false;

  @Output() optionChanged = new EventEmitter<string>();
  dataChange(event:any){
    this.selectedData=event.value
    console.log(this.selectedData)
    this.optionChanged.emit(this.selectedData);
  }

}
