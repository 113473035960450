<div class="modal-container">
    <app-preloader *ngIf="showLoader" preloaderClass="preloader-section" class="preloader-section-component"></app-preloader>

    <div class="modal-header">
        <div *ngIf="data.title" mat-dialog-title>{{data.title}}</div>
      <div *ngIf="data.showDenotes" class="denotes-info"><span class="astrik">*</span> denotes a required field</div>
        <mat-icon [mat-dialog-close] *ngIf="showCloseIcon">close</mat-icon>
    </div>
    <div mat-dialog-content>
        <ng-container *ngComponentOutlet="data.component; injector: data.injector" />
    </div>
</div>