import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';

@Component({
  selector: 'app-choose-role',
  templateUrl: './choose-role.component.html',
  styleUrls: ['./choose-role.component.scss']
})
export class ChooseRoleComponent {

  role:any;

  constructor(private companyService:CompanyService,
    private modalDataService:ModalDataService,
   // public dialogRef: MatDialogRef<ChooseRoleComponent>,
    private ref: MatDialogRef<ChooseRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,){
      
    }

    showCloseIcon = true;
  
  formGroup = new FormGroup({
    pbc: new FormControl(false),
    admin: new FormControl(false),
    noneOfAbove:new FormControl(false)
  });

  pbc(event:any){
    this.formGroup.get("noneOfAbove")!.setValue(false)
  }
  admin(event:any){
    this.formGroup.get("noneOfAbove")!.setValue(false)
  }
  noneOfAbove(event:any){
    console.log(event)
    if(event.checked){
      this.formGroup.get("admin")!.setValue(false)
      this.formGroup.get("pbc")!.setValue(false)
    }
  }
  save(){
    console.log(this.formGroup.value)
    if(this.formGroup.value.pbc && this.formGroup.value.admin){
     this.role='pbcad'
    }else if(this.formGroup.value.pbc){
      this.role='pbc'
    }else if(this.formGroup.value.admin){
      this.role='admin'
    }else{
      this.role='nonofabove'
    }  
    this.companyService.setRole=this.role

    this.modalDataService.saveButtonClick.emit();
    this.ref.close(true);

  } 
}
