<form [formGroup]="membershipForm" (ngSubmit)="membershipForm.valid && saveMembership()" class="board-advisor">
    <div class="board-advisor-content">
        <div class="form-section">
            <!-- form row starts -->
            <div class="form-row">
                <ng-container *ngIf="showPastDateAlert; else endDateTemplate">
                    <div>Membership End Date is set to past date. Would you like to proceed?</div>
                </ng-container>
                <ng-template #endDateTemplate>
                    <div class="form-row-cell form-row-cell-50">
                        <app-date-picker label="Membership End Date" [errorMsgs]="validationMessages.endDate"
                            [control]="membershipForm.controls.endDate"
                            (dateChanged)="dateChanged($event)"></app-date-picker>
                    </div>
                </ng-template>
            </div>
            <!-- form row ends -->
        </div>
    </div>
    <div class="modal-footer">
        <app-button buttonClass="secondary-button" *ngIf="!showPastDateAlert" [buttonLabel]="cancelButtonName"
            [mat-dialog-close]></app-button>
        <app-button buttonClass="secondary-button" *ngIf="!!showPastDateAlert" [buttonLabel]="cancelButtonName"
            (onClick)="cancel()"></app-button>
        <app-button buttonClass="primary-button" [buttonLabel]="addButtonName"
            [disable]="!membershipForm.valid || (!enableSave && !showPastDateAlert)" btnType="submit"></app-button>
    </div>
</form>