import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalDataService {

    private modalData$ = new Subject<any>();
    private modalLoader$ = new Subject<any>();
    public saveButtonClick: EventEmitter<void> = new EventEmitter<void>();
    public downloadButtonClick: EventEmitter<void> = new EventEmitter<void>();
    public emailButtonClick: EventEmitter<void> = new EventEmitter<void>();

    getModalDataObservable() {
        return this.modalData$.asObservable();
    }

    getModalLoaderObservable() {
        return this.modalLoader$.asObservable();
    }

    public showLoader() {
        this.modalLoader$.next(true);
    }

    public hideLoader() {
        this.modalLoader$.next(false);
    }

    public closeModal(data?: any) {
        this.modalData$.next(data);
    }
}
