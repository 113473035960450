import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from './appconfig.service';
import { GlobalDataProviderService } from './registration-data.service';
import { AppSnackBarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  _baseURL!: string;
  _profileAPIUrl!: string;
  _idsBaseUrl!: string;
  _mailTriggerUrl!: string;
  _signalrUrl!: string;
  _profileSignalrUrl!: string;

  public get baseURL(): string {
    return this._baseURL ?? AppConfig.configSettings.env.baseAPIUrl;
  }

  public set baseURL(v: string) {
    this._baseURL = v;
  }

  public get profileAPIUrl(): string {
    return this._profileAPIUrl ?? AppConfig.configSettings.env.profileAPIUrl;
  }

  public set profileAPIUrl(v: string) {
    this._profileAPIUrl = v;
  }

  public get idServerUrl(): string {
    return this._idsBaseUrl ?? AppConfig.configSettings.env.idsAPIUrl;
  }

  public set idServerUrl(v: string) {
    this._idsBaseUrl = v;
  }

  public get mailTriggerUrl(): string {
    return this._mailTriggerUrl ?? AppConfig.configSettings.env.mailTriggerUrl;
  }

  public set mailTriggerUrl(v: string) {
    this._mailTriggerUrl = v;
  }

  public get signalrUrl(): string {
    return this._signalrUrl ?? AppConfig.configSettings.env.signalrHubUrl;
  }

  public set signalrUrl(v: string) {
    this._signalrUrl = v;
  }

  public get profileSignalrUrl(): string {
    return this._profileSignalrUrl ?? AppConfig.configSettings.env.profileSignalrHubUrl;
  }

  public set profileSignalrUrl(v: string) {
    this._profileSignalrUrl = v;
  }

  constructor(
    protected _http: HttpClient,
    protected registrationDataService: GlobalDataProviderService,
    protected snackBarService: AppSnackBarService
  ) {
    const interval = setInterval(() => {
      if (AppConfig.configSettings) {
        this.baseURL = AppConfig.configSettings.env.baseAPIUrl;
        this.profileAPIUrl = AppConfig.configSettings.env.profileAPIUrl;
        this.idServerUrl = AppConfig.configSettings.env.idsAPIUrl;
        this.mailTriggerUrl = AppConfig.configSettings.env.mailTriggerUrl;
        this.signalrUrl = AppConfig.configSettings.env.signalrHubUrl;
        this.profileSignalrUrl = AppConfig.configSettings.env.profileSignalrHubUrl;
        clearInterval(interval);
      }
    }, 500);
  }
}
