import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ButtonComponent } from 'src/app/shared/common-components/button/button.component';
import { ModalComponent } from 'src/app/shared/common-components/modal/modal.component';
import {
  MembershipRequestType,
  MembershipStatus,
} from 'src/app/shared/constants/app-constants';
import { Profile } from 'src/app/shared/models/profile';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SalesService } from 'src/app/shared/services/sales.service';
import { AppSnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  imports: [CommonModule, ButtonComponent, MatTableModule, MatSortModule],
  selector: 'app-member-roster-table',
  templateUrl: './member-roster-table.component.html',
  styleUrls: ['./member-roster-table.component.scss'],
  standalone: true,
})
export class MemberRosterTableComponent {
  @Input() isRosterPage = false;
  @Input() datasource: any;
  @Input() displayColumns: String[] = [];
  @Input() hasDelete: boolean = false;
  @Input() hasPbc: boolean = false;
  @Output() emitActions = new EventEmitter<any>();
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() fromModal: boolean = false;
  @Input() forRenewal: boolean = false;
  @Input() remainingMembers = 0;
  @Input() hideDownload: boolean = false;
  @Input() memberShip: any;
  data: any;
  hideEdit = false;
  isRenewPeriodActive: any;
  activateStatus = false;

  @Input() set dataSource(data: any) {
    this.setDataSource(data);
  }

  memberRosters: any[] = [];
  paidAmount = '';
  paidDate = '';
  allProfiles!: Profile[];

  membershipStartDate = '';
  membershipEndDate = '';
  memberName = '';
  constructor(
    private snackBarSvc: AppSnackBarService,
    private modal: MatDialog,
    private salesService: SalesService,
    private router: Router,
    private membershipService: MembershipService,
    private registrationDataService: GlobalDataProviderService,
    private authService: AuthService
  ) {}

  ngOnChanges() {
    if (this.remainingMembers === 0) {
      this.activateStatus = true;
    } else {
      this.activateStatus = false;
    }
  }

  ngOnInit(): void {
    this.salesService.isPayLaterAdditionalSeat = false;
    this.registrationDataService.membershipUpdateState =
      MembershipRequestType.NEW; //resetting the membershipState

    if (this.remainingMembers === 0) {
      this.activateStatus = true;
    }

    if(this.sort){
      this.sort.sortChange.subscribe(() => {
        if (this.sort.direction === '') { // To avoid "no sort" used by Angular Mat tables and 
          this.sort.active = 'customSort'; // keep customSort active, reset direction to 'asc' on third click.
          this.sort.direction = 'asc';
        }
      });
    }
  }

  checkActiveMember(vs: any) {
    const memberRoster = this.datasource.data.find(
      (ds: any) => ds.member.id === vs.id
    );
    return memberRoster && memberRoster.statusDisplay === 'Active'
      ? true
      : false;
  }

  setDataSource(data: any) {
    if (data) {
      this.datasource = new MatTableDataSource<any>(data);
      this.datasource.sortingDataAccessor = (row: any, property: any) => {
        switch (property) {
          case 'customSort':
            // Sorting priority based on isPrimary, status, and name
            const isPrimary = row.member.pbc ? 0 : 1;
            const status = row.status ? 0 : 1;
            const name = row.member.name.toLowerCase();
            return `${isPrimary}-${status}-${name}`;
          case 'member':
            return row.member.name.toLowerCase();
          default:
            return row[property];
        }
      };

      if (this.sort) {
        this.sort.active = 'customSort'; 
        this.sort.direction = 'asc';
      }
      this.datasource.sort = this.sort;
    }

    if (this.datasource && this.datasource.filteredData) {
      let displayStatus = this.datasource.filteredData;
      displayStatus?.forEach((e: any) => {
        if (e.statusDisplay === 'Deactivated' && this.remainingMembers === 0) {
          this.hideEdit = true;
        } else {
          this.hideEdit = false;
        }
      });
    }
  }

  emitAction(row: any, action: string) {
    // expired / grace account restrict add member
    if (
      (action === 'activate' || action === 'deactivate') &&
      !this.forRenewal &&
      this.membershipService?.activeMembership &&
      new Date() > new Date(this.membershipService.activeMembership?.endDate!)
    ) {
      this.snackBarSvc.showErrorMessage(
        'Please purchase the membership subscription to modify the roster.',
        'OK'
      );
      return;
    }

    if (
      action === 'activate' &&
      this.remainingMembers === 0 &&
      !this.forRenewal &&
      !this.fromModal
    ) {
      if (
        this.membershipService.activeMembership?.status ===
          MembershipStatus.PENDING &&
        !this.remainingMembers &&
        !this.forRenewal &&
        (!this.memberShip.endDate ||
          (this.authService.isSuperAdminUser() && this.memberShip.endDate))
      ) {
        this.snackBarSvc.showErrorMessage(
          'Please purchase the membership subscription to add Additional members to the roster.',
          'OK'
        );
        return;
      }

      this.chooseExtraMemberToPay();
      return;
    }
    if (
      !this.fromModal &&
      ((row.member.pbc && action === 'remove') ||
        (!row.status && action === 'pbc'))
    ) {
      return;
    } else if (!this.fromModal) {
      this.emitActions.emit({ data: row, action: action });
    }
  }

  chooseExtraMemberToPay() {
    if (
      !this.remainingMembers &&
      new Date(this.membershipService.activeMembership?.endDate!).getMonth() ===
        new Date().getMonth() &&
      new Date().getFullYear() ===
        new Date(
          this.membershipService.activeMembership?.endDate!
        ).getFullYear()
    ) {
      const errorMessage = this.membershipService.isRenewPeriodActive
        ? 'Please renew the membership subscription to add Additional members to the roster.'
        : 'Purchase of Additional seat is allowed after the next membership term starts.';
      this.snackBarSvc.showErrorMessage(errorMessage, 'OK');
      return;
    }

    if (
      this.membershipService.activeMembership?.status ===
        MembershipStatus.PENDING &&
      !this.remainingMembers
    ) {
      this.salesService.isPayLaterAdditionalSeat = true;
    }

    const modalRef = this.modal.open(ModalComponent, {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        buttonOneLabel: 'cancel',
        buttonTwoLabel: 'Buy',
        text: 'How many additional seats do you want to purchase?',
        bodyComponent: 'count-box',
        min: 1,
        max: 99,
        value: 1,
      },
      panelClass: ['app-dialog', 'additional-seats-popup'],
      disableClose: true,
    });
    modalRef.afterClosed().subscribe((item) => {
      this.registrationDataService.membershipUpdateState =
        MembershipRequestType.ADDITIONAL;
      this.registrationDataService.individualMembershipBuyCount = item.value;
      this.router.navigate(['order-details']);
    });
  }
}
