<div class="form-date-picker">
    <div class="form-label-hint" *ngIf="label">
        <mat-label class="form-label">{{ label
            }}<span *ngIf="requireMark" class="require-mark">*</span></mat-label>
        <mat-hint class="form-hint">{{ hint }}</mat-hint>
    </div>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" [formControl]="control" (dateChange)="onDateChange($event)">

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="form-error" *ngIf="control.invalid && control.touched">
        <div *ngFor="let err of control.errors | keyvalue">
            {{ errorMsgs[err.key] }}
        </div>
    </div>

</div>