import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable()
export class FormControlService {

    public static disableFormControls(formControls: FormControl[]) {
        formControls.forEach((formControl: FormControl) => {
            this.disableFormControl(formControl)
        })
    }

    public static enableFormControls(formControls: FormControl[]) {
        formControls.forEach((formControl: FormControl) => {
            this.enableFormControl(formControl)
        })
    }

    public static disableFormControl(formControl: FormControl) {
        formControl.disable();
    }

    public static enableFormControl(formControl: FormControl) {
        formControl.enable();
    }
}