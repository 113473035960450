import { PageEvent } from '@angular/material/paginator';
import { AppConstants } from '../constants/app-constants';

export function onPageChanges(event: PageEvent, currentPageSize: number) {
  const pageSize = event.pageSize;
  let currentPage = event.pageIndex;
  let prevPage = event.previousPageIndex;
  let direction = '';
  // detect page size change and reset pagination
  // if (currentPageSize !== pageSize) {
  //   currentPageSize = pageSize;
  //   currentPage = 0;
  //   prevPage = 0;
  // }
  if (currentPage === prevPage) {
    direction = 'initial';
  } else if (currentPage > prevPage!) {
    direction = 'forward';
  } else {
    direction = 'backward';
  }
  //const filterValue = this.formGroup.controls.filterInput.value;
  // this.getCorporateBoard(pageSize, direction, filterValue ? filterValue : undefined)
  return { currentPageSize, direction, prevPage };
}

export function getRoleYears(startYear: number = 1970): number[] {
  const currentYear: number = new Date().getFullYear();
  const years: any[] = [];

  for (let year = currentYear; year >= startYear; year--) {
    const yearValue = year.toString();
    years.push({ id: yearValue, description: yearValue });
  }

  return years;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export function pushOtherRoleToBottom(roles: any[]): any[]  {
  const otherRoleIndex = roles.findIndex(role => role.description.toLowerCase() === AppConstants.OTHER.toLowerCase());
  if (otherRoleIndex > -1) {
    const otherRole = roles.splice(otherRoleIndex, 1)[0];
    roles.push(otherRole);
  }
  return roles;
}