import { Component, OnInit, Inject, OnDestroy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models/dialog-data';
import { ModalDataService } from '../../services/modal-data.service';
import { TOKEN } from '../../constants/app-constants';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ModalWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private modalDataService: ModalDataService) {
      this.showCloseIcon = data.showCloseIcon ? data.showCloseIcon : false;
      if (!data.injector) {
        data.injector = Injector.create({
          providers: [
            {
              provide: TOKEN,
              useValue: {},
            },
          ],
        });
      }
  }

  showLoader = false;
  showCloseIcon = true;

  ngOnInit(): void {
    this.modalDataService.getModalDataObservable().subscribe(data => {
      if (data.close && data.result) {
        this.dialogRef.close(data.result);
      } else {
        this.dialogRef.close();
      }
    })

    this.modalDataService.getModalLoaderObservable().subscribe(data => {
      if(!data) {
        this.showLoader = false;
      }
      this.showLoader = data;
    })
  }

  ngOnDestroy(): void {
    // this.modalDataService.modalData$.unsubscribe();
  }
}