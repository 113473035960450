import { Component, Injector, OnInit } from "@angular/core";
import { TOKEN } from 'src/app/shared/constants/app-constants';
import { MemberDto, MembershipDto } from "src/app/shared/models/order-details";
import { Profile } from "src/app/shared/models/profile";
import { CompanyService } from "src/app/shared/services/company.service";
import { EmailHelper } from "src/app/shared/services/email-helper.service";
import { MembershipService } from "src/app/shared/services/membership.service";
import { ModalDataService } from "src/app/shared/services/modal-data.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { GlobalDataProviderService } from "src/app/shared/services/registration-data.service";
import { AppSnackBarService } from "src/app/shared/services/snackbar.service";

@Component({
    selector: 'app-member-roster-pbc',
    templateUrl: './member-roster-pbc.component.html',
    styleUrls: ['./member-roster-pbc.component.scss']
})
export class MemberRosterPbcComponent implements OnInit {

    profileToUpdate: any;
    companyData: any;

    constructor(private injector: Injector,
        private modalService: ModalDataService,
        private profileService: ProfileService,
        private snackbar: AppSnackBarService,
        private emailHelper: EmailHelper,
        private registraionService: GlobalDataProviderService,
        private companyService: CompanyService,
        private membershipService: MembershipService) {

    }

    ngOnInit(): void {
        this.profileToUpdate = this.injector.get(TOKEN);
        this.companyData = this.companyService.activeCompany;
    }

    markAsPbc() {
        const currentPbcProfile = this.registraionService.getMemberRosterProfiles()?.find(
            profile => profile.companyInfo?.find(c => c.id === this.companyData.id && c.isPrimary === true)
        )
        const prevPbc = currentPbcProfile?.eMail;
        const currentPbc = this.profileToUpdate.eMail;
        if (currentPbc && prevPbc) {

            this.modalService.showLoader();
            this.profileService.updatePbc(this.companyData.id, currentPbc, prevPbc).subscribe({
                next: (value) => {
                    this.updateMembership(value.data)
                },
                error: (error) => {
                    this.modalService.hideLoader();
                    this.snackbar.showErrorMessage(error.error.message, "OK");
                    this.modalService.closeModal({ close: true, result: false });
                }
            })
        }
    }

    updateMembership(profiles: Profile[]) {
        const membership: MembershipDto = this.membershipService.activeMembership!;
        const members: MemberDto[] = [];
        profiles.map(p => {
            const index = membership.member.findIndex(m => m.id === p.id)
            const member: MemberDto = membership.member.find(m => m.id === p.id)!
            if (this.isPbc(p)) {
                member.isPrimary = true;
                member.eMail = p.eMail!
            } else {
                member.isPrimary = false;
                member.eMail = p.eMail!
            }
            p.member = member;
            membership.member[index] = member;
            members.push(member)
        })
        this.membershipService.updateMembershipRoster(membership.id!, members).subscribe({
            next: (data) => {
                if (data && data.data) {
                    this.membershipService.setActiveMembership(data.data);
                    this.registraionService.setMemberRosterProfile(profiles[0]);
                    this.registraionService.setMemberRosterProfile(profiles[1]);
                    this.snackbar.showSuccessMessage("Member has been changed to the Primary Board Contact ", "OK");
                    this.emailHelper.emailLogNewBpc();
                    this.modalService.closeModal({ close: true, result: true });
                }
            },
            error: (error) => {
                this.modalService.hideLoader();
                this.snackbar.showErrorMessage("Failed to change the Primary Board Contact", "OK");
                this.modalService.closeModal({ close: true, result: false });
            }
        })
    }

    private isPbc(profile: Profile) {
        const company = profile.companyInfo?.find(c => c.id === this.companyData.id);
        if (company) {
            return company.isPrimary;
        }
        return false;
    }
}