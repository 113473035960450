import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from './appconfig.service';
import { SalesOrderDto, SalesOrderSaveDto } from '../models/order-details';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  baseURL!: string;
  salesOrderIds = [];
  isPayLaterAdditionalInvoice = false;
  salesOrderId = '';

  isPayLaterAdditionalSeat = false;
  constructor(private http: HttpClient) {
    this.baseURL = AppConfig.configSettings.env.baseAPIUrl;
  }

  saveSalesOrder(
    salesOrder: SalesOrderSaveDto,
    requestType: string
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}/SalesOrder?membershipStage=${requestType}`,
      salesOrder
    );
  }

  submitInvoiceDetails(
    salesOrder: SalesOrderDto,
    requestType: string
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}/SalesOrder/CreateSalesOrder?MembershipStage=${requestType}`,
      salesOrder
    );
  }

  getSalesOrderByID(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/SalesOrder/${id}`);
  }

  getSalesOrderByCompanyID(companyId: string): Observable<any> {
    const params = new HttpParams().set('CompanyId', companyId);
    return this.http.get<any>(`${this.baseURL}/SalesOrder`, { params: params });
  }

  getOpenSalesOrder(id: string) {
    return this.http.get<SalesOrderDto>(
      `${this.baseURL}/SalesOrder/OpenStatus/${id}`
    );
  }

  getMultipleOpenInvoice(companyId: string, salesOrderIds: string[]) {
    return this.http.post<any>(
      `${this.baseURL}/SalesOrder/MultipleSalesOrders/${companyId}`,
      salesOrderIds
    );
  }

  updateSalesOrderPricing(salesOrder: SalesOrderSaveDto) {
    return this.http.put<any>(
      `${this.baseURL}/SalesOrder/UpdatePricing`,
      salesOrder.salesOrder
    );
  }
  getSalesOrderCompanyById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/SalesOrder/Company/${id}`);
  }
  // cancelSalesOrderCompanyById(id: string): Observable<any> {
  //   return this.http.put<any>(
  //     `${this.baseURL}/SalesOrder/CancelOpenSalesOrders?companyId=${id}`,
  //     id
  //   );
  // }
}
