<form [formGroup]="boardAdvisor" (ngSubmit)="boardAdvisor.valid && save()" class="board-advisor">

    <div class="board-advisor-content">
      <!-- <select (change)="onSelectOption()" formControlName="name">
          <option value="">Select an option</option>
          <option *ngFor="let option of displayData" [value]="option.name">{{ option.name }}</option>
        </select> -->
        <!-- <div class="denotes-info" ><span class="astrik">*</span> denotes a required field</div> -->
      <div class="form-section">
        <!-- form row starts -->
        <div class="form-row">
          <div class="form-row-cell">
            <app-select label="Name" [items]="displayData" [control]="boardAdvisor.controls.name" labelKey="name"
              valueKey="name" [errorMsgs]="validationMessages.name" [requireMark]="true"></app-select>
          </div>
          <div class="form-row-cell">
            <app-input label="Email" [control]="boardAdvisor.controls.email"></app-input>
          </div>
        </div>
        <!-- form row ends -->
  
        <!-- form row starts -->
        <div class="form-row">
          <div class="form-row-cell">
            <app-input label="Phone" [control]="boardAdvisor.controls.phone"></app-input>
          </div>
        </div>
        <!-- form row ends -->
      </div>
  
    </div>
  
    <div class="modal-footer">
      <app-button buttonClass="secondary-button" [buttonLabel]="cancelButtonName" [mat-dialog-close]></app-button>
      <app-button buttonClass="primary-button" [buttonLabel]="addButtonName" [disable]="!boardAdvisor.valid"
        btnType="submit"></app-button>
    </div>
  </form>
