<div class="body-container">
    <app-header></app-header>
    <section class="app-body">
            <app-preloader *ngIf="showLoader === true" [preloaderClass]="preloaderClass"></app-preloader>
        <!-- <div class="registration" *ngIf="showCompanyField && isAuthenticated">
            <div class="content">
                <form [formGroup]="formGroup" (ngSubmit)="$event.preventDefault(); navigate()">
                    <div class="form-section"  style="background-color: #fff;">
                        <div class="form-row">
                            <div class="form-row-cell">
                                <app-input label="Enter User" [control]="formGroup.controls.company"></app-input>
                                <mat-label>Enter User Id and click Submit to Proceed to Dashboard / Click Submit to proceed to registration</mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <app-button buttonClass="primary-button" buttonLabel="Submit"
                            btnType="submit"></app-button>
                    </div>
                </form>
            </div>
        </div> -->
        <!-- <app-preloader *ngIf="showLoader" preloaderClass="preloader-page"></app-preloader> -->
        <router-outlet *ngIf="isConfigReady || redirectedFromEmail"></router-outlet>
    </section>
    <app-footer></app-footer>
</div>