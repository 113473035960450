import { LinkStatusComponent } from './shared/common-components/link-status/link-status.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { AuthConfigModule } from './auth-config.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaymentCompletedComponent } from './components/payment-completed/payment-completed.component';
import { UnsavedChangesGuard } from './shared/guard/unsaved-changes.guard';

const routes: Routes = [
  // { path: '', redirectTo: '/choose-path', pathMatch: 'full'},
  {
    path: 'choose-path',
    loadComponent: () =>
      import('./components/choose-interest/choose-interest.component').then(
        (m) => m.ChooseInterestComponent
      ),
  },
  {
    path: 'components',
    loadComponent: () =>
      import('./layouts/all-components/all-components.component').then(
        (m) => m.AllComponentsComponent
      ),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./layouts/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'for-me',
    loadComponent: () =>
      import('./components/for-me/for-me.component').then(
        (m) => m.ForMeComponent
      ),
  },
  {
    path: 'nda/:id',
    loadComponent: () =>
      import('./components/nda/nda.component').then((m) => m.NdaComponent),
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'payment',
    loadComponent: () =>
      import('./components/payment/payment.component').then(
        (m) => m.PaymentComponent
      ),
  },
  {
    path: 'payment/:companyId/:eMail',
    loadComponent: () =>
      import('./layouts/payment-page/payment-page.component').then(
        (m) => m.PaymentPageComponent
      ),
  },
  {
    path: 'payment-success',
    loadComponent: () =>
      import('./components/payment-success/payment-success.component').then(
        (m) => m.PaymentSuccessComponent
      ),
  },
  {
    path: 'order-details',
    loadComponent: () =>
      import('./components/order-details/order-details.component').then(
        (m) => m.OrderDetailsComponent
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./layouts/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./layouts/admin/admin.module').then((m) => m.AdminModule),
  },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: 'payment-completed', component: PaymentCompletedComponent },
  {
    path: 'openlink/payment/:paymentLinkId',
    loadComponent: () =>
      import('./layouts/payment-copy-link/payment-copy-link.component').then(
        (m) => m.PaymentCopyLinkComponent
      ),
  },
  {
    path: 'payment-copy-link-status',
    component: LinkStatusComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking' }),
    AuthConfigModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe((config) => {
        console.log('ConfigLoaded', config);
      });
  }
}
