import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  // This class will have only static methods

  public static getAdminstrator(profiles: Profile[], companyId: string): any {
    const output = profiles.filter((pf) =>
      pf.companyInfo?.find(
        (x) => x.isAdmin && !x.isPrimary && x.id === companyId
      )
    );
    return output && output.length > 0 ? output[0] : undefined;
  }

  public static getPrimaryContact(profiles: Profile[], companyId: string): any {
    const output = profiles.filter((pf) =>
      pf.companyInfo?.find((x) => x.isPrimary && x.id === companyId)
    );
    return output && output.length > 0 ? output[0] : undefined;
  }

  
  public noWhitespaceValidator(control: any) {
    if (typeof control.value === 'object') return null;
    const isWhitespace = control.value?.length && (control.value).trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }
  public noWhitespace(control: any) {
    if (typeof control.value === 'object') return null;
    const isWhitespace = control.value?.length && (control.value).trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whiteSpaceValidator: true };
  }

  public minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.value === 'object') return null;
      if (control?.value && control.value?.trim().length < minLength) {
        return { minlength: true };
      }
      return null;
    };
  }

  public maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.value === 'object') return null;
      if (control.value && control.value.trim().length > maxLength) {
        return { maxlength: true };
      }
      return null;
    };
  }
}
