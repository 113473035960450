import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CheckboxComponent } from '../checkbox/checkbox.component';

export interface TableColumn {
  label: string;
  def: string;
  dataKey: string;
  formatt?: string;
  dataType?: 'date' | 'object' | boolean | string | number;
  trueLabel?: string;
  falseLabel?: string;
  suffix?: string;
  clickable?: boolean;
  hideSort?: boolean;
}

@Component({
  selector: 'app-table',
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    CheckboxComponent,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
})
export class TableComponent {
  dataSource: any = [];
  tableDisplayColumns: string[] = [];
  tableColumns: TableColumn[] = [];
  tablcol: string[] = [];

  @ViewChild(MatSort) sort!: MatSort;
  @Input() isEdit: boolean = false;
  @Input() isDelete: boolean = false;
  @Input() isPBC: boolean = false;
  @Input() isAction: boolean = false;

  @Input() set data(data: any) {
    this.dataSource = data;
  }
  matTableData = new MatTableDataSource([]);
  @Output() onEditClick = new EventEmitter<string>();
  @Output() onColumnClicked = new EventEmitter<{ row: any }>();
  @Output() oncheckClicked = new EventEmitter<any>();

  @Input() set columns(columns: TableColumn[]) {
    this.tableColumns = columns;
    this.tableDisplayColumns = this.tableColumns.map((col) => col.def);
  }

  constructor(public datePipe: DatePipe) {}

  onEdit(item: any) {
    this.onEditClick.emit(item);
  }
  onDelete(item: any) {
    this.onEditClick.emit(item);
  }
  onPBC(item: any) {
    this.onEditClick.emit(item);
  }
  onColumnClick(row: any) {
    this.onColumnClicked.emit({ row });
  }

  ngOnInit(): void {
    if (this.isAction) {
      this.tableDisplayColumns.push('action');
    }
  }
  ngOnChanges() {
    this.matTableData = new MatTableDataSource(this.dataSource) as any;
    if (this.matTableData.data?.length) {
      this.matTableData.sort = this.sort;
    }
  }

  splitEmail(data: any) {
    if (data.validFor) {
      const emails = data.validFor.split(',');
      return emails.length > 1 ? `${emails[0]}   ${emails[1]}` : emails[0];
    }
  }

  updateSelection(event: any, row: any) {
    this.oncheckClicked.emit({ event, row });
  }
}
