import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  imports: [
    CommonModule,
    RouterLink,
    MatIconModule,
    MatExpansionModule,
    RouterModule,
  ],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
})
export class SideMenuComponent {
  @Input() sideMenu: any[] = [];
  @Input() sideMenuName: any;
  @Input() sideMenuEmail: any;

  goToResetPassword(menuName: string) {
    if (menuName === 'Update Password') {
      window.location.href = environment.ids_update_password;
    }
  }

  onSubRouterLinkActive(menu: any) {
    menu['isSubActive'] = menu.submenu.some((s: any) => s['isNavActive']);
  }
}
