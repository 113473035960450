import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddMemberComponent } from '../add-member/add-member.component';
import { JsonpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-firstname-confirmbox',
  templateUrl: './firstname-confirmbox.component.html',
  styleUrls: ['./firstname-confirmbox.component.scss']
})
export class FirstnameConfirmboxComponent {
  companyId?: string
  message:any;
  @Output() public modal_principal_parent = new EventEmitter();
  constructor(
    private dialogRef: MatDialogRef<FirstnameConfirmboxComponent>,
    private parentRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router
  ) {
    if (data) {
      this.message=data.data;
      this.companyId = data.companyId;
      this.parentRef = data.companySuggestions;
    }
  }
  closeDialog() { 
    //this.router.navigate(['admin/edit-corporate-boards']);
    // this.router.navigate(['https://cmmp-dev.com:4201/account/manage-roster']); 
    this.dialogRef.close('CLOSE_ADD_MEMBER_MODAL');
  }
}
