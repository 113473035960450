import { NgModule } from '@angular/core';
import {
  AuthModule,
  LogLevel
} from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.ids_frontend_authority,
        redirectUrl: environment.ids_frontend_redirect_url,
        postLogoutRedirectUri: environment.ids_frontend_logout_url,
        clientId: environment.ids_frontend_client_id,
        scope: environment.ids_frontend_scope,
        responseType: environment.ids_frontend_responseType,
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
      },
    }),
  ],
  providers: [
    // { provide: AbstractSecurityStorage, useClass: MyStorageService },
    // { provide: AbstractLoggerService, useClass: MyLoggerService },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
