export class AppConstants {
  static SYSSETTING: string = 'SysSettings';
  static APPSETTING: string = 'AppSettings';

  static APPSETTING_FOR_ME = 'Registration.ChooseIntrest.ForMe.Text';
  static APPSETTING_FOR_MY_BOARD = 'Registration.ChooseIntrest.ForMyBoard.Text';
  static APPSETTING_PRIMARY_BOARD = 'Registration.BoardContact.PBCHelp.Text';
  static APPSETTING_ADMINISTATOR = 'Registration.BoardContact.AdminHelp.Text';
  static APPSETTING_MEMBER_ROSTER =
    'Registration.MemberRoster.ValidateMinNumber.Text';
  static APPSETTING_ORDER_DETAILS =
    'Registration.OrderDetails.Information.Text';
  static APPSETTING_PAY_NOW =
    'Registration.OrderDetails.PayNowConfirmation.Text';
  static APPSETTING_PROMOTIONAL = 'Registration.NDA.Promotional.Text';
  static APPSETTING_LEGAL_AGREEMENT = 'Registration.NDA.LegalAgreement.Text';
  static APPSETTING_TRAIL_PERIOD = 'Registration.NDA.TrailPeriod.Text';
  static APPSETTING_GATE_CON = 'SA.CoperateBoards.GateCon.Text';
  static APPSETTING_GATE_CON_HIDE = 'SA.CoperateBoards.GateCon.Hiding';
  static APPSETTING_ADDMEMBER_TOP =
    'Registration.MemberRoster.AddMembers.TopText';
  static APPSETTING_ADDMEMBER_BOTTOM =
    'Registration.MemberRoster.AddMembers.BottomText';
  static APPSETTING_EMAILLOG_MET_BDT_RECIPIENTS = 'EmailLog.Notification';
  static APPSETTING_EMAILLOG_ACCOUNTING_RECIPIENTS =
    'EmailLog.Accounting.RecipientList';
  static APPSETTING_EMAILLOG_BDT_RECIPIENTS =
    'EmailLog.Notification.BDT.RecipientList';
  static APPSETTING_EMAILLOG_MET_RECIPIENTS =
    'EmailLog.Notification.MET.RecipientList';
  static APPSETTING_EMAILLOG_ENABLE_PAYMENTREMINDER =
    'EmailLog.PaymentReminder.Enable';
  static APPSETTING_EMAILLOG_ENABLE_RENEWALINVOICE =
    'EmailLog.RenewalInvoice.Enable';
  static APPSETTING_PAYMENTCOPYLINK_INPROGRESSDURATION_STATIC_HELP_TEXT =
  'PaymentCopyLink.InProgressDuration.Minutes';
  static APPSETTING_ORDERDETAILS_EXTRACHARGE_MAXVALUE = 'OrderDetails.ExtraCharge.MaxValue';
  static APPSETTING_ORDERDETAILS_MAX_PRICEOVERRIDE_DISCOUNT_PERCENT = 'OrderDetails.MaxPriceOverrideDiscount.Percent';
  static APPSETTING_SUPERADMIN_ACCOUNTING_RECIPIENTS = 'SuperAdmin.Accounting.EmailList';
  static APPSETTING_SUPERADMIN_BDT_RECIPIENTS = 'SuperAdmin.BDT.EmailList';
  static APPSETTING_SUPERADMIN_MET_RECIPIENTS = 'SuperAdmin.MET.EmailList';
  static APPSETTING_SUPERADMIN_DAO_RECIPIENTS = 'SuperAdmin.DAO.EmailList';
  static APPSETTING_ORDERDETAILS_NEWPRICING_EFFECTIVE_DATE = 'OrderDetails.NewPricing.EffectiveDate';

  static SYSSETTINGINDUSTRY: string = 'Registration.BoardInformation.Industry';
  static SYSSETTINGCOMPANYTYPE: string =
    'Registration.BoardInformation.CompanyType';
  static SYSSETTINGREFERENCETYPE: string =
    'Registration.BoardInformation.ReferenceType';
  static SYSSETTING_ORDERDETAILS = 'Registration.OrderDetails';
  static SYSSETTING_ORDERDETAILS_CONTACT = 'Registration.OrderDetails.Contact';
  static SYSSETTING_ORDERDETAILS_MEMBERCOUNT =
    'Registration.OrderDetails.MemberCount';
  static SYSSETTING_ORDERDETAILS_MEMBERSHIPTERM =
    'Registration.OrderDetails.MembershipTerm';
  static SYSSETTING_ORDERDETAILS_STATIC_HELP_TEXT =
    'Registration.OrderDetails.StaticHelpText.Text';

  static APPSETTING_MEMBERROSTER_PRIORITY1_IMPLEMENTATIONDATE = 'MemberRoster.ErrorHandling.Priority1.ImplementationDate';
  static APPSETTING_MEMBERROLES_DEPRECATED_EXECUTIVE_ROLEIDS = 'Profile.CompanyRoles.DeprecatedExecutiveRoleIDs';

  static APPSETTING_PAYMENT = 'Registration.Payment';
  static APPSETTING_PAYMENT_AUTHORIZE = 'Registration.Payment.Authorize';
  static PAYMENT_LOGIN_ID = 'ApiLoginId';
  static PAYMENT_TRANSACTION_ID = 'TransactionId';
  static PAYMENT_CUSTOMER_PROFILE_ID = 'CustomerProfileId';
  static PAYMENT_FORM_URL = 'paymentFormUrl';
  static PAYMENT_REQUEST_URL = 'paymentRequestUrl';
  static PAYMENT_BILLING_ADDRESS_REQUIRED = 'isBillingAddressRequired';
  static PAYMENT_CREDITCARD_TYPES = 'Payment.Credit.CardTypes'
  
  static NONPROFIT_SEATS_CODE = 'NotForProfitPrice';
  static PLAN_PRICE_CODE = 'PlanPrice';
  static ADDITIONAL_SEATS_CODE = 'AddlSeatAmount';
  static SYSSETTINGPREFIX: string = 'Registration.BoardContact.Prefix';
  static SYSSETTINGSUFFIX: string = 'Registration.BoardContact.Suffix';
  static NOT_FOR_PROFIT: string = 'Not-For-Profit';
  static SYSSETTING_MEMBERROSTER_ROLE = 'Registration.MemberRoster.Role';

  static MEMBER_ADD_MSG = 'Member added to the roster';
  static MEMBER_UPDATE_MSG = 'Member details are updated to the roster';
  static MEMBER_ADD_ERROR_MSG = 'Failed to add a new member to the roster';
  static MEMBER_UPDATE_ERROR_MSG = 'Failed to update a member to the roster';
  static MEMBER_ALREADY_EXIST_MSG = 'The member is already part of the roster';
  static SUPER_ADMIN_COMPANY_SUBSCRIPTION_ACTIVE =
    'Selected Company has an Active Corporate Membership. Would you like to review the existing account?';
  static SUPER_ADMIN_COMPANY_SUBSCRIPTION_EXPIRED =
    'Selected Company has an Expired Corporate Membership. Would you like to review the existing account?';
  static SUPER_ADMIN_COMPANY_SUBSCRIPTION_INACTIVE =
    'Selected Company is Inactive. Would you like to review the existing account?';
  static ADMIN_PBC_SUBSCRIPTION_ACTIVE =
    'Selected Company has an Active Corporate Membership. Please contact join@nacdonline.org/boardadvisor@nacdonline.org';
  static ADMIN_PBC_SUBSCRIPTION_EXPIRED =
    'Selected Company has an Expired Corporate Membership. Please contact join@nacdonline.org/boardadvisor@nacdonline.org';
  static ADMIN_PBC_SUBSCRIPTION_INACTIVE =
    'Selected Company is Inactive. Please contact join@nacdonline.org/boardadvisor@nacdonline.org';
  static MEMBER_ALREADY_ADMIN_OR_PBC_MSG =
    "You can't add this user as PBC or Admin to your company";

  static DROPDOWN_DEFAULT_VALUE = '--None--';
  static HOME_BUSINESS_ADDRESS_TOOLTIP = 'Check this box to enter a home address instead of a business address';
  static ZIPCODE_US_ERROR_MESSAGE = "Enter a valid US zip code in the format '12345' or '12345-6789'";
  static ZIPCODE_NonUS_ERROR_MESSAGE = "Only letters, numbers, spaces, and hyphens ('-') are allowed";
  static ZIPCODE_US_MIN_LENGTH_MESSAGE = "Zipcode should be atleast 5 characters";
  static ZIPCODE_NonUS_MIN_LENGTH_MESSAGE = "Zipcode should be atleast 2 characters";
  static COMPANY_NAME_ERROR_MESSAGE = "Only letters, accent characters, spaces, hyphens, dots, commas, or apostrophes are allowed.";
  static COMPANY_NAME_REGEX = "^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?:[ '.,-][a-zA-ZÀ-ÖØ-öø-ÿ]*)*$";
  static NAME_REGEX = "^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?:[ '-][a-zA-ZÀ-ÖØ-öø-ÿ]*)*$";
  static ADDRESS_LINE_REGEX = "^[a-zA-Z0-9 ,.'-]+$";
  static CITY_REGEX = "^[a-zA-Z]+(?:[ '.-][a-zA-Z]*)*$";
  static ZIPCODE_US_REGEX = "^[0-9]{5}(-[0-9]{4})?$";
  static ZIPCODE_NonUS_REGEX = "^[a-zA-Z0-9- ]{2,}$";
  static PREFERRED_NAME_REGEX = "^[a-zA-Z]+(?:[ '-][a-zA-Z]*)*$";
  static PHONE_REGEX = "^[0-9 ()+-]*$";
  static EMAIL_REGEX = '^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  static MERGE_ACCOUNT = 'Ready for Merge';
  static COMPLETED = 'COMPLETED';
  static TRUE = 'True';
  static PBC = "PBC"
  static ADMIN = "ADMIN"
  static OTHER = "Other"
}

export class MemberRoleType {
  static BOARD: string = 'Board';
  static EXECUTIVE: string = 'Executive';
  static COMMITTEE: string = 'Committee';
}

export class MembershipStatus {
  static PENDING: string = 'Pending';
  static COMPLETE: string = 'Completed';
  static FAILED: string = 'Failed';
}

export class MembershipRequestType {
  static NEW: string = 'New';
  static RENEW: string = 'Renew';
  static RETRY: string = 'Retry';
  static ADDITIONAL: string = 'Additional';
  static PAY_LATER: string = 'PayLater';
}

export class ModuleType {
  static MEMASSIGN: string = 'MemberAdvisorAssignment';
  static NEWPBCASSIGN: string = 'NewPBCAssignment';
}

export class DiscountType {
  static SEASONAL: string = 'Seasonal';
  static SPECIAL: string = 'Special';
}

export class OtherDiscountType {
  static MultiYearBenefit = 'MultiYearBenefit';
  static NonProfitBenefit = 'NonProfitBenefit';
  static EarlyBird = 'EarlyBird';
}

export class EmailTriggerTemplateId {
  static PaymentReceipt = 'd-1a973ebcfd774917a311fec41b64a915';
  static PaymentLink = 'd-ff86bfb651d2493384e7c28738e22a29';
  static PaymentReminder = 'd-bebd208d7a7d41fa9c55684c4d09d9b7';
  static RenewalInvoice90Days = 'd-6c811a03cda44bb5a227d8a03a387433';
  static RenewalInvoice60Days = 'd-227f52833bf145568a44c74376f610e9';
  static RenewalInvoice31Days = 'd-9e0c2eaac77c4c76a2099b76e4a9ee93';
  static RenewalInvoice7Days = 'd-09e4419cd73545d3b18e0d3fc4374788';
  static RenewalInvoiceAfter7Days = 'd-4de75ae75eab43749c6927d080373a7b';
  static MemberRoster = 'd-9c11081b73554bca89440de23ba43a1f';
  static GenericInvoice = 'd-4f0f09c85d3c49cd835b71bec49a4409';
  static MemberAdvisor = 'd-c1fc403b36a54daab9d0815cc14d3ff2';
  static NewPBCEmailNotification = 'd-d1bf34b651d544159820b7e4948b4f61';
  static NotificationOnCorporateJoins = 'd-a72cc8976e6e4a4baa7e9a0f4bba27a5';
}

export class ErrorMessage {
  static UnexpectedError = "An unexpected error occurred";
}

import { InjectionToken } from '@angular/core';
export const TOKEN = new InjectionToken<string>('');

// Constants should be declared in All CAPS
