export enum EmailStatus {
  InProgress = 1,
  Success = 2,
  Failue = 3,
}

export interface EmailAddress {
  Name: string;
  Email: string;
}

export interface EmailLog {
  id: string;
  module?: string;
  sender?: EmailAddress;
  recipients?: string;
  scheduleDateTime?: Date;
  variableData?: { [key: string]: string };
  statusCode: EmailStatus;
  statusMessage: string;
  companyId: string;
  templateId?: string;
  attachments: attachment[];
}

export interface attachment {
  Base64: string;
  BlobUri: string;
  FileName: string;
}

export class EmailLogDetail {
  PBCEmail?: string;
  PBCMemberName?: string;
  AdminEmail?: string;
  AdminMemberName?: string;
  emailScheduleDate?: Date;
  module?: string;
  variableData!: { [key: string]: string };
  needsTrigger!: boolean;
  companyId!: string;
  templateId!: string;
  attachmentInBase64!: string;
  fileName?: string;
}