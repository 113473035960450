import { Component } from '@angular/core';

@Component({
  selector: 'app-super-admin-edit-company',
  templateUrl: './super-admin-edit-company.component.html',
  styleUrls: ['./super-admin-edit-company.component.scss']
})
export class SuperAdminEditCompanyComponent {

}
