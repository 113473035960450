import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAppConfig } from '../models/defaults';

@Injectable()
export class AppConfig {
  static configSettings: IAppConfig;

  constructor(private _http: HttpClient) {

  }

  loadConfig() {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    this._http.get(jsonFile).subscribe({ 
      next: (response) =>  { 
        AppConfig.configSettings = <IAppConfig>response
        console.log(response);
      },
      error: (error) => console.log(`Could not load file '${jsonFile}': ${JSON.stringify(error)}`)
    }) 
    // return new Promise<void>((resolve, reject) => {
    //   this._http.get(jsonFile).toPromise().then((response: any) => {
    //     AppConfig.configSettings = <IAppConfig>response;
    //     resolve();
    //   }).catch((response: any) => {
    //     reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
    //   });
    // });

  }
}
